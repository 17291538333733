

import React, { useEffect, useMemo } from 'react';
import { useState, useRef } from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsTable } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { getAmc } from '../../stores/services/amc.service';
import { getUnitHoldersByAmcAndFolioNo, updateUnitHoldersRecociliation, verifyOTP, verifyUserOTPForUnitHolderRecinciliation } from '../../stores/services/unit-holder.service';
import { getFundByAmc, getFundByAMCandFundCode } from '../../stores/services/funds.service';
import { generateOtp, generateTwoFa, twoFALogin } from '../../stores/services/auth.service';
import xls from "xlsx";
import { sortAMCS, numberWithCommas } from '../../utils/customFunction';
import Pagination from './Pagination-unitholder/Pagination';
import './Pagination-unitholder/pagination-style.scss';

import { useWorker, WORKER_STATUS } from "@koale/useworker";

const UnitHolderReconcialiation = () => {

  const PageSize = 100;
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allUnitHolders, setAllUnitHolders] = useState<any>([]);
  const [allFundsByAMC, setAllFundsByAMC] = useState<any>([]);
  const [amc_code, setAmcCode] = useState('');
  const [data, setData] = useState<any>([]);
  const [fileData, setFileData] = useState<any>([]);

  const myRef1 = useRef<HTMLInputElement>(null);
  const [filename, setFileName] = useState('');
  const [file, setFile] = useState('');

  const [showReconcile, setShowReconcile] = useState(true);

  const [actionLoading, setActionLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [checkBoxLoading, setCheckBoxLoading] = useState<any>(false);


  const [currentPage, setCurrentPage] = useState(1);
  const [optModalToggle, setOTPModalToggle] = useState(false);
  const [otp, setOTP] = useState('');


  useEffect(() => {
    const getAllAMC = async () => {
      try {
        const amcResponse = await getAmc(email, '', '', '');
        let sortAMCSByName = await sortAMCS(amcResponse.data.data);
        setAllAmc(sortAMCSByName);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Request Failed!")
        }
      }
    }
    getAllAMC();
  }, [])

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace('AMC_', '')}>
          {item.name}-{item.amc_code.replace('AMC_', '')}
        </option>
      );
    });
  };


  const uploadFile = async (e: any) => {
    fileData.length = 0;
    setFileData(fileData)
    finalData.length=0
    data.length=0
    setData(data)
    setFinalData(finalData)
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (type === 'xlsx' || type === 'csv') {
      setFile(file);
      setFileName(file.name);
      //start
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target?.result;
        const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data: any = xls.utils.sheet_to_json(ws, {
          header: 2,
          raw: false,
          dateNF: 'YYYY-MM-DD',
          defval: ''
        });
        let flag = true;
         
        /* Update state */
        if (data) {
          
          

          for (let i = 0; i < data.length; i++) {
            if (data[i].FolioNo.includes(amc_code + '-')) {
              if (i > 0) {
                if (amc_code !== data[i].AMCCode) {
                  flag = false
                  toast.error('Amc code should be same.');
                  break;

                }
                if (data[0].FundCode !== data[i].FundCode) {
                  flag = false
                  toast.error('Fund code should be same.');
                  break;
                }
              }

              if (data[i].ntn && data[i].cnic) {
                if (data[i].cnic.trim().length > 1 && data[i].cnic.trim().length > 1) {
                  flag = false
                  toast.error('NTN and CNIC both are entered.');
                  break;
                }
                if (data[i].cnic === "0") {
                  data[i].cnic = ""
                }
                if (data[i].ntn === "0") {
                  data[i].ntn = ""
                }

              }

              if (!data[i].Name || data[i].Name === '') {
                flag = false
                toast.error('Client name is missing in file.');
                break;
              }
            } else {
              flag = false;
              toast.error('Amc code is missing with folio no.');
              break;
            }
          }

          const FundCheck=async()=>{
            let res= await getFundByAMCandFundCode(email,data[0].AMCCode, data[0].FundCode)
            if(res&& res.data.data.length===0)
            {
              flag=false
               toast.error("Fund Code Not Exist in AMC")

            }

          }
          if(flag== true)
          {
            FundCheck()
          }
          
          
          
          // if(CheckFundExist && CheckFundExist.data.length==0)
          // {
          //   flag=false
          //   toast.error("Fund Code Not Exist in AMC")
          // }
          if (flag === true) {
            setFileData(data)
          }

        }

      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };

  // get all unitHolder By AMC
  const getAllUnitholdersByAmc = async (amcCode) => {
    try {
      allUnitHolders.length = 0;
      setAllUnitHolders(allUnitHolders);
      const response = await getUnitHoldersByAmcAndFolioNo(email, amcCode, '');
      setAllUnitHolders(response.data.data);
      await getFundsByAMCCode(amcCode);
      setShowReconcile(false);
    } catch (error: any) {
      setShowReconcile(true);
      if (error.response !== undefined) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error);
      }
    }
  }


  const getFundsByAMCCode = async (amcCode) => {
    const response = await getFundByAmc(email, amcCode);
    if (response.data.status === 200) {
      setAllFundsByAMC(response.data.data);
    } else {
      toast.error(response.data.message);
    }
  }


  const runProcess = async () => {
    const result = await processWorker(amc_code, allUnitHolders, fileData, allFundsByAMC); // non-blocking UI

    await setPaginationAndHoldData(result);
  };

  const [finalData, setFinalData] = useState<any>([]);

  const setPaginationAndHoldData = async (responseResult) => {
    setData(responseResult);
    setCurrentPage(1);
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setFinalData(responseResult.slice(firstPageIndex, lastPageIndex));
    setLoading(false);
  }


  const loadData = (amc_code, allUnitHolders, fileData, allFundsByAMC) => {
    try {
      const unitHoldings = allUnitHolders.map((item: any, index) => {
        if (item.balance_unit && item.balance_unit !== '[]') {
          let holding = JSON.parse(item.balance_unit);
          let finalHolding: any = []
          for (let i = 0; i < holding.length; i++) {
            if (holding[i].fund_code === fileData[0].FundCode) {
              finalHolding.push({ itm: holding[i], folio_no: item.folio_no.replace('UNITHOLDER_', ''), name: item.name, cnic: item.cnic, ntn: item.ntn, type: item.type })
            }
          }
          return finalHolding;
        }
      });

      let finaldata: any = [];
      unitHoldings.forEach(object => {
        if (object !== undefined) {
          object.forEach(items => {
            items.itm.folio_no = items.folio_no;
            items.itm.name = items.name;
            items.itm.cnic = items.cnic;
            items.itm.ntn = items.ntn;
            items.itm.type = items.type;
            finaldata.push(items.itm)
          })
        }
      });

      // end extract data (unitholding) of folios


      let combineData = finaldata.map((item: any) => {
        if (!item.fund_unit || isNaN(item.fund_unit) || item.fund_unit === 'NaN' || item.fund_unit === '') {
          item.fund_unit = '0';
        }
        let matchedObject = fileData.find(fileItem => {
          return amc_code === fileItem.AMCCode.trim() && item.folio_no === fileItem.FolioNo.trim() && item.fund_code.trim() === fileItem.FundCode.trim();
        })

        if (matchedObject) {
          return {
            folio_no: item.folio_no,
            name: matchedObject.Name === '' ? item.name : matchedObject.Name,
            fund_code: item.fund_code,
            fund_name: item.fund_name,
            //cnic: matchedObject.cnic ===''? item.cnic: matchedObject.cnic,
            cnic: item.type === "Individual" ? matchedObject.cnic === '' ? item.cnic : matchedObject.cnic : "",
            // ntn: matchedObject.ntn ===''? item.ntn: matchedObject.ntn,
            ntn: item.type === "Corporate" ? matchedObject.ntn === '' ? item.ntn : matchedObject.ntn : "",
            nav: item.nav === '' ? '0' : item.nav,
            fund_unit: item.fund_unit === 'NaN' ? '0' : item.fund_unit,
            holding: matchedObject.FundUnit,
            difference: (parseFloat(matchedObject.FundUnit.replaceAll(",", "")) - parseFloat(item.fund_unit.toString().replaceAll(",", ""))).toFixed(4),
          }
          // }else{
          //   return {};
          // }

        } else {
          return {
            folio_no: item.folio_no,
            name: item.name,
            fund_code: item.fund_code,
            fund_name: item.fund_name,
            cnic: item.cnic,
            ntn: item.ntn,
            nav: item.nav === '' ? '0' : item.nav,
            fund_unit: item.fund_unit === 'NaN' ? '0' : item.fund_unit,
            holding: '0',
            difference: (0 - parseFloat(item.fund_unit.toString().replaceAll(",", ""))).toFixed(4),
          }
        }

      }); // end of map


      let combineData3 = fileData.map((item: any, index: number) => {
        if (item.FundUnit === 'NaN' || item.FundUnit === '') {
          item.FundUnit = '0';
        }

        let UnmatchedObject = finaldata.find(items => {
          return amc_code === item.AMCCode.trim() && items.folio_no == item.FolioNo.trim() && item.FundCode.trim() === items.fund_code.trim();
        })
        // match the holding of funds according to amc's funds.
        let fineData = allFundsByAMC.filter((itm: any) => {
          return itm.amc_code == amc_code && itm.symbol_code == item.FundCode.trim()
        })



        if (UnmatchedObject && fineData.length > 0) {
          return {
            folio_no: UnmatchedObject.folio_no,
            name: UnmatchedObject.name,
            fund_code: item.FundCode,
            fund_name: item.FundName,
            cnic: item.cnic,
            ntn: item.ntn,
            nav: item.Nav === '' ? '0' : item.Nav,
            fund_unit: '0',
            holding: item.FundUnit,
            difference: (parseFloat(item.FundUnit.replaceAll(",", ""))).toFixed(4),
          }
        } else {
          return {}
        }

      }); // end of map

      let notExistFolio = fileData.map((item: any, index: number) => {
        if (item.FundUnit === 'NaN' || item.FundUnit === '') {
          item.FundUnit = '0';
        }

        // match unitholding from fund to file unitholding.
        let fineData = allFundsByAMC.filter((itm: any) => {
          return itm.amc_code == amc_code && itm.symbol_code == item.FundCode.trim()
        })
        if (finaldata.every(items => {
          let bool = amc_code == item.AMCCode.trim() && items.folio_no != item.FolioNo.trim()
          return !!bool
        }) && fineData.length > 0) {
          return {
            folio_no: item.FolioNo,
            name: item.Name,
            fund_code: item.FundCode,
            fund_name: item.FundName,
            cnic: item.cnic,
            ntn: item.ntn,
            nav: item.Nav === '' ? '0' : item.Nav,
            fund_unit: '0',
            holding: item.FundUnit,
            difference: (parseFloat(item.FundUnit.replaceAll(",", "")) - 0).toFixed(4),
          }
        } else {
          return {}
        }

      }); // end of map
      let unMatched: any = []
      const MatchedFolio = combineData.map(x => x.folio_no)
      for (let i = 0; i < combineData3.length; i++) {
        if (!MatchedFolio.includes(combineData3[i].folio_no)) {
          if (combineData3[i] && Object.keys(combineData3[i]).length > 0) {
            unMatched.push(combineData3[i])
          }

        }
      }


      let array1 = combineData.concat(unMatched);
      let finalArray = array1.concat(notExistFolio);

      const finalResults = finalArray.filter(element => {
        if (Object.keys(element).length !== 0) {
          return true;
        }
        return false;
      });
      return finalResults;


    } catch (error: any) {
    }
  }  // end of loadData

  // exceute loadData in useWorker.
  const [processWorker] = useWorker(loadData);


  // pagination
  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    // return data.slice(firstPageIndex, lastPageIndex);
    setFinalData(data.slice(firstPageIndex, lastPageIndex));
  }, [currentPage]);

  // end pagination

  const [requiredOTP, setRequiredOTP] = useState('');
  const renderOTPModal = () => {
    switch (optModalToggle) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={false}
            onHide={() => {
              setOTP('');
              setOTPModalToggle(false);

            }}
            fade={false}
          >

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                disabled={Boolean(confirmLoading)}
                onClick={() => {
                  setOTP('');
                  setOTPModalToggle(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Verification</h6>
            </div>

            <div className="modal-body">

              <div className="card" style={{
                backgroundColor: "#b8c8d0",
                color: "black"
              }}>

                <div className="form-group m-3">
                  <label style={{
                    color: "black"
                  }}>Enter OTP</label>
                  <input type='text' placeholder='Enter OTP Code' className={'form-control w-50 ' + requiredOTP} value={otp}
                    style={{
                      color: "black"
                    }}
                    onChange={(e) => {
                      setOTP(e.target.value);
                      setRequiredOTP('');
                    }} />
                </div>

                <div className="form-group m-3">
                  <button className='btn btn-primary'
                    disabled={Boolean(confirmLoading)}
                    onClick={(e) => {
                      if (otp == '') {
                        setRequiredOTP('required-border')
                      } else {
                        verify2FaBtn();
                      }
                    }}>

                    {confirmLoading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Confirm</span>
                    )}
                  </button>


                  <button className='btn btn-default'
                    disabled={Boolean(confirmLoading)}
                    onClick={(e) => {
                      setOTP('');
                      setOTPModalToggle(false);
                    }}>

                    Cancel
                  </button>
                </div>

              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };


  const updateReconcileData = async () => {


    try {
      setActionLoading(true);
      const response = await generateOtp(email);
      if (response.data.status === 200) {
        toast.success(response.data.message)
        setOTPModalToggle(!optModalToggle);
      } else {
        toast.error(response.data.message)
      }

      // setActionLoading(false);
    } catch (error: any) {
      setActionLoading(false);
      if (error.response !== undefined) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error)
      }
    }
    setActionLoading(false);
  }

  const verify2FaBtn = async () => {
    setConfirmLoading(true);
    try {
      const result = await verifyOTP(email, otp);
      if (result.data.status === 200) {
        saveReconcileData();
      } else if (result.data.status === 400) {
        setConfirmLoading(false);
        toast.error('Invalid OTP');
      }
    } catch (error: any) {
      setConfirmLoading(false);
      toast.error(error.data.message);
    }
  }

  const saveReconcileData = async () => {
    try {
      const response = await updateUnitHoldersRecociliation(email, data, amc_code);
      if (response.data.status === 200) {
        setAmcCode('');
        setFileName('');
        data.length = 0;
        setData(data);
        setFinalData(data);
        setFileData(data);
        toast.success(response.data.message);
        setOTP('');
        setConfirmLoading(false);
        setOTPModalToggle(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Request Failed!");
      }
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Unit Holder Reconciliation
                      <a className='float-right' href="./../../Uploader-Sample/Unit-Holder-Reconciliation-Format.xlsx" download>Download Sample</a></h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Select AMC</label>
                          <select
                            className="form-control"
                            value={amc_code}
                            onChange={(e) => {
                              setAmcCode(e.target.value);
                              getAllUnitholdersByAmc(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                        </div>
                      </Col>

                      <Col md="2">
                        <label>Upload File</label>
                        <div
                          onClick={() => myRef1?.current?.click()} >
                          <div className="form-group">
                            <div className="form-control" data-tip="Upload File">
                              {filename === '' ? 'Upload File' : filename}
                            </div>
                            <input
                              className="form-control mt-1"
                              type="file"
                              ref={myRef1}
                              style={{ display: 'none' }}
                              onChange={(e) => {
                                uploadFile(e);
                              }}
                              onClick={(e) => {
                                e.currentTarget.value = '';
                              }}
                            />
                          </div>
                        </div>
                      </Col>

                      {showReconcile == false ? (
                        <Col md="2">
                          <div className=" ">
                            <button
                              className="btn btn-primary mt-4"
                              onClick={async () => {
                                // loadData();
                                setLoading(true);;
                                if (fileData.length === 0) {
                                  toast.error('File is not selected or File is empty.')
                                } else {
                                  data.length = 0;
                                  setData(data)
                                  await runProcess();
                                }
                                setLoading(false);
                              }}
                              disabled={Boolean(loading)}
                            >
                              {loading ? (
                                <>
                                  <span
                                    className="spinner-border login-txt spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="login-txt"> Processing...</span>
                                </>
                              ) : (
                                <span>Reconcile</span>
                              )}
                            </button>
                          </div>
                        </Col>
                      ) : (
                        ''
                      )}



                    </Row>
                    {/* End */}
                  </div>
                </div>


                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    {data &&  data.length > 0 ? (
                      <>
                        <div className="title-row m-4 d-flex justify-content-center">
                          <h3>Unit Holder Reconciliation</h3>
                        </div>

                        <div className='table-reponsive'
                          style={{
                            overflowX: 'auto',
                            overflowY: 'hidden',
                          }}
                        >
                          <div className="table-responsive">
                            <table className="table">
                              <thead style={{ alignSelf: 'center' }}>
                                <tr>
                                  {/* <th>Select</th> */}
                                  <th>Folio No</th>
                                  <th>Name</th>
                                  <th>Fund Code</th>
                                  <th>Fund Name</th>
                                  <th >CNIC</th>
                                  <th >NTN</th>
                                  <th className="text-right">NAV</th>
                                  <th className="text-right">Holding as per MACS</th>
                                  <th className="text-right">Unit Holding</th>
                                  <th className="text-right">Difference</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {data.map((item: any, index: number) => { */}
                                {finalData.map((item: any, index: number) => {
                                  return (
                                    <>
                                      {parseFloat(item.difference) > 0 && (
                                        <tr key={index} className="highlight-row-success">
                                          <td>{item.folio_no}</td>
                                          <td>{item.name}</td>
                                          <td>{item.fund_code}</td>
                                          <td>{item.fund_name}</td>
                                          <td >{item.cnic}</td>
                                          <td >{item.ntn}</td>
                                          <td className="text-right">{item.nav}</td>
                                          <td className="text-right">{item.fund_unit}</td>
                                          <td className="text-right">{item.holding}</td>
                                          <td className="text-right">{item.difference}</td>
                                        </tr>
                                      )}
                                      {parseFloat(item.difference) < 0 && (
                                        <tr key={index} className="highlight-row-error">
                                          <td>{item.folio_no}</td>
                                          <td>{item.name}</td>
                                          <td>{item.fund_code}</td>
                                          <td>{item.fund_name}</td>
                                          <td >{item.cnic}</td>
                                          <td >{item.ntn}</td>
                                          <td className="text-right">{item.nav}</td>
                                          <td className="text-right">{item.fund_unit}</td>
                                          <td className="text-right">{item.holding}</td>
                                          <td className="text-right">{item.difference}</td>
                                        </tr>
                                      )}
                                      {parseFloat(item.difference) == 0 && (
                                        <tr key={index}>
                                          <td>{item.folio_no}</td>
                                          <td>{item.name}</td>
                                          <td>{item.fund_code}</td>
                                          <td>{item.fund_name}</td>
                                          <td >{item.cnic}</td>
                                          <td >{item.ntn}</td>
                                          <td className="text-right">{item.nav}</td>
                                          <td className="text-right">{item.fund_unit}</td>
                                          <td className="text-right">{item.holding}</td>
                                          <td className="text-right">{item.difference}</td>
                                        </tr>
                                      )}
                                    </>
                                  );
                                })}

                              </tbody>
                            </table>
                          </div>
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={data.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                          />
                        </div>
                        {data.length > 0 && (
                          <>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox"
                                checked={checkBoxLoading}
                                onChange={() => {
                                  setCheckBoxLoading(!checkBoxLoading)
                                }} />
                              <span className="form-check-sign" />
                              <span style={{color:'#EED202',fontWeight:'bold'}}>Warning!!! remaining holding will be zero automatically </span> 
                            </Label>
                          </FormGroup>
                          {/* <span className='pl-4' style={{color:'#EED202',paddingTop:'-20px'}}>Warning!!! remaining holding will be zero automatically </span>  */}
                          </>
                        )}
                        {(data.length > 0 && checkBoxLoading === true)&& (
                          <button className='btn btn-primary float-right'
                            disabled={Boolean(actionLoading)}
                            onClick={(e) => {
                              updateReconcileData();
                            }}>
                            {actionLoading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Reconcile</span>
                            )}
                          </button>
                        )}


                      </>
                    ) : (
                      <div className="table-empty">
                        <BsTable className="icon" />
                        <p className="text-center empty-text">No record found</p>
                      </div>
                    )}

                  </div>
                </div>
              </div>
              {renderOTPModal()}
            </Container>
          </Col>
        </Row>
      </div>

    </>
  );
};

export default UnitHolderReconcialiation;
