import React from 'react';
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';

import { Row, Col } from 'reactstrap';
interface IProps {
  setShowModal: Function;
  // setAmcSig: Function
  // amcSig: any[]
  setAmcSigError: Function;
  emailArray: any[];
  setEmailArray: Function;
}
const ConfirmModal: React.FC<IProps> = ({
  setShowModal,
  setAmcSigError,
  emailArray,
  setEmailArray,
}: IProps) => {
  const [Loading, setLoading] = useState(false);
  const [panel, setPanel] = useState('AUTHORIZER-A');
  const [emailAdd, setEmailAdd] = useState('');
  const emailRegex = new RegExp(
    '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
  );
  const [emailError, setEmailError] = useState('');
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState('');
  const validate = async () => {
    let nameErr,
      emailErr = '';
    emailAdd.trim() === ''
      ? (emailErr = 'Email Required')
      : emailRegex.test(emailAdd) !== true
      ? (emailErr = 'Invalid Email')
      : (emailErr = '');
    name.trim() === '' ? (nameErr = 'Name Required') : (nameErr = '');
    if (nameErr || emailErr) {
      setNameError(nameErr);
      setEmailError(emailErr);
      return false;
    } else {
      return true;
    }
  };
  const addEmailInList = async () => {
    const isValid = await validate();
    if (isValid) {
      setEmailError('');
      let obj = {
        name: name,
        email: emailAdd.toLowerCase(),
        role: panel,
      };
      // amcSig.push(obj);
      // if (emailArray.trim() === '') {
      //     emailArray = emailAdd;
      // } else {
      //     emailArray = emailArray + ' , ' + emailAdd;
      // }
      emailArray.push(obj);
      // setAmcSig(amcSig);
      setEmailArray(emailArray);
      setShowModal(false);
      setAmcSigError('');
    }
  };
  return (
    <Modal
      className=""
      dialogClassName="modal60w"
      show={true}
    >

<div className="modal-header ">
              <button aria-hidden="true" className="close"  
              onClick={() => { setEmailAdd(''); setShowModal(false) }}
              data-dismiss="modal" type="button">
                <i className="tim-icons icon-simple-remove"></i>
             </button>
                <h6 className="title title-up">Add AMC Signatories</h6>
            </div>

      <div className="modal-body">
        <Row> 
          <Col md="12">
            <div className="form-group ">
                <label>Name</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError('');
                }}
              />
              {nameError ? (
                <p className="error-labels error-mes">{nameError}</p>
              ) : (
                ''
              )}
            </div>
          </Col>
        </Row>
        <Row> 
          <Col md="12">
            <div className="form-group ">
                <label htmlFor="">Email</label>
              <input
                type="text"
                className="form-control"
                value={emailAdd}
                onChange={(e) => {
                  setEmailAdd(e.target.value);
                  setEmailError('');
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    addEmailInList();
                  }
                }}
              />
              {emailError ? (
                <p className="error-labels error-mes">{emailError}</p>
              ) : (
                ''
              )}
            </div>
          </Col>
        </Row>
        <Row> 
          <Col md="12">
            <div className="form-group">
                <label>Select Panel</label>
              <select
                className="form-control"
                value={panel}
                onChange={(e) => {
                  setPanel(e.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    addEmailInList();
                  }
                }}
              >
                <option value="AUTHORIZER-A" defaultChecked>
                  Panel A
                </option>
                <option value="AUTHORIZER-B">Panel B</option>
              </select>
            </div>
          </Col>
        </Row>
        <div className="float-right">
          <Row>
            <Col>
              <div className="">
                <button
                  className="btn btn-primary"
                  disabled={Boolean(Loading)}
                  onClick={() => {
                    addEmailInList();
                  }}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>Confirm</span>
                  )}
                </button>
              </div>
            </Col>
            <Col>
              <div className=" ">
                <button
                  className="btn btn-default"
                  onClick={() => {
                    setEmailAdd('');
                    setShowModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmModal;
