import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';
/**
 *
 * @param email
 * @param bank_name
 * @param branch_name
 * @param city
 * @param area
 * @param branch_code
 * @param branch_address
 *
 */
const addBranch = async (
  email: string,
  bank_name: string,
  branch_name: string,
  city: string,
  area: string,
  branch_code: string,
  branch_address: string
) => {
  const url = `${Config.baseUrl}/branch/`;
 try{
  const result: any = await axios.post(
    url,
    { email, bank_name, branch_name, city, area, branch_code, branch_address },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
 }catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await addBranch(email,bank_name,branch_name,city,area, branch_code, branch_address)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 * @param email
 */
const getAllBranchs = async (
  email: string,
  page_number: string = '',
  page_size: string = ''
) => {
  const url = `${Config.baseUrl}/branch?email=${email}&page_number=${page_number}&page_size=${page_size}`;
 try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAllBranchs(email,page_number,page_size)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 *
 * @param search_value
 */
const searchBranchAPI = async (email: string, search_value: string) => {
  const url = `${Config.baseUrl}/branch?email=${email}&search_value=${search_value}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await searchBranchAPI(email,search_value)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 * @param bankName
 *
 */
const getBranchByBankName = async (email: string, bankName: string) => {
  const url = `${Config.baseUrl}/branch?email=${email}&bank_name=${bankName}`;
 try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getBranchByBankName(email,bankName)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 * @param code
 * @param status
 */
const updateBranchStatus = async (
  email: string,
  code: string,
  status: string
) => {
  const url = `${Config.baseUrl}/branch/update-status`;
  try{
  const result: any = await axios.post(
    url,
    { email, code, status },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await updateBranchStatus(email,code,status)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};
const editBranchData = async (
  email: string,
  bank_name: string,
  branch_name: string,
  city: string,
  area: string,
  branch_code: string,
  branch_address: string
) => {
  const url = `${Config.baseUrl}/branch/update`;
  try{
  const result: any = await axios.post(
    url,
    { email, bank_name, branch_name, city, area, branch_code, branch_address },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await editBranchData(email,bank_name,branch_name,city, area, branch_code, branch_address)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};

export {
  addBranch,
  editBranchData,
  getAllBranchs,
  searchBranchAPI,
  updateBranchStatus,
  getBranchByBankName,
};
