import React, { Component, useEffect, useState } from "react";
import { AiFillCalculator } from "react-icons/ai";
import { RiCalculatorFill, RiDeleteBin5Line, RiDeleteBin6Line } from "react-icons/ri";
import { VscHistory } from "react-icons/vsc"
import { Container, Row, Col } from 'reactstrap';


import { Button, CustomInput } from "reactstrap";
import { numberWithCommas } from "../utils/customFunction";
import { IoMdClose } from "react-icons/io";
import { FaCalculator } from "react-icons/fa";
// import './Calculator.css';

const FixedPlugin = (props) => {
  // const [classes, setClasses] = React.useState("dropdown");
  // const [darkMode, setDarkMode] = React.useState(false);


  const [calculatorFlag, setCalculatorFlag] = useState(false);

  let [input, setInput] = useState('');
  let [input1, setInput1] = useState(0);
  let [percent, setPercent] = useState(0);
  let [output, setOutput] = useState('');
  let [keyPress, setKeyPress] = useState('');
  let [keyPressData, setKeyPressData] = useState('');
  const [history, setHistory] = useState(false);
  const [calculationHistory, setCalculationHistory] = useState<any>([]);
  const [input1Focused, setIsInput1Focused] = useState(false);
  let count = 0;
  const inputRef = React.useRef(null);

  useEffect(() => {
    // let inputField:any = '';
    // inputField= document.querySelector('.focused'); // Replace with the correct selector for your input field

    // // Add event listener to input field
    // inputField.addEventListener('keydown', handleKeyDown);

    // // Remove event listener when component unmounts
    // return () => {
    //   inputField.removeEventListener('keydown', handleKeyDown);
    // };
    if (input1Focused) {
      // Check if input field is not focused before running the effect
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [input1Focused]);

  useEffect(() => {
    if (input1 >= 1) handleEqual();
    // if (percent >= 1) handlePercentage();
  }, [input1, percent])

  useEffect(() => {
    const showResultOnKeyPress = () => {
      let inputData = input + keyPressData;
      setInput((prev_input) => prev_input + keyPressData);
      let flag = false;
      if (keyPressData === '+' || keyPressData === '-' || keyPressData === '*' || keyPressData === '/') {
        flag = true;

      } else {
        flag = false
      }
      if (flag === false) {
        try {
          setKeyPressData('');
          let result = eval(inputData.replaceAll('%', '/100')).toString();
          setOutput(result);
        } catch (error) {
          setKeyPressData('');
          setOutput('Error');
        }
      }
    }
    if (keyPressData !== '') showResultOnKeyPress()

  }, [keyPressData])


  const handleKeyDown = (event) => {
    event.stopPropagation();
    setKeyPress('');
    const key = event.key;
    const operators = ['+', '-', '*', '/'];

    if (key === 'Enter' || key === '=') {
      setKeyPress(key);
      input1 = input1 + 1;
      setInput1(input1);

    } else if (key === 'Escape') {
      setKeyPress(key);
      handleClear();
    } else if (key === 'Backspace') {
      handleDelete();
    } else if (key === '.' || (key >= '0' && key <= '9') || operators.includes(key)) {
      count = count + 1;
      handleInput(key);
    } else if (key === '%') {
      handlePercentage()
    } else if (key === '+/-') {
      handleNegation();
    }

  };
  const [percentCount,setPercentCount] = useState(0);
useEffect(() => {
  const percentFuntion =()=>{
    setPercentCount(0);
    try {
      let prev = input.replaceAll('%', '/100') ;
      setInput((prevInput) => {
        if (prevInput.slice(-1) === "%") {
          prevInput = prevInput.slice(0, -1);
        }
        return prevInput + '%'
      });
      let result = eval(prev + '/100').toString();
      setCalculationHistory((prevHistory) => [...prevHistory, `${input + '%'} = ${result.includes('.') ? numberWithCommas(parseFloat(result || '').toFixed(4)) : result}`]);
      setOutput(result);
    } catch (error) {
      setOutput('Error');
    }
  }
  if(percentCount==1){
    percentFuntion()
  }
  
}, [percentCount])

  const handlePercentage = () => {
    if (keyPress !== 'Enter') {
      setPercentCount(1);
    }
  };

  const handleNegation = () => {
    setInput((prevInput) => {
      if (prevInput.charAt(0) === '-') {
        return prevInput.slice(1);
      } else {
        return '-' + prevInput;
      }
    });
  };

  const handleInput = async (value) => {
    setKeyPressData(value);
  };








  const handleInput1 = (value) => {
    let inputData = input + value;
    setInput((prevInput) => prevInput + value);
    if (['+', '-', '*', '/'].includes(value) === false) {
      if (keyPress !== 'Enter') {
        try {
          let result = eval(inputData.replaceAll('%', '/100')).toString();
          setOutput(result);
        } catch (error) {
          setOutput('Error');
        }
      }
    }
  };


  const handleClear = () => {
    if (keyPress !== 'Enter') {
      setInput('');
      setOutput('')
    }
  };
  const [deleteCount, setDeleteCount] = useState(0)
  useEffect(() => {
    const deleteFunction = async () => {
      setDeleteCount(0)
      let inputVal: any = '';
      inputVal = inputRef.current;
      let start;
      start = inputVal.selectionStart;
      let end;
      end = inputVal.selectionEnd;
      // Get the input value
      let inputValue: any = ''
      inputValue = inputVal.value;
      // Update the input value with selected text removed
      const newValue = inputValue.slice(0, start) + inputValue.slice(end);
      if (input.length !== newValue.length) {



        setInput(newValue)
        if (['+', '-', '*', '/'].includes(newValue[newValue.length - 1]) === false) {
          try {
            let result = await eval(newValue.replaceAll('%', '/100')).toString();
            setOutput(result);
          } catch (error) {
            setOutput('Error');
          }
        } else {
          try {
            let result = await eval(newValue.slice(0, -1).replaceAll('%', '/100')).toString();
            setOutput(result);
          } catch (error) {
            setOutput('Error');
          }
        }
        // Update the input value and reset selection
        inputVal.value = newValue;
        inputVal.selectionStart = start;
        inputVal.selectionEnd = start;

      } else {
        let val = input.slice(0, -1);
        setInput((prevInput) => {
          val = prevInput.slice(0, -1);
          return prevInput.slice(0, -1)
        }
        );
        if (['+', '-', '*', '/'].includes(val[val.length - 1]) === false) {
          try {
            let result = await eval(val.replaceAll('%', '/100')).toString();
            setOutput(result);
          } catch (error) {
            setOutput('Error');
          }
        } else {
          try {
            let result = await eval(val.slice(0, -1).replaceAll('%', '/100')).toString();
            setOutput(result);
          } catch (error) {
            setOutput('Error');
          }
        }
      }
    }
    if (deleteCount === 1) {
      deleteFunction()
    }

  }, [deleteCount])


  const handleDelete = async () => {
    if (keyPress !== 'Enter') {
      setDeleteCount(1)

      // setOutput((prevInput) => prevInput.slice(0, -1));
    }
  };

  const handleEqual = () => {
    if (input !== '') {
      try {
        let result = eval(input.replaceAll('%', '/100')).toString();
        setOutput(result);
        setCalculationHistory((prevHistory) => [...prevHistory, `${input.toString()} = ${result.includes('.') ? numberWithCommas(parseFloat(result || '').toFixed(4)) : result}`]);
        setInput('')
      } catch (error) {
        setOutput('Error');
      }
    }
  };

  function handlePaste(event) {
    event.preventDefault();
    const pastedValue = event.clipboardData.getData("Text").replaceAll(',', '');
    try {
      let result = eval(input + pastedValue.replaceAll('%', '/100')).toString();
      setOutput(result);
    } catch (error) {
      setOutput('Error');
    }
    setInput((prevValue) => prevValue + pastedValue.replaceAll(',', ''));
  }

  const handleDrop = (event) => {
    event.preventDefault();
    const drag_and_drop = event.dataTransfer.getData('text').replaceAll(',', '');
    try {
      let result = eval(input + drag_and_drop.replaceAll('%', '/100')).toString();
      setOutput(result);
    } catch (error) {
      setOutput('Error');
    }
    setInput((prevValue) => prevValue + drag_and_drop.replaceAll(',', ''));
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  

  const [color, setColor] = useState("#db6cfa");
  const styles = {
    marginRight: '30%', marginTop: '-8px',
    cursor: 'pointer',
    color: color
  }
  const styles1: any = {

    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    marginLeft: "5px",
    marginRight: "5px"
  }

  const viewCalculator = () => {
    switch (calculatorFlag) {
      case true:
        return (
          <>

            <div className="calculator" style={{ transform: 'translateX(-86%)' }}>
              <div >
                <Row className="p-1">
                  <Col md='8'>
                    <div className="output1 text-left">Instant Calculator</div>
                  </Col>
                  <Col md='2'>
                    <VscHistory size={20} style={{ marginLeft: '100%', cursor: 'pointer' }} onClick={() => {
                      setHistory(!history);
                    }} />
                  </Col>
                  <Col md='2'>
                    <IoMdClose className="pr-2" size={35}
                      style={styles}
                      onMouseEnter={() => setColor("white")}
                      onMouseLeave={() => setColor("#db6cfa")}
                      onClick={() => {
                        setCalculatorFlag(false);
                      }}
                    />
                    {/* <button
                      style={{ marginLeft: '99%', marginTop: '-8px' }}
                      type="button"
                      className="float-right"
                      onClick={() => {
                        setCalculatorFlag(false);
                      }}
                    >
                      <i className="tim-icons icon-simple-remove" />
                    </button> */}
                  </Col>
                </Row>

              </div>
              {history ? (
                <>
                  <h2 className="output1">History</h2>
                  <Row>
                    <Col className="float-right pr-4">
                      <RiDeleteBin5Line onClick={() => { setCalculationHistory([]) }} size={20} style={{ cursor: 'pointer' }} />
                    </Col>
                  </Row>
                  <div className='scrollable1-div'>
                    {calculationHistory.map((calculation, index) => {
                      return (
                        <div key={index}>
                          <Container fluid>
                            <Row>
                              <Col className="output1 text-right mt-2" >
                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                  setHistory(!history);
                                  setInput(calculation.split('=')[0].trim());
                                  setOutput(calculation.split('=')[1].trim());
                                // }}>{`${calculation.split('=')[0].trim()} = ${calculation.split('=')[1].trim()}`}</div>
                                }}><span style={{ fontSize: '10px' }}>{calculation.split('=')[0].trim()}</span> = {calculation.split('=')[1].trim()}</div>
                              </Col>
                            </Row>
                          </Container>
                        </div>

                      )
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div className="display">
                    <div className="output1 text-right pr-3 pt-1" style={{ height: '25px' }}>
                      {output.includes('.') ? numberWithCommas(parseFloat(output || '').toFixed(4)) : numberWithCommas(parseFloat(output || ''))}
                    </div>
                    <div className="form-group">
                      <input type="text" ref={inputRef}
                        style={{ width: '235px' }}
                        onFocus={() => setIsInput1Focused(true)}
                        onBlur={() => setIsInput1Focused(false)}
                        className={'form-control text-right'}
                        value={input} onPaste={handlePaste}
                        onDragOver={handleDragOver} onDrop={handleDrop} />
                    </div>
                  </div>
                  <div className="buttons">
                    <button onClick={() => {
                      setKeyPress('')
                      handlePercentage()
                    }} className="btn btn-secondary" style={styles1}>%</button>
                    <button onClick={() => {
                      setKeyPress('')
                      handleClear()
                    }} className="btn btn-secondary" style={styles1}>C</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleDelete()
                    }} className="btn btn-secondary" style={styles1}>←</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('/')
                    }} className="btn btn-secondary" style={styles1}>÷</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('7')
                    }} className="btn btn-light" style={styles1}>7</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('8')
                    }} className="btn btn-light" style={styles1}>8</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('9')
                    }} className="btn btn-light" style={styles1}>9</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('*')
                    }} className="btn btn-secondary" style={styles1}>×</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('4')
                    }} className="btn btn-light" style={styles1}>4</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('5')
                    }} className="btn btn-light" style={styles1}>5</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('6')
                    }} className="btn btn-light" style={styles1}>6</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('-')
                    }} className="btn btn-secondary" style={styles1}>-</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('1')
                    }} className="btn btn-light" style={styles1}>1</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('2')
                    }} className="btn btn-light" style={styles1}>2</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('3')
                    }} className="btn btn-light" style={styles1}>3</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('+')
                    }} className="btn btn-secondary" style={styles1}>+</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleNegation()
                    }} className="btn btn-secondary" style={styles1}>+/-</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('0')
                    }} className="btn btn-light" style={styles1}>0</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleInput1('.')
                    }} className="btn btn-light" style={styles1}>.</button>
                    <button onClick={() => {
                      setKeyPress('');
                      handleEqual()
                    }} className="btn btn-primary" style={styles1}>=</button>
                  </div>
                </>
              )

              }
            </div>
          </>


        );
      default:
        return '';
    }
  };
  return (
    <div className="fixed-plugin">
      <div >
        <a
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            setCalculatorFlag(true)
          }}
        >
          <RiCalculatorFill color="#ffffff" size={40} />
        </a>
        {/* <ul className="dropdown-menu show"> 
          <li className="adjustments-line mb-3">
            <div className="togglebutton switch-change-color mt-3 d-flex align-items-center justify-content-center">
              <span className="label-switch">LIGHT MODE</span>
              <CustomInput
                type="switch"
                id="switch-2"
                onChange={handleActiveMode}
                value={darkMode.toString()}
                className="mt-n4"
              />
              <span className="label-switch ml-n3">DARK MODE</span>
            </div>
          </li> 
        </ul> */}
      </div>
      {viewCalculator()}
    </div>
  );
};

export default FixedPlugin;

