import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 
 */


const equityHoldersRecociliation = async (email: string, data: any, fund_code:string) => {
  const url = `${Config.baseUrl}/reconc/update-equity-recon`;
  try{
  // let data = JSON.stringify(dataObj);
  const result: any = await axios.post(
    url,
    { email, data, fund_code },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await equityHoldersRecociliation(email,data,fund_code)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};

const updateEquityReconCDC = async (email: string, data: any) => {
  const url = `${Config.baseUrl}/reconc/update-equity-recon-cdc`;
  try{
  // let data = JSON.stringify(dataObj);
  const result: any = await axios.post(
    url,
    { email, data },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await updateEquityReconCDC(email,data)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};

export {
    equityHoldersRecociliation,
    updateEquityReconCDC
};
