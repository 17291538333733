import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import moment from 'moment';
import DCClogo from '../../assets/img/DCC-Logo.png';
import { numberWithCommas } from "../../utils/customFunction";
import Config from '../../config';

export const OnlineTransferOfFundLetter = (transaction: any, amtWords: any, transMode: any, transType: any,  transNetAmount: any, transGrossAmount: any) => {
    
    let date = new Date();
    let dateFormat = moment(date).format('LL');
    let q="qrcodeonlineTF";
    let id=`${Config.q_r_url }/admin/verify-transaction/`+transaction.txn_id; 
    let txnId = transaction.txn_id; 

    let branchName = '';
    let branchCity = '';

    let counter_branchName = '';
    let counter_branchCity = '';
//     if(transaction.branch != undefined){
//         branchName = transaction.branch.split(',')[0];
//         branchCity = transaction.branch.split(',')[1];
//         branchName = branchCity === undefined ? branchName+',' : branchName;
//         branchCity = branchCity === undefined ? '' : branchCity+',';
// }
if(transaction.branch != undefined){
    if(transaction.branch.includes('–')){
        branchName = transaction.branch.split('–')[0];
        branchCity = transaction.branch.split('–')[1];
        branchName = branchCity === undefined ? branchName.trim()+',' : branchName.trim()+',';
        branchCity = branchCity === undefined ? '' : branchCity.trim()+'.';
    }else{
        branchName = transaction.branch+",";
    }
}
if(transaction.counter_branch != undefined){
    if(transaction.counter_branch.includes('–')){
        counter_branchName = transaction.counter_branch.split('–')[0];
        counter_branchCity = transaction.counter_branch.split('–')[1];
        counter_branchName = counter_branchCity === undefined ? counter_branchName.trim()+',' : counter_branchName.trim()+',';
        counter_branchCity = counter_branchCity === undefined ? '' : counter_branchCity.trim()+'.';
    }else{
        counter_branchName = transaction.counter_branch+",";
    }
}

    setTimeout(() => {
        if(id!=undefined){  
            var element =document.getElementById(q)!;
            if(element!=null){
             element.innerHTML="";
            }
             var qrcode = new QRCode(document.getElementById(q), {
             width : 50,
             height : 50
         });
           qrcode.makeCode(id);
     }
    
    } , 500)

    // const numberWithCommas = (amount: any) => {
    //     return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // }

    return `<html>
    <head>
        <title>MACS Trustee</title>
    </head>
    <body >
        <header>
            <table width="100%">
            <tr>
            <td>
                <img src="${DCClogo}" alt="Trustee" height="70" />
                </td>
                <td valign="bottom">
                </td>
                <td valign="bottom">
                </td>
                <td align="right" valign="bottom">
                <div>
                <div style='text-align: right; margin-top:10px; height:60%; float: right;' id="${q}">
                </div>
                <span style='text-align: right; font-size:9px; margin-right:-53px; margin-top:60px; float: right;'>${txnId}</span>
                </div>
                </td>
            </tr>
            </table>
        </header>
        <hr />
     
        <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
        <br />
            <div>The Chief Manager <br /> ${transaction.bank} <br />  ${branchName}<br />${branchCity}</div>
            <br />
            <div > Sub : <b style="border-bottom:1px solid black">Online Transfer of Fund. </b></div>     
            <br />
            <div> You are requested to online transfer the funds as per below details.</div>
            <br />
            <table  > 
            <tr><td style="font-weight: bold;" colspan="3">Debit Account Details: </td> </tr>
                <tr>
                    <td > Account Title</td> 
                    <td ><b>: ${transaction.account_title} </b></td> 
                </tr>
                <tr>
                    <td>Account No.</td> 
                    <td><b>: ${transaction.account_number} (${transaction.bank}, ${branchCity?branchName +" "+ branchCity.replace(".",""):transaction.branch}) </b></td> 
                </tr>
                <tr>
                    <td style="width: 100px;">Instrument No. </td> 
                    <td><b>: ${transaction.instrument_no} </b></td> 
                </tr>
                </table>

                <table >
                
                <tr> <td style="font-weight: bold;" colspan="3">Credit Account Details: </td> </tr>
                </tr>
                <tr>
                    <td style="width: 100px;"> Account Title</td> 
                    <td><b>: ${transaction.counter_account_title}  </b></td> 
                </tr>
                <tr>
                    <td>Account No.</td> 
                    <td><b>: ${transaction.counter_account_number} (${transaction.counter_bank}, ${counter_branchCity?counter_branchName +" "+ counter_branchCity.replace(".",""):transaction.counter_branch}) </b></td> 
                </tr> 
                <tr>
                    <td> Value Date</td> 
                    <td><b>: ${moment(transaction.execution_date).format('LL')}  </b></td> 
                </tr>
                <tr>
                    <td valign="top"> Amount</td> 
                    <td valign="top">
                        <b>: PKR: ${numberWithCommas(transNetAmount)} (Rupees ${amtWords}) </b>
                    </td>
                </tr> 
             </table>
    
           
           
             <br />
            <div>Please acknowledge receipt of the said letter.</div>
            <br />
            <div>Regards,</div>
            <br />
            <br />
            <br />
            <table style="width: 100%;">
            <tr>
                <td></td>
                <td >
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td style="width: 40%;"> </td>
                <td>
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
            </tr>
        </table>
        <br/>

    </body>
    </html>
    `

  
}

