import React, { useState } from 'react';
import { Container, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { getAllPendingTransactions } from '../../stores/services/transactions.service';
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import ViewTransaction from './../transactions/view-beta-txn';
import { BsTable } from 'react-icons/bs';
import { numberWithCommas } from '../../utils/customFunction';
import { IoMdClose } from 'react-icons/io';
import { toast } from 'react-toastify';
const RejectedTransactions = () => {
  const history = useHistory();
  let [transactions, setTransactions] = useState([]);
  let [searchValue, setSearchValue] = useState('');
  let [activePage, setActivePage] = useState('');
  let [paginationArr, setPaginationArr] = useState(['']);
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  const [trx_history, setTrxHistory] = useState([]);
  const [viewChecklist, setViewCheckList] = useState(false);
  const [trx_checklist, setTrxCheckList] = useState([]);

  const [resLoading, setResLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  // React.useEffect(() => {
  //   await getAllPendingTransactions(	
  //     sessionStorage.getItem('email') || '',	
  //     'REJECTED-TRUSTEE',	
  //     ''	
  //   )	
  //     .then((response) => {	
  //       let pageArr = paginationArr;	
  //       let existingPageIndex = paginationArr.findIndex(	
  //         (x) => x === response.data.page_info.next_page_number	
  //       );	
  //       if (	
  //         existingPageIndex === -1 &&	
  //         response.data.data.length === 10 &&	
  //         10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
  //       ) {		
  //         pageArr.push(response.data.page_info.next_page_number);	
  //       }	
  //       setPaginationArr(pageArr);	
  //       setTransactions(response.data.data);	
  //     })	
  //     .catch((err) => {
  //     });	
  // }, []);

  const [color, setColor] = useState("#db6cfa");
  const styles = {
    color: color
  }

  React.useEffect(() => {
    async function getTransactions() {
      setResLoading(true);
      await getAllPendingTransactions(
        '',
        sessionStorage.getItem('email') || '',
        'REJECTED-TRUSTEE',
        searchValue,
        activePage
      )
        .then((response) => {
          if (response.data.status === 200) {
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
              // &&	
              // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }
            setPaginationArr(pageArr);
            setTransactions(response.data.data);
            setResLoading(false);
          } else if (response.data.status === 404) {
            toast.error(response.data.message);
            setTransactions([]);
            setResLoading(false);
          }
        })
        .catch((err) => {
          setResLoading(false);
        });
      setResLoading(false);
    }
    getTransactions();
  }, [activePage]);

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const searchFilters = async (searchVar = '') => {
    return new Promise(async (resolve, reject) => {
      let search = false;
      let clear = false;
      if (searchVar === '') {
        search = false;
        clear = true;
      } else {
        search = true;
        clear = false;
      }
      setResLoading(true);
      setSearchLoading(search);
      setClearLoading(clear);

      await getAllPendingTransactions(
        '',
        sessionStorage.getItem('email') || '',
        'REJECTED-TRUSTEE',
        searchValue,
        activePage
      )
        .then((response) => {
          if (response.data.status = 200) {
            let pageArr = [''];
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
              // &&	
              // 10 * pageArr.length !==	
              //   parseInt(response.data.page_info.total_count)	
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }
            setPaginationArr(pageArr);
            setTransactions(response.data.data);
            setResLoading(false);
            setSearchLoading(false);
            setClearLoading(false);
            resolve('Success');
          } else if (response.data.status === 404) {
            toast.error(response.data.message);
            setTransactions([]);
            setResLoading(false);
            setSearchLoading(false);
            setClearLoading(false);
          }
        })
        .catch((err) => {
          setResLoading(false);
          setSearchLoading(false);
          setClearLoading(false);
          reject('Error');
        });
      setResLoading(false);
      setSearchLoading(false);
      setClearLoading(false);
    });
  };

  const renderNote = (txn_history) => {
    return txn_history.map((txn) => {
      if (txn.action === 'REJECTED-TRUSTEE') {
        return <p style={{ fontSize: '16px' }}>{txn.note}</p>;
      }
    });
  };
  const renderRejectedBy = (txn_history) => {
    return txn_history.map((txn) => {
      if (txn.action === 'REJECTED-TRUSTEE') {
        return <p style={{ fontSize: '16px' }}>{txn.updated_by}</p>;
      }
    });
  };
  const renderPsxData = () => {
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.type}</td>
          <td>{items.market}</td>
          <td>{items.symbol}</td>
          <td>{items.quantity}</td>
          <td>{items.avg_rate}</td>
          <td>{items.gross_amount}</td>
          <td>{items.deductions}</td>
          <td>{items.additions}</td>
          <td>{items.net_amount}</td>
        </tr>
      );
    });
  };
  const [flagForSecutities, setFlagForSecutities] = useState(false);
  const [securites, setSecurites] = useState<any>([]);
  const renderSecuritiesModalPopup = () => {
    switch (flagForSecutities) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => { setFlagForSecutities(false) }}
          >
            <Modal.Header closeButton onClick={() => {
              setFlagForSecutities(false);
              // clearTaxobject();		
            }}>
              <Modal.Title>View Security</Modal.Title>
            </Modal.Header>
            <div className="modal-body">
              <div className="">
                {securites.length > 0 &&
                  <div className="input-size-pos">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Market</th>
                          <th>Symbol</th>
                          <th>Quantity</th>
                          <th>Avg Rate</th>
                          <th>Gross Amount</th>
                          <th>Deduction</th>
                          <th>Addition</th>
                          <th>Net Amount</th>
                        </tr>
                      </thead>
                      <tbody >
                        {renderPsxData()}
                      </tbody>
                    </table>
                  </div>}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };
  //render table data for tax	
  //view tax table	
  const [viewTaxModal, setTaxModal] = useState(false);
  const [totalTax, setTotalTax] = useState<any>([]);
  //render Tax table data	
  const renderTaxData = () => {
    return totalTax.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.name}</td>
          <td>{items.amount}</td>
          <td>{items.calculatedAmount}</td>
        </tr>
      );
    });
  };
  const renderTaxPopup = () => {
    switch (viewTaxModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => { setTaxModal(false) }}
          >
            <Modal.Header closeButton onClick={() => {
              setTaxModal(false);
              // clearTaxobject();		
            }}>
              <Modal.Title>View Txn Charges</Modal.Title>
            </Modal.Header>
            <div className="modaL-body">
              <div className="">
                {totalTax.length > 0 &&
                  <div className="">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Tax Name</th>
                          <th>Tax Amount</th>
                          <th>Calculated Amount</th>
                        </tr>
                      </thead>
                      <tbody >
                        {renderTaxData()}
                      </tbody>
                    </table>
                  </div>}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };
  //for history	
  const [flagForHistory, setFlagForHistory] = useState(false);
  const [historyObj, setHistoryObj] = useState<any>([]);
  const renderHistoryData = () => {
    return historyObj.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.action}</td>
          <td>
            {items.created_at
              ? moment(items.created_at).format('DD-MM-YYYY')
              : moment(historyObj[0].created_at).format('DD-MM-YYYY')}
          </td>
          <td>
            {items.created_by ? items.created_by : historyObj[0].created_by}
          </td>
          <td>{items.note}</td>
          <td>
            {items.updated_at
              ? moment(items.updated_at).format('DD-MM-YYYY')
              : ''}
          </td>
          <td>{items.updated_by}</td>
          <td>{items.updated_by_role}</td>
        </tr>
      );
    });
  };
  const renderHistoryModalPopup = () => {
    switch (flagForHistory) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => { setFlagForHistory(false) }}
          >
            <div className="modal-header justify-content-center">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForHistory(false);
                  // clearTaxobject();		
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View history</h6>
            </div>
            <div className="modal-body">
              <div className="">
                {historyObj.length > 0 &&
                  <div className="">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Created At</th>
                          <th>Created By</th>
                          <th>Note</th>
                          <th>Updated At</th>
                          <th>Updated By</th>
                          <th>Role</th>
                        </tr>
                      </thead>
                      <tbody >
                        {renderHistoryData()}
                      </tbody>
                    </table>
                  </div>}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };
  const [view, setView] = useState(false);
  const [data, setData] = useState<any>([]);
  const titleCase = (value: string) => {
    let sentence = value.toLowerCase().split('_');
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
  };
  const renderData = () => {
    return data.map((value: any, index: number) => {
      return (
        <>
          {value[0] !== 'file' && value[1] !== '' && value[1] !== 0 ? (
            <Col md="6" key={index}>
              <div className="input-holder">
                <label>{titleCase(value[0])}</label>
                {value[0] === 'securities' ?
                  <div className="form-control flex justify-content-center" style={{ background: '#0e4646', color: 'white', cursor: 'pointer' }}
                    onClick={() => {
                      setFlagForSecutities(true);
                      setSecurites(JSON.parse(value[1]))
                    }}
                  >
                    View Securities
                  </div>
                  :
                  value[0] === 'txn_charges' ?
                    <div className="form-control flex justify-content-center" style={{ background: '#0e4646', color: 'white', cursor: 'pointer' }}
                      onClick={() => {
                        setTaxModal(true);
                        setTotalTax(JSON.parse(value[1]))
                      }}
                    >
                      View Charges
                    </div>
                    // <AiFillEye className="m-3"/>		
                    :
                    value[0] === 'txn_history' ?
                      <div className="form-control flex justify-content-center" style={{ background: '#0e4646', color: 'white', cursor: 'pointer' }}
                        onClick={() => {
                          setFlagForHistory(true);
                          setHistoryObj(value[1])
                        }}
                      >
                        View History
                      </div>
                      // <AiFillEye className="m-3"/>		
                      :
                      <div className="form-control">
                        <p
                          style={{
                            fontSize: '15px',
                          }}
                        >
                          {value[1] && (value[1].toString()).includes("Pakistan Standard") ?
                            moment(value[1].toString()).format("DD-MM-YYYY h:mm a")
                            : value[1] && value[1].toString()}
                        </p>
                      </div>}
              </div>
            </Col>
          ) : (
            ''
          )}
        </>
      );
    });
  };

  const [note, setNote] = useState('');
  const viewCheckListPopup = () => {
    switch (viewChecklist) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewCheckList(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Approval Check List </h6>
            </div>


            <div className="modal-body">
              <div className=" ">
                <div className={'checlist-scroll'}>
                  {trx_checklist.map((val: any, ind: any) => {
                    return (
                      <Row key={ind} className="">

                        <Col md="5">
                          <text className="text-primary">{val.serial_no}. </text> <text>{val.message}</text>
                        </Col>
                        <Col md="7">
                          <Row>
                            <Col md="3">

                              <FormGroup check>
                                <Label check>
                                  <Input
                                    checked={val.value == 'compliant'}
                                    type="checkbox"
                                    value="compliant"
                                    readOnly />
                                  <span className="form-check-sign" />
                                  Compliant
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col md="2">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox"
                                    checked={val.value == 'na'}
                                    value="na"
                                    readOnly />
                                  <span className="form-check-sign" />
                                  N /A
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col md="7">
                              <FormGroup>
                                <input
                                  type="text"
                                  placeholder="Comments"
                                  value={val.comment}
                                  className="form-control"
                                  readOnly
                                />
                              </FormGroup>

                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                {note !== '' && (
                  <Row>
                    <Col md="12">
                      <label>Reason of Reject</label>
                      <div className="form-group">
                        <textarea
                          placeholder="Reason"
                          className={'form-control w-100'}
                          value={note}
                          readOnly
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setViewCheckList(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };



  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              {!view ? (
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Rejected Transactions</h4>
                  </div>
                  <div className="card-body">
                    {/* <div className="input-holder">		
                <div className="form-group inline-box">		 */}
                    <Row>
                      <Col md="4">
                        <div className='form-group'>
                          <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                          />
                          <input
                            type="search"
                            id="myInput"
                            placeholder="Transaction Id"
                            data-tip="Search Transaction"
                            className="form-control mr-2"
                            value={searchValue}
                            onChange={(e) => {
                              setSearchValue(e.target.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="6">
                        <button
                          className="btn btn-primary mr-2 mt-a"
                          disabled={Boolean(searchLoading)}
                          onClick={() => {
                            searchFilters(searchValue).then(() => {
                              setActivePage('');
                            });
                          }}
                        >
                          {searchLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Searching...</span>
                            </>
                          ) : (
                            'Search'
                          )}
                          {/* <i className="fa fa-search"></i> Search		 */}
                        </button>
                        <button
                          className="btn btn-default mt-a"
                          disabled={Boolean(clearLoading)}
                          onClick={() => {
                            setSearchValue('');
                            searchFilters('').then(() => {
                              setActivePage('');
                              
                            });
                          }}
                        >
                          {/* <i className="fa fa-trash"></i> Clear*/}
                          {clearLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Clear</span>
                            </>
                          ) : (
                            'Clear'
                          )}
                        </button>
                      </Col>
                    </Row>
                    {/* </div>		
              </div> */}

                    <div className="">
                      {resLoading ? (
                        <div className="d-flex justify-content-center my-5">
                          <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                        </div>
                      ) : (
                        transactions.length > 0 ? (
                          <div className='table-responsive'>
                            <table className="table mt-2">
                              <thead>
                                <tr>
                                  <th>Transaction Id</th>
                                  <th>Transaction Type</th>
                                  <th>Create By</th>
                                  <th>Date Created</th>
                                  <th>Status</th>
                                  <th>Net Amount</th>
                                  <th>Reason</th>
                                  <th>Rejected By</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {transactions.map((transaction: any) => {
                                  return (
                                    <tr>
                                      <td>{transaction.txn_id.replaceAll("TXN_", "")}</td>
                                      <td>
                                        {transaction.txnr_type
                                          ? CapTxnType(transaction.txnr_type)
                                          : 'N/A'}
                                      </td>
                                      <td>
                                        {transaction.created_by
                                          ? transaction.created_by
                                          : 'N/A'}
                                      </td>
                                      <td>
                                        {transaction.txn_history
                                          ? transaction.txn_history[0]?.created_at
                                            ? moment(
                                              transaction.txn_history[0].created_at
                                            ).format('DD-MM-YYYY')
                                            : 'N/A'
                                          : 'N/A'}
                                      </td>
                                      <td>
                                        {transaction.txn_status === 'REJECTED-TRUSTEE'
                                          ? 'REJECTED'
                                          : ''}
                                      </td>
                                      <td className="text-right">{numberWithCommas(transaction.net_amount)}</td>
                                      <td>{renderNote(transaction.txn_history)}</td>
                                      <td>{renderRejectedBy(transaction.txn_history)}</td>
                                      <td className=" ">
                                        <div className="multi-icons pointer">

                                          <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {
                                            setView(true);
                                            setData(transaction);
                                            setTrxHistory(transaction.txn_history);
                                          }}>
                                            <i className="fa fa-eye"> </i>
                                          </button>


                                          <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {
                                            sessionStorage.setItem(
                                              'rejectedTxObj',
                                              JSON.stringify(transaction)
                                            );
                                            sessionStorage.setItem(
                                              'rejectedFlag',
                                              'true'
                                            );
                                            history.replace(
                                              `/admin/beta-transaction/${transaction.txnr_type}`
                                            );
                                          }}>
                                            <i className="tim-icons icon-pencil"> </i>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">No record found</p>
                          </div>
                        )
                      )}
                      <div
                        className="row pointer mr-3"
                        style={{
                          float: 'right',
                        }}
                      >
                        {/* PREVIOUS BUTTON */}
                        {activePage !== paginationArr[0] ? (
                          <i
                            className="fa fa-arrow-left"
                            aria-hidden="true"
                            onClick={() => {
                              let pageArr = paginationArr;
                              let index = pageArr.findIndex((x) => x === activePage);
                              if (index !== -1) {
                                setActivePage(paginationArr[index - 1]);
                              }
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                        {/* NEXT BUTTON */}
                        {activePage !== paginationArr[paginationArr.length - 1] ? (
                          <i
                            className="fa ml-3 fa-arrow-right"
                            aria-hidden="true"
                            onClick={() => {
                              let pageArr = paginationArr;
                              let index = pageArr.findIndex((x) => x === activePage);
                              if (index !== -1) {
                                setActivePage(paginationArr[index + 1]);
                              }
                            }}
                          ></i>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" ">
                  <h4>
                    <span>
                      <i className="fa fa-angle-left mr-2 mt-1 pointer" onClick={() => {
                        setView(false);
                      }}></i>
                      View - Rejected Transaction
                    </span>
                    <span>
                      <Link to='/admin/rejected-transaction'>
                        <IoMdClose size={35} className='ml-2 mt-1 pointer float-right'
                          style={styles}
                          onMouseEnter={() => setColor("white")}
                          onMouseLeave={() => setColor("#db6cfa")}
                          onClick={() => {
                            setView(false);
                          }}
                        />
                      </Link>
                    </span>
                  </h4>
                  <ViewTransaction data={data} />
                  <div className="line"></div>
                  <Row>
                    {trx_history.map((hist: any, index: number) => {
                      return (
                        <Col
                          md="3"
                          onClick={() => {
                            if (hist.action === 'REJECTED-TRUSTEE' || hist.action === 'REJECTED-AMC') {
                              setNote(hist.note);
                            } else {
                              setNote('');
                            }
                            if (hist.action === 'CREATED') {
                              return;
                            }
                            if (!hist.check_list) return;
                            setTrxCheckList(hist.check_list);
                            setViewCheckList(true);
                          }}
                        >
                          <div className="card process-card pointer">
                            <div className="card-header">
                              <h4 className="card-title">
                                <span className="card-number">
                                  {index + 1}
                                </span>
                                <span className="mr-1">
                                </span> <i className="tim-icons icon-single-02 mr-1"></i> 	 {hist.updated_by_role}
                              </h4>
                            </div>
                            <div className="card-body text-center">

                              <p className="small mt-2">Status:</p>
                              <h3 className="accepted text-primary">  {hist.action}    </h3>
                              {hist.updated_at !== '' ? (
                                <>
                                  <p className="small mt-2">Last update</p>
                                  <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MM-YYYY, h:mm a")}</h4>
                                </>
                              ) : (
                                ''
                              )}
                              {hist.note !== '' ? (
                                <>
                                  <p className="small mt-2">Comment</p>
                                  <h5 className="comment">{hist.note}</h5>
                                </>
                              ) : (
                                ''
                              )}
                              {
                                hist.created_by !== '' ? (
                                  <>
                                    <p className="small mt-2">Created By</p>
                                    <h4>{hist.created_by}</h4>
                                    <p className="small mt-2">Created At</p>
                                    <h4 className="comment">  {moment(hist.created_at).format("DD-MM-YYYY")}</h4>
                                  </>
                                ) : (
                                  ''
                                )}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
              {viewCheckListPopup()}
              {renderSecuritiesModalPopup()}
              {renderTaxPopup()}
              {renderHistoryModalPopup()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default RejectedTransactions;	
