import React, { useState, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getFundByAmc,
  netAssetsUpload,
} from "../../../stores/services/funds.service";
import { getAmc } from "../../../stores/services/amc.service";
import { JsonToTable } from "react-json-to-table";
import xls from "xlsx";
import moment from "moment";
import { Modal } from "react-bootstrap";

const SetupNetUploadAssets = () => {
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [Amc, setAmc] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [file, setFile] = useState("");
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const [fileData, setfileData] = useState<any>([]);
  const [fileDataCount, setFileDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [invalidFormatData, setInvalidFormatData] = useState<any>([]);
  const [invalidFormatPopup, setInvalidFormatPopup] = useState(false);
  const [refreshAMC, setRefreshAMC] = useState(false);

  const myRef1 = useRef<HTMLInputElement>(null);
  const email = sessionStorage.getItem("email") || "";

  React.useEffect(() => {
    const getAllAMC = async () => {
      allAmc.length=0
      setAllAmc(allAmc)
      const amcResponse = await getAmc(email, "", "", "");
      setAllAmc(amcResponse.data.data);
    };
    getAllAMC();
  }, [refreshAMC]);

  const renderAmcDropdown = () => {
    return allAmc.map((item: any, index: number) => {
      return (
        <option key={index} value={item.amc_code.replace("AMC_", "")}>
          {item.name}
        </option>
      );
    });
  };
  const getFunds = async (code: any) => {
    try {
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
    } catch (err: any) {
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };
  const ErrorBorder = (value) => {
    if (errorShow && value === "") {
      return "required-border";
    } else {
      return "";
    }
  };

  const UploadNetAssets = async () => {
    if (Amc === "") {
      setErrorShow(true);
    } else {
      if (file) {
        setLoading(true);
        try {
          const res = await netAssetsUpload(email, fileData);
          if (res.data.status == 200) {
            setRefreshAMC(!refreshAMC);
            toast.success(res.data.message);
            setFileDataCount(0);
            fileData.length = 0;
            setfileData(fileData);
            setAmc('')
            setFileName('')
          } else {
            toast.error(res.data.message);
          }
          setLoading(false);
        } 
        catch (err: any) {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        }
        setLoading(false);
      } else {
        toast.error("Kindly Upload File");
      }
    }
  };

  const upload = async (e: any) => {
    setFileError("");
    setfileData(null);
    invalidFormatData.length = 0;
    setInvalidFormatData(invalidFormatData);
    let regExp = /[a-zA-Z]/g;
    let file = e?.target.files[0];
    let filesize = parseInt(file.size);
    if (filesize <= 2097152) {
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (type === "xlsx" || type === "csv") {
        setFile(file);
        setFileName(file.name);
        //start
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target?.result;
          const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data: any = xls.utils.sheet_to_json(ws, {
            header: 2,
            raw: false,
            defval: "",
            dateNF: "YYYY-MM-DD",
          });

          let matchFundLength: any = [];
          for (let i = 0; i < allFunds.length; i++) {
            data.find((item, k) => {
              if (
                item["FUND"] &&
                item["FUND"].trim().toUpperCase() === allFunds[i].symbol_code
              ) {
                matchFundLength.push(item["FUND"]);
              }
            });
          }

          for (let i = 0; i < data.length; i++) {
            if (
              !data[i]["DATE"] ||
              !moment(data[i]["DATE"], "YYYY-MM-DD", true).isValid()
            ) {
              let temp = invalidFormatData;
              temp.push({
                row_number: i + 1,
                field_name: "DATE",
                message: !data[i]["DATE"] ? "empty field" : "invalid date",
              });
              setInvalidFormatData([...temp]);
            }
            if (!data[i]["FUND"] || data[i]["FUND"].trim() === "") {
              let temp = invalidFormatData;
              temp.push({
                row_number: i + 1,
                field_name: "FUND",
                message: "empty field",
              });
              setInvalidFormatData([...temp]);
            }
            if (
              !data[i]["TOTAL_NET_ASSET"] ||
              regExp.test(data[i]["TOTAL_NET_ASSET"].trim())
            ) {
              let temp = invalidFormatData;
              temp.push({
                row_number: i + 1,
                field_name: "TOTAL_NET_ASSET",
                message: !data[i]["TOTAL_NET_ASSET"]
                  ? "empty field"
                  : "invalid data",
              });
              setInvalidFormatData([...temp]);
            }
            if (
              !data[i]["TOTAL_FUND_UNITS"] ||
              regExp.test(data[i]["TOTAL_FUND_UNITS"].trim())
            ) {
              let temp = invalidFormatData;
              temp.push({
                row_number: i + 1,
                field_name: "TOTAL_FUND_UNITS",
                message: !data[i]["TOTAL_FUND_UNITS"]
                  ? "empty field"
                  : "invalid data",
              });
              setInvalidFormatData([...temp]);
            }
            if (!data[i]["NAV"] || regExp.test(data[i]["NAV"].trim())) {
              let temp = invalidFormatData;
              temp.push({
                row_number: i + 1,
                field_name: "NAV",
                message: !data[i]["NAV"] ? "empty field" : "invalid data",
              });
              setInvalidFormatData([...temp]);
            }
            if (
              !data[i]["OFFER_PRICE"] ||
              regExp.test(data[i]["OFFER_PRICE"].trim())
            ) {
              let temp = invalidFormatData;
              temp.push({
                row_number: i + 1,
                field_name: "OFFER_PRICE",
                message: !data[i]["OFFER_PRICE"]
                  ? "empty field"
                  : "invalid data",
              });
              setInvalidFormatData([...temp]);
            }
            if (
              !data[i]["REDEMPTION_PRICE"] ||
              regExp.test(data[i]["REDEMPTION_PRICE"].trim())
            ) {
              let temp = invalidFormatData;
              temp.push({
                row_number: i + 1,
                field_name: "REDEMPTION_PRICE",
                message: !data[i]["REDEMPTION_PRICE"]
                  ? "empty field"
                  : "invalid data",
              });
              setInvalidFormatData([...temp]);
            }
            if (
              !data[i]["NET_ASSETS_BEFORE_FEE"] ||
              regExp.test(data[i]["NET_ASSETS_BEFORE_FEE"].trim())
            ) {
              let temp = invalidFormatData;
              temp.push({
                row_number: i + 1,
                field_name: "NET_ASSETS_BEFORE_FEE",
                message: !data[i]["NET_ASSETS_BEFORE_FEE"]
                  ? "empty field"
                  : "invalid data",
              });
              setInvalidFormatData([...temp]);
            }
            if (
              !data[i]["TRUSTEE_FEE"] ||
              regExp.test(data[i]["TRUSTEE_FEE"].trim())
            ) {
              let temp = invalidFormatData;
              temp.push({
                row_number: i + 1,
                field_name: "TRUSTEE_FEE",
                message: !data[i]["TRUSTEE_FEE"]
                  ? "empty field"
                  : "invalid data",
              });
              setInvalidFormatData([...temp]);
            }
            if (!data[i]["VERIFIED"] || data[i]["VERIFIED"].trim() === "") {
              let temp = invalidFormatData;
              temp.push({
                row_number: i + 1,
                field_name: "VERIFIED",
                message: "empty field",
              });
              setInvalidFormatData([...temp]);
            }
            data[i]["AMC_CODE"]=Amc
          }

          /* Update state */
          if (invalidFormatData.length > 0) {
            setFileError("");
            setFile("");
            setFileName("");
            setfileData([]);

            setFileDataCount(0);
            setInvalidFormatPopup(true);
          } else if (matchFundLength.length != data.length) {
            setFileError("");
            setFile("");
            setFileName("");
            setfileData([]);

            setFileDataCount(0);
            toast.error("Fund not exist in AMC");
          } else if (data && invalidFormatData.length == 0) {
            setfileData(data);
            setFileDataCount(data.length);
          }
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      } else {
        toast.error("Please Upload Correct Format of File");
      }
    } else {
      toast.error("File size should be less than 2MB");
    }
  };

  const renderinvalidFormatPopup = () => {
    switch (invalidFormatPopup) {
      case true:
        return (
          <Modal className=" " dialogClassName="modal60w" show={true}>
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setInvalidFormatPopup(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Kindly Fix the below error </h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  {/* <div className="mx-auto">
                        <h1 className="pb-3">Kindly fill following fields data</h1>
                        Missing Fields
                      </div> */}
                  <div className={"d-flex border w-100"}>
                    <div className={"w-50"}>Row Number</div>
                    <div className={"w-50"}>Field Name</div>
                    <div className={"w-50"}>Error</div>
                  </div>
                  {invalidFormatData.map((item, index) => {
                    return (
                      <div className={"d-flex w-100"}>
                        <div className={"w-50"}>{item.row_number}</div>
                        <div className={"w-50"}>{item.field_name}</div>
                        <div className={"w-50"}>{item.message}</div>
                      </div>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  return (
    <div className="content">
      <Row> 
        <Col md="12">
          <Container fluid>
            <ToastContainer limit={1} />
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  Upload Net Assets
                  <Dropdown className="float-right">
                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                      Download Samples
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="./../../Uploader-Sample/Net-Assets-Uploader.xlsx"
                        download
                      >
                        Net Assets Uploader
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </h4>
              </div>
              <div className="card-body">
                <Row>
                  <Col md="2">
                    <div className="form-group  ">
                      <label>Select AMC</label>
                      <select
                        className={"form-control " + ErrorBorder(Amc)}
                        onChange={(e) => {
                          setAmc(e.target.value);
                          getFunds(e.target.value);
                          allFunds.length = 0;
                          setAllFunds(allFunds);
                          // clear fields
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select AMC
                        </option>
                        {renderAmcDropdown()}
                      </select>
                    </div>
                  </Col>
                  <Col md="2">
                  <div className="">
                      <label>Choose File</label>
                      <div
                        className="multi-input pointer"
                        onClick={() => myRef1?.current?.click()}
                      >
                        <div className="form-group">
                          <div className="form-control">
                            {fileName === "" ? "Upload File" : fileName}
                          </div>
                          <input
                            type="file"
                            ref={myRef1}
                            style={{ display: "none" }}
                            multiple={false}
                            onChange={(e) => {
                              upload(e);
                              e.target.value = "";
                            }}
                          />
                          {fileError ? (
                            <p className="error-labels error-message2">
                              {fileError}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {fileUploadingFlag && (
                        <img src="assets/loader.svg" width="50" />
                      )}
                    
                  
                </div>
                  </Col>
                </Row>
                
                {fileDataCount <= 0 ? (
                  ""
                ) : (
                  <div className="table-responsive">
                    <JsonToTable json={fileData} />
                  </div>
                )}
                Total Rows:
                <span className="text-primary mt-3">{fileDataCount} </span>
                <div className="mt-4">
                  <button
                    className="btn btn-primary "
                    onClick={() => {
                      UploadNetAssets();
                    }}
                    disabled={Boolean(loading)}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Upload</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            {renderinvalidFormatPopup()}
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default SetupNetUploadAssets;
