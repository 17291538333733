declare global {
    interface Window {
      env: any
    }
  }
  
  // change with your own variables
  type EnvType = {
      // for PTCL
      // REACT_APP_TITLE: "MACS Core Alpha",
      // REACT_APP_VERSION: "v3.0.1",
      // REACT_APP_BASE_URL: "http://192.168.1.134:3000/api",
      // REACT_APP_SOCKET_URL: "http://192.168.1.134:3000/",
      // REACT_APP_IMAGE_URL: "http://192.168.1.134:3000/",
      // CAPTCHA_ENABLED: "false", // true or false
      // REACT_LOGIN_REGISTER: "http://192.168.1.134:3011",
      // REACT_APP_QR_URL: "http://192.168.1.134:3005",

      // Online work
      // REACT_APP_TITLE: "MACS Core Alpha",
      // REACT_APP_VERSION: "v3.0.1",
      // REACT_APP_BASE_URL: "http://202.69.62.51:5520/api",
      // REACT_APP_SOCKET_URL: "http://202.69.62.51:5520/",
      // REACT_APP_IMAGE_URL: "http://202.69.62.51:5520/",
      // CAPTCHA_ENABLED: "false", // true or false
      // REACT_LOGIN_REGISTER: "http://202.69.62.51:5521",
      // REACT_APP_QR_URL: "http://202.69.62.51:5520",

       // Online work from Home
      //dev http
      // REACT_APP_TITLE: "MACS Core Alpha",
      // REACT_APP_VERSION: "v3.0.8",
      // REACT_APP_BASE_URL: "http://203.128.8.2:5520/api",
      // REACT_APP_SOCKET_URL: "http://203.128.8.2:5520/",
      // REACT_APP_IMAGE_URL: "http://203.128.8.2:5520/",
      // CAPTCHA_ENABLED: "false", // true or false
      // REACT_LOGIN_REGISTER: "http://203.128.8.2:5521",
      // REACT_APP_QR_URL: "http://203.128.8.2:5520",

      //DEV https
      REACT_APP_TITLE: "MACS Core Alpha DEV",
      REACT_APP_VERSION: "v3.0.8",
      REACT_APP_BASE_URL: "https://dccl.pk:3000/api",
      REACT_APP_SOCKET_URL: "https://dccl.pk:3000/",
      REACT_APP_IMAGE_URL: "https://dccl.pk:3000/",
      CAPTCHA_ENABLED: "false", // true or false
      REACT_LOGIN_REGISTER: "https://dccl.pk:3011",
      REACT_APP_QR_URL: "https://dccl.pk:3000/",


    // For NayaTel
    // REACT_APP_TITLE: "MACS Core Alpha UAT QA",
    // REACT_APP_VERSION: "v2.2.7",
    // REACT_APP_BASE_URL: "http://124.109.39.158:3000/api",
    // REACT_APP_SOCKET_URL: "http://124.109.39.158:3000/",
    // REACT_APP_IMAGE_URL: "http://124.109.39.158:3000/",
    // REACT_APP_QR_URL: "http://124.109.39.158:3000/",
    // CAPTCHA_ENABLED: "true", // true or false

    // VPS-1 - domain name
    // REACT_APP_TITLE: "MACS Core Alpha UAT QA",
    // REACT_APP_VERSION: "v2.2.3",
    // REACT_APP_BASE_URL: "http://digitalcustodian.co:3000/api",
    // REACT_APP_SOCKET_URL: "http://digitalcustodian.co:3000/",
    // REACT_APP_IMAGE_URL: "http://digitalcustodian.co:3000/",
    // REACT_APP_QR_URL: "http://digitalcustodian.co:3000/",
    // CAPTCHA_ENABLED: "true", // true or false

    // for Production
    // REACT_APP_TITLE: "MACS Core Beta",
    // REACT_APP_VERSION: "v2.2.3",
    // REACT_APP_BASE_URL: "https://prod.macs-core.com:3000/api",
    // REACT_APP_SOCKET_URL: "https://prod.macs-core.com:3000/",
    // REACT_APP_IMAGE_URL: "https://prod.macs-core.com:3000/",
    // REACT_APP_QR_URL: "https://prod.macs-core.com:3000/",
    // CAPTCHA_ENABLED: "true", // true or false
  }
  export const env: EnvType = { ...process.env, ...window.env }