import axios from 'axios';
import https from 'https';
import { any } from 'prop-types';
import Config from '../../config/index';
import RefreshTokenHandler from './refresh-token';
//import preval from 'babel-plugin-preval/macro'
var { HttpsProxyAgent } = require('https-proxy-agent');
const jks = require('jks-js');



// let keystore = preval`
//   const fs = require('fs')
//   module.exports = fs.readFileSync(require.resolve('./cddcuat.cer'))
// `



const allCDCData = async () => {
  const agent = new https.Agent({
    rejectUnauthorized: false
  })

  try {
    let result = await axios({
      method: 'post',
      url: `https://cdcwebserviceuat.cdcpk.com:5013/DCCL/Services/getCDSAccountBalance`,
      // data: { uid, password, reserved1, reserved2, reserved3 },
      data: {
        "userId": "DCCL01",
        "password": "test123",
        "reserved1": "",
        "reserved2": "",
        "reserved3": ""
      },
      headers: { 'Content-Type': 'application/json' },
      httpsAgent: agent,
    });
    return result;
  } catch (err: any) {
    throw err
  }
}

// const cdcCerificate = async () => {
//   const email = sessionStorage.getItem('email') || '';
//   const url = `${Config.baseUrl}/cdc/?email=${email}`;
//   try {
//     const result: any = await axios.get(url, {
//       headers: {
//         Authorization: sessionStorage.getItem('token') || '',
//       },
//     });
//     return result;
//   } catch (err: any) {
//     if (err.response.data.status == 401) {
//       let responseToHandle = err.response.data
//       let result = await RefreshTokenHandler.handleIt(responseToHandle)
//       if (result.status) {
//         return await cdcCerificate()
//       } else {
//         throw err;
//       }
//     } else {
//       throw err
//     }
//   }
// }

export {
  allCDCData,
  // cdcCerificate
};