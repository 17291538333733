import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

const addTax = async (
  email: string,
  code: string,
  transaction_type: string,
  name: string,
  days: string,
  amount_from: string,
  fixed_charges: string,
  percentage: string,
  created_at: string,
  description: string,
  amount_to: string,
  rate: string
) => {
  const url = `${Config.baseUrl}/tax/`;
  try{
  const result: any = await axios.post(
    url,
    {
      email,
      code,
      transaction_type,
      name,
      days,
      amount_from,
      fixed_charges,
      percentage,
      created_at,
      description,
      amount_to,
      rate,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await addTax(email,code,transaction_type, name, days, amount_from, fixed_charges, percentage,created_at,description,amount_to, rate)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};

const editTax = async (
  email: string,
  code: string,
  transaction_type: string,
  name: string,
  days: string,
  amount_from: string,
  fixed_charges: string,
  percentage: string,
  created_at: string,
  description: string,
  amount_to: string,
  rate: string
) => {
  const url = `${Config.baseUrl}/tax/update`;
  try{
  const result: any = await axios.post(
    url,
    {
      email,
      code,
      transaction_type,
      name,
      days,
      amount_from,
      fixed_charges,
      percentage,
      created_at,
      description,
      amount_to,
      rate,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await editTax(email,code,transaction_type, name, days, amount_from, fixed_charges, percentage, created_at, description, amount_to, rate)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};
const getAllTax = async (
  email: string,
  page_number: string = '',
  page_size: string = ''
) => {
  const url = `${Config.baseUrl}/tax?email=${email}&page_number=${page_number}&page_size=${page_size}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAllTax(email,page_number,page_size)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 *
 * @param search_value
 */
const searchTaxAPI = async (email: string, search_value: string) => {
  const url = `${Config.baseUrl}/tax?email=${email}&search_value=${search_value}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await searchTaxAPI(email,search_value)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const getTaxAmount = async (
  email: string,
  type: string,
  amount: string,
  tax_name: string
) => {
  const url = `${Config.baseUrl}/tax/tax-amount?email=${email}&type_of_transaction=${type}&amount=${amount}&tax_name=${tax_name}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getTaxAmount(email,type,amount,tax_name)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const getTaxType = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/tax-names?email=${email}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getTaxType(email)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

export { getTaxType, addTax, editTax, getAllTax, searchTaxAPI, getTaxAmount };
