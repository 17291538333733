import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Container, Col, Row } from 'reactstrap';
import {
  getAllBranchs,
  updateBranchStatus,
  searchBranchAPI,
} from '../../../stores/services/branch.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';

const BranchList = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [isSearchActive, setSearchActive] = useState(false);
  let [activePage, setActivePage] = useState('');
  let [paginationArr, setPaginationArr] = useState(['']);
  const [pageSize, setPageSize] = useState('10');

  React.useEffect(() => {
    sessionStorage.removeItem('branchObj');
    const getAllBranchData = async () => {
      setLoading(true);
      try {
        const response = await getAllBranchs(email, activePage, pageSize);
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10
          // &&
          // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        setData(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      setLoading(false);
    };
    getAllBranchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);
  const getBranchListUpdated = async () => {
    setLoading(true);
    try {
      const response = await getAllBranchs(email, activePage, pageSize);
      let pageArr = paginationArr;
      let existingPageIndex = paginationArr.findIndex(
        (x) => x === response.data.page_info.next_page_number
      );
      if (
        existingPageIndex === -1 &&
        response.data.data.length === 10 &&
        10 * pageArr.length !== parseInt(response.data.page_info.total_count)
      ) {
        pageArr.push(response.data.page_info.next_page_number);
      }
      setPaginationArr(pageArr);
      setData(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setLoading(false);
  };
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [currentBranchStatus, setCurrentBranchStatus] = useState('');
  const [code, setCode] = useState('');
  const [branchLoading, setbranchLoading] = useState(false);
  const updateStatus = async () => {
    setbranchLoading(true);
    let status = '';
    if (currentBranchStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateBranchStatus(email, code, status);
      toast.success(response.data.message);
      setStatusSetPopup(false);
      getBranchListUpdated();
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setbranchLoading(false);
  };
  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, tx, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      td = data[i].branch_name;
      tx = data[i].bank_name;
      if (td) {
        if (
          td.toUpperCase().indexOf(filter.toUpperCase()) > -1 ||
          tx.toUpperCase().indexOf(filter.toUpperCase()) > -1
        ) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }

  const [bankBranchError, setBankBranchError] = useState('');
  const validate = () => {
    let bankBranchError = '';
    searchValue.trim() === '' ? (bankBranchError = 'Required') : (bankBranchError = '');

    if (bankBranchError) {
      setBankBranchError(bankBranchError);
      return false;
    } else {
      return true;
    }
  };
  const searchBranchData = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await searchBranchAPI(email, 'BRANCH_' + searchValue);
        setSearchActive(true);
        setPageSize('10');
        setData(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      setLoading(false);
    }
  };

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value.toUpperCase());
    if (e.target.value.length === 0) {
      getBranchListUpdated();
      setSearchActive(false);
      setPageSize('10');
    } else {
      setSearchValue(e.target.value.toUpperCase());
    }
  };


  const renderUpdateBranchStatusPopup = () => {
    switch (statusSetPopup) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal60w"
            show={true}
          >
            <div className="modal-header justify-content-center">
              <button aria-hidden="true" className="close" onClick={() => {
                setStatusSetPopup(false);
              }} data-dismiss="modal" type="button">
                <i className="tim-icons icon-simple-remove"></i>
              </button>
              <h6 className="title title-up">Action</h6>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    {currentBranchStatus === 'active' ? (
                      <img src="assets/ban.svg" alt="" width="70" />
                    ) : (
                      <img src="assets/check.svg" alt="" width="70" />
                    )}
                    <h1 className="pb-3">Are You Sure?</h1>
                    Are you sure you want to{' '}
                    {currentBranchStatus === 'active'
                      ? 'Deactivate'
                      : 'Activate'}{' '}
                    this Branch?
                  </div>
                </Row>
                <Row>
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        disabled={Boolean(branchLoading)}
                        onClick={() => {
                          updateStatus();
                        }}
                      >
                        {branchLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          'Yes'
                        )}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setStatusSetPopup(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };
  const renderData = () => {
    return res.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.branch_code.replace('BRANCH_', '')}</td>
          <td>{items.branch_name}</td>
          <td>{items.branch_address}</td>
          <td className="captilize">{items.status}</td>
          <td className="pointer">
            <div
              className="mr-3">
              {items.status !== 'active' ? (
                <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm"
                onClick={() => {
                  setStatusSetPopup(true);
                  setCurrentBranchStatus(items.status);
                  setCode(items.branch_code.replace("BRANCH_", ''));
                }}
              >
                  <i className="tim-icons icon-simple-remove"> </i>
                </button>
              ) : (
                <button type="button" className="btn-icon btn-link like btn btn-success btn-sm"
                onClick={() => {
                  setStatusSetPopup(true);
                  setCurrentBranchStatus(items.status);
                  setCode(items.branch_code.replace("BRANCH_", ''));
                }}>
                  <i className="fa fa-check"></i>
                </button>
              )}
              <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {
                sessionStorage.setItem('branchObj', JSON.stringify(items));
                history.replace(`/admin/edit-branch`);
              }}>
                <i className="tim-icons icon-pencil"></i>
              </button>


              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {
                sessionStorage.setItem('branchObj', JSON.stringify(items));
                history.replace('/admin/view-branch');
              }}>
                <i className="fa fa-eye"></i>
              </button>

            </div>

          </td>
        </tr>
      );
    });
  };
  return (
    <>

      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Branch Listing
                    <div
                      className="btn btn-primary btn-sm float-right"
                      onClick={() => {
                        history.replace('/admin/setup-branches');
                      }}
                    >
                      <span><i className="fa fa-plus mr-2"></i>Add New</span>
                    </div></h4>
                </div>
                {!Loading ? (
                  <div className="card-body">

                    <Row>
                      <Col md="4">
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <div className="form-group">
                          <input
                            type="search"
                            id="myInput"
                            placeholder="Enter Branch Code"
                            data-tip="Enter Branch Code"
                            className={`form-control  w-100 ${bankBranchError ? 'required-border' : ''
                              }`}
                            value={searchValue}
                            onChange={(e) => {
                              // setSearchValue(e.target.value);	
                              // myFunction(e);	
                              handleSearchValue(e);
                              setBankBranchError('')
                            }}
                            onKeyPress={(e) => {
                              let key_board_keycode = e.which || e.keyCode;
                              if (key_board_keycode == 13) {
                                e.preventDefault();
                                searchBranchData()
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <button className="btn btn-primary mt-a " onClick={searchBranchData}>
                          <i className="fa fa-search"></i> Search
                        </button>
                      </Col>
                      {/* <div className="date">	
                    <img src="assets/search.svg" alt="" width="16" />	
                  </div> */}

                    </Row>


                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Branch Code</th>
                            <th>Branch Name</th>
                            <th>Branch Address</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>{renderData()}</tbody>
                      </table>
                      {!isSearchActive &&
                        <div
                          className="row pr-3 pointer"
                          style={{
                            float: 'right',
                          }}
                        >
                          {/* PREVIOUS BUTTON */}
                          {activePage !== paginationArr[0] ? (
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                              onClick={() => {
                                let pageArr = paginationArr;
                                let index = pageArr.findIndex((x) => x === activePage);
                                if (index !== -1) {
                                  setActivePage(paginationArr[index - 1]);
                                }
                              }}
                            ></i>
                          ) : (
                            ''
                          )}
                          {/* NEXT BUTTON */}
                          {activePage !== paginationArr[paginationArr.length - 1] ? (
                            <i
                              className="fa ml-3 fa-arrow-right"
                              aria-hidden="true"
                              onClick={() => {
                                let pageArr = paginationArr;
                                let index = pageArr.findIndex((x) => x === activePage);
                                if (index !== -1) {
                                  setActivePage(paginationArr[index + 1]);
                                }
                              }}
                            ></i>
                          ) : (
                            ''
                          )}
                        </div>}

                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center my-5">
                    <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                  </div>
                )}
                {renderUpdateBranchStatusPopup()}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default BranchList;	
