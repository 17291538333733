import React, { useRef, useEffect, useState } from "react";
import jsPDF from "jspdf";
import { Container, FormGroup, Label, Input } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as htmlToImage from "html-to-image";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import html2canvas from "html2canvas";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReactToPrint from "react-to-print";

type Coordinates = {
  id: string;
  x: number;
  y: number;
  label: string;
  prefix: string;
  postFix: string;
};

const postionCoordinates: Coordinates[] = [
  {
    id: "E1",
    x: 0,
    y: 0,
    label: "Date",
    prefix: "",
    postFix: "",
  },
  {
    id: "E2",
    x: 0,
    y: 30,
    label: "Name",
    prefix: "",
    postFix: "",
  },
  {
    id: "E3",
    x: 0,
    y: 50,
    label: "Amount Fig",
    prefix: "=",
    postFix: "/-",
  },
  {
    id: "E4",
    x: 0,
    y: 70,
    label: "Amount Words",
    prefix: "",
    postFix: "",
  },
];

const PrintCheque = React.forwardRef((props: any, ref) => {
  const history = useHistory();
  let templateData = JSON.parse(sessionStorage.getItem("template") || "");
  let view_txn_from = sessionStorage.getItem("view_txn_from") || "";
  let mode_of_payment_Details = JSON.parse(
    sessionStorage.getItem("modeofpaymentCheque") || ""
  );
  const [headLabel, setHeadLabel] = React.useState(""); // label represent transaction name

  React.useEffect(() => {
    setHeadLabel(props.match.params.type);
    mode_of_payment_Details = JSON.parse(
      sessionStorage.getItem("modeofpaymentCheque") || ""
    );
    if (
      mode_of_payment_Details &&
      mode_of_payment_Details["mode_of_payment"] &&
      (mode_of_payment_Details["mode_of_payment"] === "RTGS" ||
        mode_of_payment_Details["mode_of_payment"] === "PO/DD")
    ) {
      setInFavorOfBankCheck(true);
      setCnicCheck(false);
    }
  }, [props.match.params.type]);

  const cnic = templateData[1].label.split("–")[1];
  const bank_name = mode_of_payment_Details["bank_name"];
  templateData[1].label = templateData[1].label.split("–")[0];

  const [coordinates, setCoordinates] = useState(templateData);
  const email = sessionStorage.getItem("email") || "";
  const [mamoCheck, setMemoCheck] = useState<boolean>(false);
  const [payeeCheck, SetPayeeCheck] = useState<boolean>(true);
  const [cnicnCheck, setCnicCheck] = useState<boolean>(true);
  const [inFavorOfBankCheck, setInFavorOfBankCheck] = useState<boolean>(false);
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const data = JSON.parse(sessionStorage.getItem("cheque") || "");
  const componentRef = useRef(null);

  let pdfFileName = "";
  if (data.txn_id === undefined) {
    pdfFileName = `Cheque ${data.collective_payment_id} ${data.amc_code} ${data.created_at}.pdf`; // Save the cheque
  } else {
    pdfFileName = `Cheque ${data.txn_id} ${data.amc_code} ${data.transaction_date}.pdf`; // Save the cheque
  }

  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handlePrint = () => {
    return <ReactToPrint content={() => componentRef.current} />;
  };

  const Prints = () => (
    // <PDFExport
    //   paperSize="A4"
    //   landscape={false}
    //   scale={0.5}
    //   fileName={pdfFileName}
    //   ref={pdfExportComponent}
    // >
    <div className="a4-page" id={"dateElement"} ref={canvasRefs}>
      <div
        className="a4-page"
        ref={componentRef}
        style={{ position: "absolute" }}
      >
        {coordinates &&
          coordinates.map((element, index) => {
            if (index < 4) {
              return (
                <div
                  key={element.id}
                  className="position-absolute rotate-parent cheque-index"
                  id={element.id}
                  style={{
                    top:
                      element.rotate === 90
                        ? `${element.y}px` //+ 139 + 18
                        : `${element.y}px`,
                    left:
                      element.rotate === 90
                        ? `${element.x}px` //- 139 - 18
                        : `${element.x}px`,
                    fontSize: "12px",

                    width: `${element.width}`,
                    lineHeight: "1.2",

                    height: `${element.heigth}`,
                    // overflowWrap: 'break-word',
                    transform: `rotateZ(${element.rotate}deg)`,
                    transformOrigin: `0 0`,
                  }}
                >
                  {element.prefix}

                  <span className="">
                    {index == 2
                      ? numberWithCommas(element.label)
                      : index == 1 &&
                        (templateData[5].label.includes(
                          "Redemption of Units"
                        ) ||
                          templateData[5].label.includes(
                            "Fund Dividend Payout"
                          )) &&
                        cnic &&
                        cnic != "" &&
                        cnicnCheck &&
                        !inFavorOfBankCheck
                      ? `${element.label} (${cnic})`
                      : index == 1 && inFavorOfBankCheck
                      ? bank_name
                      : element.label}
                    {element.postFix}
                  </span>
                </div>
              );
            }
          })}
        {payeeCheck && (
          <div
            key={coordinates[4].id}
            className="position-absolute cross-cheque"
            id={coordinates[4].id}
            style={{
              top:
                coordinates[4].rotate === 60
                  ? `${coordinates[4].y}px` //+ 43 + 10
                  : `${coordinates[4].y}px`,
              left:
                coordinates[4].rotate === 60
                  ? `${coordinates[4].x}px` //+ 43 + 10
                  : `${coordinates[4].x}px`,
              fontSize: "12px",
              overflowWrap: "break-word",
              width: `${coordinates[4].width}`,
              height: `${coordinates[4].height}`,
              transform: `rotateZ(${coordinates[4].rotate}deg)`,
              transformOrigin: `0 0`,
              textAlign: "center",
            }}
          >
            {coordinates[4].prefix}
            {coordinates[4].label}
            {coordinates[4].postFix}
          </div>
        )}
        {mamoCheck && (
          <div
            key={coordinates[5].id}
            className="position-absolute"
            id={coordinates[5].id}
            style={{
              top:
                coordinates[5].rotate === 90
                  ? `${coordinates[5].y}px` // + 139 + 18
                  : `${coordinates[5].y}px`,
              left:
                coordinates[5].rotate === 90
                  ? `${coordinates[5].x}px` //- 139 - 18
                  : `${coordinates[5].x}px`,
              fontSize: "12px",
              overflowWrap: "break-word",
              width: `${coordinates[5].width}`,
              height: `${coordinates[5].height}`,
              transform: `rotateZ(${coordinates[5].rotate}deg)`,
              transformOrigin: `0 0`,
            }}
          >
            {coordinates[5].prefix}
            {coordinates[5].label} {coordinates[5].postFix}
          </div>
        )}
      </div>
    </div>
    // </PDFExport>
  );

  const canvasRefs = useRef(null);

  // const printContent = () => {
  //   var node = document.getElementById('dateElement') as HTMLElement;
  //   var Options = {
  //     width: 480,
  //     height: 642,
  //   };
  //   htmlToImage
  //     .toPng(node)
  //     .then(function (dataUrl) {
  //       // var img = new Image(680, 842);
  //       //img.src = dataUrl;
  //       var doc = new jsPDF('p', 'px', 'a4'); // make A4 size Pdf file
  //       // var doc = new jsPDF('l', 'pt', 'letter'); // make letter size Pdf file
  //       const pdfWidth = doc.internal.pageSize.getWidth(); // set It width
  //       const pdfHeight = doc.internal.pageSize.getHeight(); // set its Height
  //       // doc.addImage(dataUrl, 'PNG', 0, 0, 446,630,"che", 'NONE' ); // add image to Pdf
  //       doc.addImage(dataUrl, 'PNG', 0, 0, 446,630,"che", 'NONE' ); // add image to Pdf
  //       // doc.html(dataUrl)
  //       // doc.save('Cheque.pdf'); // Save the cheque
  //       var data= JSON.parse(sessionStorage.getItem('cheque') || '');
  //       doc.save(`Cheque ${data.txn_id} ${data.amc_code} ${data.execution_date}.pdf`); // Save the cheque
  //     })
  //     .catch(function (error) {
  //     });

  // };

  const printContent = () => {
    var node = document.getElementById("dateElement") as HTMLElement;
    // if (pdfExportComponent.current) {
    //   pdfExportComponent.current.save();
    // }

    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        var doc = new jsPDF("p", "px", "a4"); // make A4 size Pdf file
        const pdfWidth = doc.internal.pageSize.getWidth(); // set It width
        const pdfHeight = doc.internal.pageSize.getHeight(); // set its Height
        doc.addImage(dataUrl, "PNG", -60, -30, pdfWidth, pdfHeight); // add image to Pdf
        doc.save(pdfFileName); // Save the cheque
      })
      .catch(function (error) {});

    //  html2canvas(node)
    // .then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF('p', 'px', 'a4');
    //     pdf.addImage(imgData, 'PNG',0,0,0,0,"SRK","NONE");
    //     var data= JSON.parse(sessionStorage.getItem('cheque') || '');
    //     if(data.txn_id===undefined){
    //       pdf.save(`Cheque ${data.collective_payment_id} ${data.amc_code} ${data.created_at}.pdf`); // Save the cheque
    //     }else{
    //       pdf.save(`Cheque ${data.txn_id} ${data.amc_code} ${data.transaction_date}.pdf`); // Save the cheque
    //     }

    // })
  };

  // const printContent = () => {
  //   var doc = new jsPDF('p', 'pt', 'a4');

  //  doc.html(document.getElementById('dateElement') as HTMLElement, {
  //     callback: function (doc) {

  //       let data= JSON.parse(sessionStorage.getItem('cheque') || '');
  //       doc.save(`Letter ${data.txn_id} ${data.amc_code} ${data.execution_date}.pdf`);
  //     },
  //     html2canvas: {
  //       scale: 0.8
  //       // width: 500
  //     },
  //     margin: [20, 20, 20, 20],
  //     x: 32,
  //     y: 32,
  //   });
  // }

  const [nameTab, setNameTab] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [Loading, setLoading] = useState(false);
  const pageStyle = "@page {size: A4 portrait;}";

  return (
    <>
      <Container fluid className="pl-1 pr-0">
        <ToastContainer limit={1} />
        <div className="row no-gutters">
          <div className="d-flex">
            <Prints />
            <div className="ml-1 mt-2 mb-2">
              {/* <div className="ml-5"> */}
              <div className="col">
                <FormGroup className="ml-2" check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={mamoCheck}
                      className="mr-3"
                      onChange={(e) => {
                        setMemoCheck(!mamoCheck);
                      }}
                    />
                    <span className="form-check-sign" />
                    Memo
                  </Label>
                </FormGroup>
                <FormGroup className="ml-2" check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={inFavorOfBankCheck}
                      className="mr-3"
                      onChange={(e) => {
                        setInFavorOfBankCheck(!inFavorOfBankCheck);
                        setCnicCheck(false);
                      }}
                    />
                    <span className="form-check-sign" />
                    In favor of Bank
                  </Label>
                </FormGroup>

                <FormGroup check className="ml-2">
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={payeeCheck}
                      onChange={(e) => {
                        SetPayeeCheck(!payeeCheck);
                      }}
                    />
                    <span className="form-check-sign" />
                    Cross Cheque
                  </Label>
                </FormGroup>
                {(templateData[5].label.includes("Redemption of Units") ||
                  templateData[5].label.includes("Fund Dividend Payout")) &&
                  cnic &&
                  cnic != "" && (
                    <FormGroup check className="ml-2">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={cnicnCheck}
                          onChange={(e) => {
                            setCnicCheck(!cnicnCheck);
                            setInFavorOfBankCheck(false);
                          }}
                        />
                        <span className="form-check-sign" />
                        CNIC
                      </Label>
                    </FormGroup>
                  )}
              </div>
              <div className="row ml-1 mt-2 mb-2">
                <div className="">
                  <button
                    className="btn btn-default "
                    onClick={() => {
                      if (headLabel === "list") {
                        history.replace("/admin/transaction-listing");
                      } else if (view_txn_from) {
                        history.replace(`/admin/${view_txn_from}`);
                      } else {
                        history.replace("/admin/transaction-status");
                      }
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className=" ">
                  <style>{pageStyle}</style>
                  <ReactToPrint
                    content={() => componentRef.current}
                    documentTitle={pdfFileName}
                    trigger={() => (
                      <button
                        className="btn btn-primary  ml-2"
                        onClick={handlePrint}
                        // onClick={() => {
                        //   printContent();
                        // }}
                      >
                        Print
                      </button>
                    )}
                  />
                  {/* <button
                className="btn btn-primary ml-2"
                onClick={() => {
                  printContent();
                }}
              >
                Print
              </button> */}
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <Header />
        </div>
      </Container>
    </>
  );
});

export default PrintCheque;
