import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BsTable } from 'react-icons/bs';
import { toast, ToastContainer } from 'react-toastify';
import { Col, Container, Row } from 'reactstrap';
import { autoSettlementNCCPL } from '../../stores/services/nccpl.service';
import { postNccpl } from '../../stores/services/transactions.service';

const EquityAutoSettlement = () => {
  let date = new Date();
  const email = sessionStorage.getItem('email') || '';
  const [nccplData, setNccplData] = useState<any>([]);
  const [errorData, setErrorData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [postDataLoading, setPostDataLoading] = useState(false);
  const [settlementDate, setSettlementDate] = useState(moment(date).format('YYYY-MM-DD'));

  const checkNccplData = async () => {
    try {
      setLoading(true);
      setFlag(false);
      let response: any = await autoSettlementNCCPL(moment(settlementDate).format('DD-MMM-YYYY').toUpperCase());
      if (response !== '' && response !== '[]' && response !== '{}' && response !== undefined) {
        if (response.data.responseCode === '208') {
          setLoading(false);
          toast.error(response.data.errorDescription);
          setNccplData([]);
        }
        let resData = response.data;
        setFlag(true);
        if (typeof (resData) === 'object') {

          let arr: any = []
          arr.push(resData);
          setNccplData(arr.map(item => {
            const settledate = item.Settlementdate;
            const [day, month, year] = settledate.split("/");
            const newSettleDate = `${year}-${month}-${day}`

            const tradedate = item.Tradedate;
            const [tday, tmonth, tyear] = tradedate.split("/");
            const newTradeDate = `${tyear}-${tmonth}-${tday}`

            return { ...item, Settlementdate: moment(newSettleDate).format('YYYY-MM-DD'), Tradedate: moment(newTradeDate).format('YYYY-MM-DD') }
          }))
        } else {
          let nccpData = resData.replaceAll('}', '},');

          const strToObj = (str) => {
            var obj;
            let finalArray: any = [];
            if (str && typeof str === 'string') {
              let array = str.split('},')
              let a = array.map((item) => {
                let objs = (item + '},').toString();
                var objStr: any = objs.match(/\{(.)+\}/g);
                if (objStr != null) {
                  finalArray.push(JSON.parse(objStr));
                  eval("obj =" + objStr);
                }
              })
            }
            return finalArray;
          }
          let totalData = await strToObj(nccpData);
          setNccplData(totalData.map(item => {
            const settledate = item.Settlementdate;
            const [day, month, year] = settledate.split("/");
            const newSettleDate = `${year}-${month}-${day}`

            const tradedate = item.Tradedate;
            const [tday, tmonth, tyear] = tradedate.split("/");
            const newTradeDate = `${tyear}-${tmonth}-${tday}`

            return { ...item, Settlementdate: moment(newSettleDate).format('YYYY-MM-DD'), Tradedate: moment(newTradeDate).format('YYYY-MM-DD') }
          }))
        }
        setLoading(false);
        toast.error(response.data.errorDescription);
      }

      setTimeout(() => {
        if (flag == false) {
          setLoading(false);
          setNccplData([]);
          toast.error('NCCPL server is busy now.');
        }
      }, 30000);
      // else{
      //   setNccplData([]);
      //   setLoading(false);
      //   toast.error('NCCPL server is busy now.');
      // }

    } catch (error: any) {
      if (error.response !== undefined) {
        setNccplData([]);
        toast.error(error.response.message.responseCode);
        setLoading(false);
      } else {
        setNccplData([]);
        toast.error(error);
        setLoading(false);
      }

    }
  };



  const postData = async () => {
    setPostDataLoading(true);
    try {
      let totalData = JSON.stringify(nccplData)
      const response = await postNccpl(email, totalData);
      if (response.data.status === 200) {
        setErrorData(response.data.Error);
        if (response.data.Error.length > 0) {
          setErrorBoolean(true);
        }else{
          toast.success(response.data.message);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
      setPostDataLoading(false);
    }
    setPostDataLoading(false);
  }

  const [errorBoolean, setErrorBoolean] = useState(false);

  const renderErrorPopup = () => {
    switch (errorBoolean) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal60w"
            size="lg"
            show={true}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setErrorBoolean(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Transactions Details </h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  {/* <div className="mx-auto">
                    <h1 className="pb-3">Kindly fill following fields data</h1>
                    Missing Fields
                  </div> */}
                  <div className={'d-flex border w-100'}>
                    <div className={'w-50'} style={{color:'#ffff'}}>Fund Code</div>
                    <div className={'w-50'} style={{color:'#ffff'}}>CMID</div>
                    <div className={'w-50'} style={{color:'#ffff'}}>Message</div>
                  </div>
                  {errorData.map((item, index) => {
                    return (
                      <div className={'d-flex w-100'}>
                        <div className={'w-50'} style={{color:'#ffff'}}>{item.fund_name}</div>
                        <div className={'w-50'} style={{color:'#ffff'}}>{item.cmid}</div>
                        <div className={'w-50'} style={{color:'#ffff'}}>{item.message}</div>
                      </div>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Equity Auto Settlement
                  </h4>
                </div>
                <Row >
                  <Col md='3'>
                    <div className="form-group">
                      <label>Settlement Date</label>
                      <input type='date' className='form-control'
                        value={settlementDate}
                        onChange={(e) => {
                          setSettlementDate(e.target.value);
                        }}
                      />
                    </div>

                  </Col>
                  <Col md='3'>
                    <div className='col-md-2'>
                      <label></label>
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          checkNccplData();
                        }}
                        disabled={Boolean(loading)}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Search</span>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>


                {nccplData.length > 0 ? (
                  <div className="table-responsive pl-2 mt-3">
                    <table
                      className="table"
                      data-pagination={true}
                      data-page-size={5}
                    >
                      <thead>
                        <tr>
                          <th>CMID</th>
                          <th>CName</th>
                          <th>Symbol</th>
                          <th>Symbol Name</th>
                          <th>Market Type</th>
                          <th>Trade Type</th>
                          <th>Volume</th>
                          <th>Average Price</th>
                          <th>Amount Pay Collect</th>
                          <th>Trade Date</th>
                          <th>Settlement Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          nccplData.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.CMID}</td>
                                <td>{item.CMName}</td>
                                <td>{item.Symbol}</td>
                                <td>{item.SymbolName}</td>
                                <td>{item.MarketType}</td>
                                <td>{item.TradeType}</td>
                                <td>{item.Volume}</td>
                                <td>{item.AveragePrice}</td>
                                <td>{item.AmountPayCollect}</td>
                                <td>{item.Tradedate}</td>
                                <td>{item.Settlementdate}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    <div className="col-md-12">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          postData();
                        }}
                        disabled={Boolean(postDataLoading)}
                      >
                        {postDataLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>{'Create'}</span>
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="table-empty">
                    <BsTable className="icon" />
                    <p className="text-center empty-text">No record found</p>
                  </div>
                )
                }

              </div>
            </Container>
          </Col>
        </Row>
        {renderErrorPopup()}
      </div>
    </>
  );

};

export default EquityAutoSettlement;