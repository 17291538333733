import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsTable } from "react-icons/bs";
import { isNumber, sortAMCS } from "../../utils/customFunction";
import {
  equityHoldersRecociliation,
  updateEquityReconCDC,
} from "../../stores/services/equity-holder.service";
import { generateOtp } from "../../stores/services/auth.service";
import { verifyOTP } from "../../stores/services/unit-holder.service";
import { getFunds } from "../../stores/services/funds.service";
import { element } from "prop-types";
// import { allCDCData, cdcCerificate } from '../../stores/services/cdc-recon.service';
import { allCDCData } from "../../stores/services/cdc-recon.service";

import xls from "xlsx";
import { getAmc } from "../../stores/services/amc.service";
const jks = require("parse-jks");

const CDCHoldingReconciliation = () => {
  const myRef1 = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem("email") || "";
  let date = new Date();
  const [cdcData, setCDCData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  let [reconcData, setReconcData] = useState<any>([]);
  let [allFunds, setAllFunds] = useState<any>([]);
  const [fund_code, setFundCode] = useState("");
  let [matchID, setMatchID] = useState<any>([]);
  let [actionLoading, setActionLoading] = useState(false);
  let [count, setCount] = useState(0);
  //let [counter, setCounter] = useState(0);
  let [cert, setCert] = useState<any>({});

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [optModalToggle, setOTPModalToggle] = useState(false);
  const [loadBtnEnable, setLoadBtnEnable] = useState(false);
  const [otp, setOTP] = useState("");

  // useEffect(() => {

  //   // getCDCsertificate();
  //   getAllFunds();
  // }, [counter])

  //const totalData = {
    //   data: [
    //     {
    //       "participantId": "34561",
    //       "positionOwned": "917",
    //       "securitySymbol": "ABOT",
    //       "securityName": "MUHAMMAD IRFAN MEMON SECURITY - ABC01",
    //       "blocked": "0",
    //       "available": "917",
    //       "pledged": "0",
    //       "pendingIn": "0",
    //       "pendingOut": "0",
    //       "accountNumber": "11",
    //       "freezed": "0"
    //     },
    //     {
    //       "participantId": "34561",
    //       "positionOwned": "1681",
    //       "securitySymbol": "AGIL",
    //       "securityName": "MUHAMMAD IRFAN MEMON SECURITIES PAKISTAN PRIVATE LIMITEDFULL",
    //       "blocked": "300",
    //       "available": "1381",
    //       "pledged": "0",
    //       "pendingIn": "0",
    //       "pendingOut": "0",
    //       "accountNumber": "11",
    //       "freezed": "0"
    //     },
    //     {
    //       "participantId": "34561",
    //       "positionOwned": "324",
    //       "securitySymbol": "AGP",
    //       "securityName": "MUHAMAMD IRFAN MEMON SECURITY FOR DEMERGER",
    //       "blocked": "0",
    //       "available": "324",
    //       "pledged": "0",
    //       "pendingIn": "0",
    //       "pendingOut": "0",
    //       "accountNumber": "11",
    //       "freezed": "0"
    //     },
    //     {
    //       "participantId": "34561",
    //       "positionOwned": "113",
    //       "securitySymbol": "WTL",
    //       "securityName": "MUHAMMAD IRFAN MEMON SECURITY",
    //       "blocked": "0",
    //       "available": "113",
    //       "pledged": "0",
    //       "pendingIn": "0",
    //       "pendingOut": "0",
    //       "accountNumber": "11",
    //       "freezed": "0"
    //     }, {
    //       "participantId": "34561",
    //       "positionOwned": "260000",
    //       "securitySymbol": "TELE",
    //       "securityName": "KOHINOOR ENERGY LIMITED",
    //       "blocked": "0",
    //       "available": "260000",
    //       "pledged": "0",
    //       "pendingIn": "0",
    //       "pendingOut": "0",
    //       "accountNumber": "29",
    //       "freezed": "0"
    //     }, {
    //       "participantId": "34561",
    //       "positionOwned": "540000",
    //       "securitySymbol": "AVN",
    //       "securityName": "LUCKY CEMENT LIMITED",
    //       "blocked": "0",
    //       "available": "540000",
    //       "pledged": "0",
    //       "pendingIn": "0",
    //       "pendingOut": "0",
    //       "accountNumber": "29",
    //       "freezed": "0"
    //     }, {
    //       "participantId": "34561",
    //       "positionOwned": "409590",
    //       "securitySymbol": "LUCK-LOR9977",
    //       "securityName": "LUCK-LOR",
    //       "blocked": "0",
    //       "available": "0",
    //       "pledged": "0",
    //       "pendingIn": "0",
    //       "pendingOut": "0",
    //       "accountNumber": "29",
    //       "freezed": "0"
    //     },
    //     {
    //       "participantId": "34561",
    //       "positionOwned": "100088",
    //       "securitySymbol": "MIM01878",
    //       "securityName": "MUHAMMAD IRFAN MEMON SECURITIES PAKISTAN PRIVATE LIMITEDFULL",
    //       "blocked": "0",
    //       "available": "0",
    //       "pledged": "0",
    //       "pendingIn": "0",
    //       "pendingOut": "0",
    //       "accountNumber": "29",
    //       "freezed": "0"
    //     }, {
    //       "participantId": "34561",
    //       "positionOwned": "12232",
    //       "securitySymbol": "ABOT",
    //       "securityName": "MUHAMAMD IRFAN MEMON SECURITY FOR DEMERGER",
    //       "blocked": "0",
    //       "available": "12232",
    //       "pledged": "0",
    //       "pendingIn": "0",
    //       "pendingOut": "0",
    //       "accountNumber": "516",
    //       "freezed": "0"
    //     }
    //   ],
    //   "balanceDate": "19/08/2024",
    //   "errorDescription": "",
    //   "response": "success",
    //   "responseCode": "00"
    // }
  
    // totalData.data.map((item, index) => {
    //   matchID.map((elem, index) => {
    //     if (item.participantId === elem.participantID && item.accountNumber === elem.cdcAccountNumber) {
    //       item['stock_market'] = elem.stock_market;
    //       item['fundCode'] = elem.fundCode;
    //       cdcData.push(item);
    //     }
    //   })
    // })
  
    // setCDCData(cdcData);
  
    // if (cdcData.length > 0) {
    //   await loadData();
    // }

  const getAllFunds = async () => {
    setLoadBtnEnable(true);
    cdcData.length = 0;
    setCDCData(cdcData);
    try {
      setAllFunds([]);
      const response = await getFunds(email);
      let array: any = [];
      response.data.data.filter((item) => {
        if (item.cdc_participant_id !== "" && item.cdc_account_number !== "") {
          array.push({
            fundCode: item.symbol_code,
            participantID: item.cdc_participant_id,
            cdcAccountNumber: item.cdc_account_number,
            stock_market: item.stock_market,
          });
        }
      });
      matchID = array;
      allFunds = response.data.data;
      setMatchID([...matchID]);
      setAllFunds([...allFunds]);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
    setLoadBtnEnable(false);
  };
  

  const loadCDC = async () => {
    setLoading(true);
    data.length = 0;
    setData(data);
    cdcData.length = 0;
    setCDCData(cdcData);
    await getAllFunds();
    try {
      const response: any = await allCDCData();
        if (response.data.responseCode === "00") {
          response.data.data.map((item, index) => {
            matchID.map((elem, index) => {
              if (
                item.participantId === elem.participantID &&
                item.accountNumber === elem.cdcAccountNumber
              ) {
                item["stock_market"] = elem.stock_market;
                item["fundCode"] = elem.fundCode;
                cdcData.push(item);
              }
            });
          });
          setCDCData(cdcData);

          if (cdcData.length > 0) {
            await loadData();
          }
        } else {
          toast.error(response.data.errorDescription);
          setData([]);
        }

        
     

      setLoading(false);
    } catch (error: any) {
    
      if (error.response !== undefined) {
        setCDCData([]);
        setData([]);
        toast.error(error.response.responseCode);
        setLoading(false);
      } else {
        setCDCData([]);
        setData([]);
        toast.error(error.response.responseCode);
        setLoading(false);
      }
    }
  };

  const loadData = () => {
    setLoading(true);
    let cdcArray = cdcData.map((cdcitem, index) => {
      let matchedObject = JSON.parse(cdcitem.stock_market).find((fundItem) => {
        return fundItem.code === cdcitem.securitySymbol;
      });

      if (matchedObject) {
        return {
          fCode: cdcitem.fundCode,
          symbol: cdcitem.securitySymbol,
          name: cdcitem.securityName,
          market:
            matchedObject.market === undefined || matchedObject.market === ""
              ? "Reg"
              : matchedObject.market,
          close_rate: isNumber(matchedObject.close_rate),
          macs_holding: matchedObject.unit,
          blocked_quantity: cdcitem.blocked,
          cdc_holding: cdcitem.available,
          difference: (
            +isNumber(cdcitem.available) - +isNumber(matchedObject.unit)
          ).toFixed(0),
        };
      } else {
          return {
            fCode: cdcitem.fundCode,
            symbol: cdcitem.securitySymbol,
            name: cdcitem.securityName,
            market: "Reg",
            macs_holding: "0",
            close_rate: "0",
            blocked_quantity: cdcitem.blocked,
            cdc_holding: cdcitem.available,
            difference: cdcitem.available,
          };
        
      }
    });

    let finalArray: any = [];
    let cdcArrayCodes = cdcArray.map((item) => item.symbol);
    let dataUnique = cdcData.filter(
      (v, i, a) =>
        a.findIndex((v2) =>
          ["participantId", "accountNumber"].every((k) => v2[k] === v[k])
        ) === i
    );

    dataUnique.filter((item, i) => {
      if (item.participantId && item.accountNumber !== "") {
        let fundUnmatched: any = [];
        let funmatched = JSON.parse(item.stock_market).filter(
          (elem) => !cdcArrayCodes.includes(elem.code)
        );
        if (funmatched && funmatched.length > 0) {
          fundUnmatched = funmatched.map((fitem, index) => {
            return {
              fCode: item.fundCode,
              symbol: fitem.code,
              name: "",
              market:
                fitem.market === undefined || fitem.market === ""
                  ? "Reg"
                  : fitem.market,
              close_rate: isNumber(fitem.close_rate),
              blocked_quantity: isNumber(fitem.blocked),
              macs_holding: isNumber(fitem.unit),
              cdc_holding: "0",
              difference: "-" + isNumber(fitem.unit),
            };
          });
          finalArray = [...fundUnmatched];
        }
      }
    });
    //cdcArray=cdcArray.filter(item=>item.macs_holding!="0" && item.cdc_holding!="0")
    for(let k=0;k<cdcArray.length;k++)
      {
       if(cdcArray[k].macs_holding==="0" && cdcArray[k].cdc_holding==="0")
       {
        if(k==0)
        {
          cdcArray.shift()
          k=k-1
        }else{
          cdcArray=cdcArray.slice(0,k).concat(cdcArray.slice(k+1, cdcArray.length))
         k=k-1
        }
         
       }
      }
    

    setData([...cdcArray, ...finalArray]);

    setLoading(false);
  };

  const [requiredOTP, setRequiredOTP] = useState("");
  const renderOTPModal = () => {
    switch (optModalToggle) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={false}
            onHide={() => {
              setOTP("");
              setOTPModalToggle(false);
            }}
            fade={false}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                disabled={Boolean(confirmLoading)}
                onClick={() => {
                  setOTP("");
                  setOTPModalToggle(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Verification</h6>
            </div>

            <div className="modal-body">
              <div
                className="card"
                style={{
                  backgroundColor: "#b8c8d0",
                  color: "black",
                }}
              >
                <div className="form-group m-3">
                  <label
                    style={{
                      color: "black",
                    }}
                  >
                    Enter OTP
                  </label>
                  <input
                    type="text"
                    placeholder="Enter OTP Code"
                    className={"form-control w-50 " + requiredOTP}
                    value={otp}
                    style={{
                      color: "black",
                    }}
                    onChange={(e) => {
                      setOTP(e.target.value);
                      setRequiredOTP("");
                    }}
                  />
                </div>

                <div className="form-group m-3">
                  <button
                    className="btn btn-primary"
                    disabled={Boolean(confirmLoading)}
                    onClick={(e) => {
                      if (otp == "") {
                        setRequiredOTP("required-border");
                      } else {
                        verify2FaBtn();
                      }
                    }}
                  >
                    {confirmLoading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Confirm</span>
                    )}
                  </button>

                  <button
                    className="btn btn-default"
                    disabled={Boolean(confirmLoading)}
                    onClick={(e) => {
                      setOTP("");
                      setOTPModalToggle(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const updateReconcileData = async () => {
    try {
      setActionLoading(true);
      const response = await generateOtp(email);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        setOTPModalToggle(!optModalToggle);
      } else {
        toast.error(response.data.message);
      }

      // setActionLoading(false);
    } catch (error: any) {
      setActionLoading(false);
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error);
      }
    }
    setActionLoading(false);
  };

  const verify2FaBtn = async () => {
    setConfirmLoading(true);
    try {
      const result = await verifyOTP(email, otp);
      if (result.data.status === 200) {
        saveReconcileData();
      } else if (result.data.status === 400) {
        toast.error(result.data.message);
        setConfirmLoading(false);
      }
    } catch (error: any) {
      setConfirmLoading(false);
      setActionLoading(false);
      toast.error(error.data.message);
    }
  };

  const saveReconcileData = async () => {
    setActionLoading(true);
    try {
      const response = await updateEquityReconCDC(email, reconcData);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        data.length = 0;
        setData(data);
        setOTP("");
        setReconcData([]);
        setAllFunds([]);
        //let c:any=0;
        // c = counter+1;
        // setCounter(c);

        setConfirmLoading(false);
        setOTPModalToggle(false);
        setActionLoading(false);
      } else {
        toast.error(response.data.message);
        setActionLoading(false);
      }
    } catch (error: any) {
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Request Failed!");
      }
      setConfirmLoading(false);
      setActionLoading(false);
    }
  };

  const addDataForBulk = (obj) => {
    count += 1;
    const check = reconcData.filter((item) => {
      return item.fCode + item.symbol === obj.fCode + obj.symbol;
    });

    if (check.length === 0) {
      let temp = {
        fCode: obj.fCode || "",
        symbol: obj.symbol || "",
        name: obj.name || "",
        market: obj.market,
        close_rate: obj.close_rate || "0",
        block_quantity: obj.blocked_quantity || "0",
        cdc_holding: obj.cdc_holding || "0",
        macs_holding: obj.macs_holding,
      };
      reconcData.push(temp);
      setReconcData(reconcData);
    }
    setCount(count);
  };

  const removeDataForBulk = (obj) => {
    count -= 1;
    const check = reconcData.filter((item) => {
      return item.fCode + item.symbol !== obj.fCode + obj.symbol;
    });
    reconcData = check;
    setReconcData(reconcData);
    setCount(count);
  };
  const checkBoolean = (obj) => {
    let select = false;
    reconcData.map((item, index) => {
      if (item.fCode + item.symbol === obj) {
        select = true;
      }
    });
    return select;
  };

  const addAllDataForBulk = (data) => {
    data.filter((txnObj) => {
      const check = reconcData.filter((item) => {
        return item.fCode + item.symbol === txnObj.fCode + txnObj.symbol;
      });
      if (check.length === 0) {
        let temp = {
          fCode: txnObj.fCode || "",
          symbol: txnObj.symbol || "",
          name: txnObj.name || "",
          market: txnObj.market,
          close_rate: txnObj.close_rate || "0",
          block_quantity: txnObj.blocked_quantity || "0",
          cdc_holding: txnObj.cdc_holding || "0",
          macs_holding: txnObj.macs_holding,
        };
        reconcData.push(temp);
        setReconcData(reconcData);
      }
    });
    setCount(reconcData.length);
  };

  const removeAllDataFromBulk = (data) => {
    count = count - 1;
    data.filter((dataObj) => {
      const check: any = reconcData.filter((item) => {
        return item.fCode + item.symbol !== dataObj.fCode + dataObj.symbol;
      });
      reconcData = check;
      setReconcData(check);
    });
    setCount(reconcData.length);
  };

  const checkAllBoolean = (data) => {
    let cnt = 0;
    data.filter((elem) => {
      reconcData.map((item, index) => {
        if (item.fCode + item.symbol === elem.fCode + elem.symbol) {
          checkBoolean(item.fCode + item.symbol);
          cnt += 1;
        }
      });
    });
    // return false;
    if (cnt === data.length) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      CDC Holding Reconciliation
                      {/* <a className='float-right' href="./../../Uploader-Sample/insideZip.txt" download>Download Sample</a> */}
                    </h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      {/* <Col md="2">
                        <div>Upload Securities</div>
                        <div
                          onClick={() => myRef1?.current?.click()} >
                          <div className="form-group">
                            <div className="form-control" data-tip="Upload File">

                              Upload File
                            </div>
                            <input
                              className="form-control mt-1"
                              type="file"
                              ref={myRef1}
                              style={{ display: 'none' }}
                              onChange={async(e) => {
                                await ProcessImage(e);
                              }}
                              onClick={(e) => {
                                e.currentTarget.value = '';
                              }}
                            />
                          </div>
                        </div>
                      </Col> */}

                      {loadBtnEnable === false ? (
                        <Col md="12">
                          <div className=" ">
                            <button
                              className="btn btn-primary mt-4 mr-0"
                              onClick={async () => {
                                // setCDCData([]);
                                await loadCDC();
                              }}
                              disabled={Boolean(loading)}
                            >
                              {loading === true ? (
                                <>
                                  <span
                                    className="spinner-border login-txt spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="login-txt">
                                    {" "}
                                    Processing...
                                  </span>
                                </>
                              ) : (
                                <span>Load CDC</span>
                              )}
                            </button>
                          </div>
                        </Col>
                      ) : (
                        <Col md="2">
                          <div className="">
                            <button
                              className="btn btn-primary mt-4 mr-0"
                              disabled={Boolean(loadBtnEnable)}
                            >
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              >
                                Load CDC
                              </span>
                            </button>
                          </div>
                        </Col>
                      )}
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    {data.length > 0 ? (
                      <>
                        <div className="title-row m-4 d-flex justify-content-center">
                          <h3>CDC Holding Reconciliation</h3>
                        </div>

                        <div
                          className="table-reponsive"
                          style={{
                            overflowX: "auto",
                            overflowY: "hidden",
                          }}
                        >
                          <table className="table">
                            <thead style={{ alignSelf: "center" }}>
                              <tr>
                                <th>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        checked={checkAllBoolean(data)}
                                        onChange={(e) => {
                                          if (e.target.checked === true) {
                                            addAllDataForBulk(data);
                                          } else {
                                            removeAllDataFromBulk(data);
                                          }
                                        }}
                                      />
                                      <span className="form-check-sign" />
                                    </Label>
                                  </FormGroup>
                                </th>
                                <th>Fund Code</th>
                                <th>Symbol</th>
                                <th>Name</th>
                                <th>Market</th>
                                <th className="text-right">Close Rate</th>
                                <th className="text-right">Blocked Quantity</th>
                                <th className="text-right">
                                  Holding as per MACS
                                </th>
                                <th className="text-right">CDC</th>
                                <th className="text-right">Difference</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((item: any, index: number) => {
                                return (
                                  <>
                                    {parseFloat(item.difference) > 0 && (
                                      <tr
                                        key={index}
                                        className="highlight-row-success"
                                      >
                                        <td>
                                          <FormGroup check>
                                            <Label check>
                                              <Input
                                                type="checkbox"
                                                checked={checkBoolean(
                                                  item.fCode + item.symbol
                                                )}
                                                onChange={(e) => {
                                                  if (
                                                    e.target.checked === true
                                                  ) {
                                                    addDataForBulk(item);
                                                  } else {
                                                    removeDataForBulk(item);
                                                  }
                                                }}
                                              />
                                              <span className="form-check-sign" />
                                            </Label>
                                          </FormGroup>
                                        </td>
                                        <td>{item.fCode}</td>
                                        <td>{item.symbol}</td>
                                        <td>{item.name}</td>
                                        <td>{item.market}</td>
                                        <td className="text-right">
                                          {item.close_rate}
                                        </td>
                                        <td className="text-right">
                                          {item.blocked_quantity}
                                        </td>
                                        <td className="text-right">
                                          {item.macs_holding}
                                        </td>
                                        <td className="text-right">
                                          {item.cdc_holding}
                                        </td>
                                        <td className="text-right">
                                          {item.difference}
                                        </td>
                                      </tr>
                                    )}
                                    {parseFloat(item.difference) < 0 && (
                                      <tr
                                        key={index}
                                        className="highlight-row-error"
                                      >
                                        <td>
                                          <FormGroup check>
                                            <Label check>
                                              <Input
                                                type="checkbox"
                                                checked={checkBoolean(
                                                  item.fCode + item.symbol
                                                )}
                                                onChange={(e) => {
                                                  if (
                                                    e.target.checked === true
                                                  ) {
                                                    addDataForBulk(item);
                                                  } else {
                                                    removeDataForBulk(item);
                                                  }
                                                }}
                                              />
                                              <span className="form-check-sign" />
                                            </Label>
                                          </FormGroup>
                                        </td>
                                        <td>{item.fCode}</td>
                                        <td>{item.symbol}</td>
                                        <td>{item.name}</td>
                                        <td>{item.market}</td>
                                        <td className="text-right">
                                          {item.close_rate}
                                        </td>
                                        <td className="text-right">
                                          {item.blocked_quantity}
                                        </td>
                                        <td className="text-right">
                                          {item.macs_holding}
                                        </td>
                                        <td className="text-right">
                                          {item.cdc_holding}
                                        </td>
                                        <td className="text-right">
                                          {item.difference}
                                        </td>
                                      </tr>
                                    )}
                                    {parseFloat(item.difference) == 0 && (
                                      <tr key={index}>
                                        <td>
                                          <FormGroup check>
                                            <Label check>
                                              <Input
                                                type="checkbox"
                                                checked={checkBoolean(
                                                  item.fCode + item.symbol
                                                )}
                                                onChange={(e) => {
                                                  if (
                                                    e.target.checked === true
                                                  ) {
                                                    addDataForBulk(item);
                                                  } else {
                                                    removeDataForBulk(item);
                                                  }
                                                }}
                                              />
                                              <span className="form-check-sign" />
                                            </Label>
                                          </FormGroup>
                                        </td>
                                        <td>{item.fCode}</td>
                                        <td>{item.symbol}</td>
                                        <td>{item.name}</td>
                                        <td>{item.market}</td>
                                        <td className="text-right">
                                          {item.close_rate}
                                        </td>
                                        <td className="text-right">
                                          {item.blocked_quantity}
                                        </td>
                                        <td className="text-right">
                                          {item.macs_holding}
                                        </td>
                                        <td className="text-right">
                                          {item.cdc_holding}
                                        </td>
                                        <td className="text-right">
                                          {item.difference == "-0"
                                            ? "0"
                                            : item.difference}
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>

                        {reconcData.length > 0 && (
                          <button
                            className="btn btn-primary float-right"
                            disabled={Boolean(actionLoading)}
                            onClick={(e) => {
                              updateReconcileData();
                            }}
                          >
                            {actionLoading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Action</span>
                            )}
                          </button>
                        )}
                      </>
                    ) : (
                      <div className="table-empty">
                        <BsTable className="icon" />
                        <p className="text-center empty-text">
                          No record found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {renderOTPModal()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CDCHoldingReconciliation;
