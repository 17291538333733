import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StaffListing = () => {
  const history = useHistory();
  
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Staff Management
                    <button className="btn btn-primary btn-sm float-right" onClick={() => {
                      history.replace('/admin/add-staff-management');
                    }}>
                      <i className="fa fa-plus mr-2"></i>  Add New
                    </button></h4>
                </div>
                {/* {!Loading ? ( */}
                  <div className="card-body">
                    <div className="input-holder">
                      {/* <Row>
                        <Col md="4">
                          <div className="form-group">
                            <label>Search</label>
                            <ReactTooltip textColor='white' backgroundColor='black' effect="float" />
                            <input type="search" id="myInput" className="form-control" data-tip="Search User" placeholder="Search Name" value={''} onChange={(e) => {
                            //   setSearchValue(e.target.value);
                            //   myFunction(e);
                            }} />
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>Select AMC</label>
                            <select
                              className="form-control"
                              onChange={(e) => {

                                // searchUsersByAmc(e.target.value);
                              }}
                            // value={searchUsersByAmc}
                            >
                              <option value="" defaultChecked hidden>
                                Select AMC
                              </option>
                              {renderAmcDropdown()} 
                            </select>
                          </div>
                        </Col>

                        <Col md="4">
                          <button className="btn btn-default mt-4"
                            onClick={() => {
                            //   getUserListUpdated();
                            }}
                          >
                            Clear
                          </button>
                        </Col>
                      </Row> */}


                    </div>
                    <div className="table-responsive">
                      <table className="table mt-3 ">
                        <thead>
                          <th>S No</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Contact No</th>
                          <th>Designation</th>
                          <th>Signature</th>
                        </thead>
                        {/* <tbody>{renderData()}</tbody> */}
                      </table>
                    </div>
                  </div>
                  {/* {
                // ) : (
                //   <div className="d-flex justify-content-center">
                //     <div className="fa fa-spinner fa-spin fa-3x mt-5 mb-5" role="status">
                //       <span className="sr-only">Loading...</span>
                //     </div>
                //   </div>
                // )}} */}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  )
};

export default StaffListing;