

import React, { useRef, useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import ViewTransaction from '../transactions/view-beta-txn';
import {
    getMailTemplates,
    inWords,
    getAllLetterTemplates,
    SendMailTo,
} from '../../stores/services/template.service';
import { getAllPendingTransactions, getLedger, searchTransactions } from '../../stores/services/transactions.service';
import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { useHistory } from 'react-router-dom';
import { BsTable } from 'react-icons/bs';
import { getAllBanks } from '../../stores/services/bank.service';
import { toast, ToastContainer } from 'react-toastify';
import { numberWithCommas } from '../../utils/customFunction';
import { IoMdClose } from 'react-icons/io';
import Select from 'react-select';

const BankSettlement = () => {
    const history = useHistory();
    let [transactions, setTransactions] = useState<any>([]);
    let [status, setStatus] = useState('COMPLETED');
    let [searchValue, setSearchValue] = useState('');
    let [activePage, setActivePage] = useState('');
    let [paginationArr, setPaginationArr] = useState(['']);
    let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
    const email = sessionStorage.getItem('email') || '';

    let [bulkProcessingData, setBulkProcessingData] = useState<any>([]);

    const [resLoading, setResLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [clearLoading, setClearLoading] = useState(false);


    const [Amc, setAmc] = useState('');
    const [allAmc, setAllAmc] = useState<any>([]);
    const [allFunds, setAllFunds] = useState<any>([]);
    const [fund, setFund] = useState('');

    let [changeCheck, setChangeCheck] = useState('');
    let [count, setCount] = useState(0);
    let [cnt, setCnt] = useState(0);

    const [togglePagination, setTogglePagination] = useState(false);

    const [bankData, setBankData] = useState<any>([]);
    const [bank_Code, setBankCode] = useState<any>('');

    const [color, setColor] = useState("#db6cfa");
    const styles = {
        color: color
    }


    React.useEffect(() => {
        const fetchBank = async () => {
            try {
                const bankResponse = await getAllBanks(email);
                setBankData(bankResponse.data.data);
            } catch (error) {
            }
        };
        fetchBank();
    }, []);

    React.useEffect(() => {
        async function getTransactions() {
            if (togglePagination != true) {
                setResLoading(true);
                await getAllPendingTransactions(
                    '',
                    sessionStorage.getItem('email') || '',
                    status === '-1' ? '' : status,
                    searchValue,
                    activePage
                    // sessionStorage.getItem('email') || '',	
                    // status,	
                    // searchValue,	
                    // '',	
                    // activePage
                )
                    .then((response) => {
                        if (response.data.status === 200) {
                            let pageArr = paginationArr;
                            let existingPageIndex = paginationArr.findIndex(
                                (x) => x === response.data.page_info.next_page_number
                            );
                            if (
                                existingPageIndex === -1 &&
                                response.data.data.length === 10
                                // &&	
                                // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
                            ) {
                                pageArr.push(response.data.page_info.next_page_number);
                            }
                            setPaginationArr(pageArr);
                            setCnt(0);
                            setTransactions(response.data.data);
                            setResLoading(false);
                        } else if (response.data.status === 404) {
                            toast.error(response.data.message);
                            setTransactions([]);
                            setResLoading(false);
                        }
                    })
                    .catch((err) => {
                        setResLoading(false);
                    });
                setResLoading(false);
            } else { // if search is enable
                setResLoading(true);
                if (searchValue !== '') {
                    searchValue = 'TXN_' + searchValue;
                }
                await searchTransactions(
                    sessionStorage.getItem('email') || '',
                    Amc,
                    fund,
                    status === '-1' ? '' : status,
                    searchValue,
                    activePage
                )
                    .then((response) => {

                        if (response.data.data.length > 0) {
                            let pageArr = paginationArr
                            let existingPageIndex = paginationArr.findIndex(
                                (x) => x === response.data.page_info.next_page_number
                            );
                            if (
                                existingPageIndex === -1 &&
                                response.data.data.length === 10
                                // &&	
                                // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
                            ) {
                                pageArr.push(response.data.page_info.next_page_number);
                            }
                            setPaginationArr(pageArr);
                            setCnt(0);
                            setTransactions(response.data.data);
                        } else {
                            setTransactions([]);
                        }
                        setResLoading(false);

                    })
                    .catch((err) => {
                        setResLoading(false);
                    });
                setResLoading(false);

            }
        }
        getTransactions();
    }, [activePage]);




    const CapTxnType = (type: any) => {
        let i, txType;
        for (i = 0; i < txnTypes.length; i++) {
            if (txnTypes[i].api_name === type) {
                txType = txnTypes[i].dis_name;
                break;
            }
        }
        return txType;
    };

    const bankName = (bankcode) => {
        const bankdata = bankData.filter((item: any, index: string) => {
            return (item.bank_code.replace('BANK_', '') === bankcode);
        });
        if (bankdata.length > 0) {
            return bankdata[0].bank_name;
        }
        else {
            return bankcode;
        }
    }

    useEffect(() => {
        const getAMC = async () => {
            try {
                const amcResponse = await getAmc(email, '', '', '');
                setAllAmc(amcResponse.data.data);
            } catch (err: any) {
                if (err.response !== undefined) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error("Request Failed!")
                }
            }
        }
        getAMC();
    }, [])

    const renderAmcDropdown = () => {
        return allAmc.map((item: any, index: string) => {
            return (
                <option key={index} value={item.amc_code.replace('AMC_', '')}>
                    {item.name}
                </option>
            );
        });
    }

    const getFunds = async (code: any) => {
        try {
            allFunds.length = 0;
            setAllFunds(allFunds);
            const response = await getFundByAmc(email, code);
            setAllFunds(response.data.data);
        } catch (error) {

        }
    }

    // Fund Type Drop Down
    const renderFundsDropDown = () => {
        return allFunds.map((item: any, index) => {
            return (
                <option key={index} value={item.symbol_code}>
                    {item.fund_name} - {item.symbol_code}
                </option>
            );
        })
    }



    const searchTransactionsByAMCAndFund = async (statusVar = '', searchVar = '', amcCodeVar = '', fundCodeVar = '') => {
        setTogglePagination(true);
        return new Promise(async (resolve, reject) => {
            if (searchVar !== '') {
                searchVar = 'TXN_' + searchVar;
            }

            setResLoading(true);
            setSearchLoading(true);
            await searchTransactions(
                sessionStorage.getItem('email') || '',
                amcCodeVar,
                fundCodeVar,
                statusVar === '-1' ? '' : statusVar,
                searchVar,
                activePage
            )
                .then((response) => {
                    if (response.data.data.length > 0) {
                        // let pageArr = paginationArr

                        paginationArr = paginationArr.filter(function (item) {
                            return item == ''
                        })
                        let pageArr = paginationArr;
                        let existingPageIndex = paginationArr.findIndex(
                            (x) => x === response.data.page_info.next_page_number
                        );
                        if (
                            existingPageIndex === -1 &&
                            response.data.data.length === 10
                            // &&	
                            // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
                        ) {
                            pageArr.push(response.data.page_info.next_page_number);
                        }
                        setPaginationArr(pageArr);
                        setTransactions(response.data.data);
                    } else {
                        setTransactions([]);
                    }
                    setResLoading(false);
                    setSearchLoading(false);

                    resolve('Success');

                })
                .catch((err) => {
                    setResLoading(false);
                    setSearchLoading(false);
                    reject('Error');
                });
            setResLoading(false);
            setSearchLoading(false);
        });
    };

    const clearFilters = async (statusVar = '', searchVar = '') => {
        return new Promise(async (resolve, reject) => {
            setResLoading(true);
            setClearLoading(true);
            await getAllPendingTransactions(
                '',
                sessionStorage.getItem('email') || '',
                statusVar === '-1' ? '' : statusVar,
                searchValue,
                activePage
                // sessionStorage.getItem('email') || '',	
                // statusVar === '-1' ? '' : statusVar,	
                // searchVar,	
                // '',	
                // activePage	
            )
                .then((response) => {
                    if (response.data.status === 200) {
                        let pageArr = paginationArr;
                        let existingPageIndex = paginationArr.findIndex(
                            (x) => x === response.data.page_info.next_page_number
                        );
                        if (response.data.data.length !== 10) {
                            if (
                                existingPageIndex === -1 &&
                                response.data.data.length === 10
                            ) {
                                pageArr.push(response.data.page_info.next_page_number);
                            }
                        }
                        setPaginationArr(pageArr);
                        setTransactions(response.data.data);
                        setResLoading(false);
                        setClearLoading(false);
                        resolve('Success');
                        setTogglePagination(false); // false search pagination.
                    } else if (response.data.status === 404) {
                        toast.error(response.data.message);
                        setTransactions([]);
                        setResLoading(false);
                        setClearLoading(false);
                        setTogglePagination(false); // false search pagination.
                    }
                })
                .catch((err) => {
                    setResLoading(false);
                    setClearLoading(false);
                    reject('Error');
                });
            setResLoading(false);
            setClearLoading(false);
            setTogglePagination(true);
        });
    };
    const [setLoading] = useState(false);
    const [view, setView] = useState(false);
    const [data, setData] = useState<any>([]);
    const titleCase = (value: string) => {
        let sentence = value.toLowerCase().split('_');
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence.join(' ');
    };

    const [trx_history, setTrxHistory] = useState([]);

    const renderPsxData = () => {
        return securites.map((items: any, index: number) => {
            return (
                <tr key={index}>
                    <td>{items.type}</td>
                    <td>{items.market}</td>
                    <td>{items.symbol}</td>
                    <td>{items.quantity}</td>
                    <td>{items.avg_rate}</td>
                    <td>{items.gross_amount}</td>
                    <td>{items.deductions}</td>
                    <td>{items.additions}</td>
                    <td>{items.net_amount}</td>
                </tr>
            );
        });
    };
    const [flagForSecutities, setFlagForSecutities] = useState(false);
    const [securites, setSecurites] = useState<any>([]);
    const renderSecuritiesModalPopup = () => {
        switch (flagForSecutities) {
            case true:
                return (
                    <Modal
                        className=""
                        dialogClassName="modal90w"
                        show={true}
                        size="lg"
                        backdrop={true}
                        onHide={() => { setFlagForSecutities(false) }}
                    >
                        <div className="modal-header ">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    setFlagForSecutities(false);
                                    // clearTaxobject();	
                                }}
                            >
                                <i className="tim-icons icon-simple-remove" />
                            </button>
                            <h6 className="title title-up">   View Security</h6>
                        </div>
                        <div className="modal-body">
                            <div className="">
                                {securites.length > 0 && (
                                    <div className="input-size-pos">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Market</th>
                                                    <th>Symbol</th>
                                                    <th>Quantity</th>
                                                    <th>Avg Rate</th>
                                                    <th>Gross Amount</th>
                                                    <th>Deduction</th>
                                                    <th>Addition</th>
                                                    <th>Net Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {renderPsxData()}
                                            </tbody>
                                        </table>
                                    </div>)}
                            </div>
                        </div>
                    </Modal>
                );
            default:
                return '';
        }
    };
    //render table data for tax	
    //view tax table	
    const [viewTaxModal, setTaxModal] = useState(false);
    const [totalTax, setTotalTax] = useState<any>([]);
    //render Tax table data	
    const renderTaxData = () => {
        return totalTax.map((items: any, index: number) => {
            return (
                <tr key={index}>
                    <td>{items.name}</td>
                    <td>{items.amount}</td>
                    <td>{items.calculatedAmount}</td>
                </tr>
            );
        });
    };
    const renderTaxPopup = () => {
        switch (viewTaxModal) {
            case true:
                return (
                    <Modal
                        className=""
                        dialogClassName="modal90w"
                        show={true}
                        size="lg"
                        backdrop={true}
                        onHide={() => { setTaxModal(false) }}
                    >

                        <div className="modal-header justify-content-center">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    setTaxModal(false);
                                    // clearTaxobject();	
                                }}
                            >
                                <i className="tim-icons icon-simple-remove" />
                            </button>
                            <h6 className="title title-up"> View Txn Charges  </h6>
                        </div>
                        <div className="modal-body">
                            <div className="">
                                {totalTax.length > 0 && (
                                    <div className="input-size-pos">
                                        <table className="table my-table">
                                            <thead>
                                                <tr>
                                                    <th>Tax Name</th>
                                                    <th>Tax Amount</th>
                                                    <th>Calculated Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {renderTaxData()}
                                            </tbody>
                                        </table>
                                    </div>)}
                            </div>
                        </div>
                    </Modal>
                );
            default:
                return '';
        }
    };
    const renderData = () => {
        return data.map((value: any, index: number) => {
            return (
                <>
                    {value[0] !== 'txn_history' &&
                        value[0] !== 'file' &&
                        value[1] !== '' &&
                        value[1] !== 0 ? (
                        <Col md="6" key={index}>
                            <div className="input-holder left">
                                <p className="label">{titleCase(value[0])}</p>

                                {value[0] === 'securities' ? (
                                    <div
                                        className="input-1 flex justify-content-center"
                                        style={{
                                            background: '#0e4646',
                                            color: 'white',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setFlagForSecutities(true);
                                            setSecurites(JSON.parse(value[1]));
                                        }}
                                    >
                                        View Securities
                                    </div>
                                ) : value[0] === 'txn_charges' ? (
                                    <div
                                        className="input-1 flex justify-content-center"
                                        style={{
                                            background: '#0e4646',
                                            color: 'white',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setTaxModal(true);
                                            setTotalTax(JSON.parse(value[1]));
                                        }}
                                    >
                                        View Txn Charges
                                    </div>
                                ) : (
                                    // <AiFillEye className="m-3"/>	
                                    <div className="input-1">
                                        <p
                                            style={{
                                                fontSize: '15px',
                                            }}
                                        >
                                            {value[1] &&
                                                value[1].toString().includes('Pakistan Standard')
                                                ? moment(value[1].toString()).format(
                                                    'DD-MM-YYYY h:mm a'
                                                )
                                                : value[1] && value[1].toString()}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </Col>
                    ) : (
                        ''
                    )}
                </>
            );
        });
    };

    // render Model for adding Fund	
    const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);


    const [note, setNote] = useState('');
    const [viewChecklist, setViewCheckList] = useState(false);
    const [trx_checklist, setTrxCheckList] = useState([]);
    const [templateDD, setTemplateDD] = useState();
    const viewCheckListPopup = () => {
        switch (viewChecklist) {
            case true:
                return (
                    <Modal
                        className=""
                        dialogClassName="modal90w"
                        show={true}
                        size="xl"
                    >

                        <div className="modal-header ">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    setViewCheckList(false);
                                }}
                            >
                                <i className="tim-icons icon-simple-remove" />
                            </button>
                            <h6 className="title title-up">Approval Check List </h6>
                        </div>
                        <div className="modal-body">
                            <div className="">
                                <div className={'checlist-scroll'}>
                                    {trx_checklist.map((val: any, ind: any) => {
                                        return (
                                            <Row key={ind} className="pt-3">

                                                <Col md="5">
                                                    <text className="text-primary">{val.serial_no}. </text> <text>{val.message}</text>
                                                </Col>
                                                <Col md="7">
                                                    <Row>
                                                        <Col md="3">

                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        checked={val.value == 'compliant'}
                                                                        type="checkbox"
                                                                        value="compliant"
                                                                        readOnly />
                                                                    <span className="form-check-sign" />
                                                                    Compliant
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input type="checkbox"
                                                                        checked={val.value == 'na'}
                                                                        value="na"
                                                                        readOnly />
                                                                    <span className="form-check-sign" />
                                                                    N /A
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="7">
                                                            <FormGroup>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Comments"
                                                                    value={val.comment}
                                                                    className="form-control"
                                                                    readOnly
                                                                />
                                                            </FormGroup>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </div>
                                {note !== '' && (
                                    <Row>
                                        <Col md="12">

                                            <div className="form-group">
                                                <label>Reason of Reject</label>
                                                <textarea
                                                    placeholder="Reason"
                                                    className={'form-control w-100'}
                                                    value={note}
                                                    readOnly
                                                ></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col>
                                        <div className=" ">
                                            <button
                                                className="btn btn-default"
                                                onClick={() => {
                                                    setViewCheckList(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Modal>
                );
            default:
                return '';
        }
    };

    let letterState = [];




    const [disableSendBtn, setdisableSendBtn] = useState(false);
    const [tempValue, setTempValue] = useState('');
    const [fund_account, setFund_account] = useState('');
    const [txnData, setTxnData] = useState('');


    const addTxnForBulk = (txnObj) => {
        count = count + 1;
        const check = bulkProcessingData.filter((item) => {
            return item.id.replace('TXN_', '') === txnObj.txn_id.replace('TXN_', '');
        });

        if (check.length === 0) {
            let temp = {
                id: txnObj.txn_id.replace('TXN_', '') || '',
                status: txnObj.txn_status || '',
                counter: txnObj.counter || '0',
                made_by: txnObj.made_by || ''
            };
            bulkProcessingData.push(temp);
            setBulkProcessingData(bulkProcessingData);
            setChangeCheck(`${txnObj.txn_id.replace('TXN_', '')}-${count}`);
        }
        setCount(count);
    };
    const addAllTxnForBulk = (txnObjs) => {
        count = count + 1;
        txnObjs.filter((txnObj) => {
            const check = bulkProcessingData.filter((item) => {
                return item.id.replace('TXN_', '') === txnObj.txn_id.replace('TXN_', '');
            });
            if (check.length === 0) {
                let temp = {
                    id: txnObj.txn_id.replace('TXN_', '') || '',
                    status: txnObj.txn_status || '',
                    counter: txnObj.counter || '0',
                    made_by: txnObj.made_by || ''
                };
                bulkProcessingData.push(temp);
                setBulkProcessingData(bulkProcessingData);
                setChangeCheck(`${txnObj.txn_id.replace('TXN_', '')}-${count}`);
            }
        })



        setCount(bulkProcessingData.length);
    };

    const removeTxnFromBulk = (txnObj) => {
        count = count - 1;
        const check = bulkProcessingData.filter((item) => {
            return item.id.replace('TXN_', '') !== txnObj.txn_id.replace('TXN_', '');
        });
        bulkProcessingData = check;
        setBulkProcessingData(bulkProcessingData);
        setCount(count);
        setChangeCheck(`${txnObj.txn_id.replace('TXN_', '')}-${count}`);
    };

    const removeAllTxnFromBulk = (txnObjs) => {
        count = count - 1;
        txnObjs.filter((txnObj) => {
            const check = bulkProcessingData.filter((item) => {
                return item.id.replace('TXN_', '') !== txnObj.txn_id.replace('TXN_', '');
            });
            bulkProcessingData = check;
            setBulkProcessingData(bulkProcessingData);
            setChangeCheck(`${txnObj.txn_id.replace('TXN_', '')}-${count}`);
        })
        setCount(bulkProcessingData.length);
    };

    const checkBoolean = (txnId) => {
        let tx = false;
        bulkProcessingData.map((item, index) => {
            if (item.id === txnId) {
                tx = true;
            }
        })
        return tx;
    }

    const checkAllBoolean = (txnObjs) => {
        cnt = 0;
        txnObjs.filter((elem) => {
            bulkProcessingData.map((item, index) => {
                if (item.id === elem.txn_id.replace('TXN_', '')) {
                    checkBoolean(item.id);
                    cnt += 1;
                }
            })
        })
        if (cnt === 10) {
            return true;
        } else {
            return false;
        }
    }

    //  Execute Payment Modal
    const [viewExecutePayment, setViewExecutePayment] = useState(false);
    const renderExecutePayment = () => {
        switch (viewExecutePayment) {
            case true:
                return (
                    <Modal
                        className=" "
                        dialogClassName="modal90w"
                        show={true}
                        size="lg"
                        backdrop={true}
                        onHide={() => {
                            setViewExecutePayment(false);
                        }}
                    >
                        <div className="modal-header ">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    setViewExecutePayment(false);
                                    // clearTaxobject();
                                }}
                            >
                                <i className="tim-icons icon-simple-remove" />
                            </button>
                            <h6 className="title title-up"> Execute Payment  </h6>
                        </div>

                        <div className="modal-body">
                            <div className="">
                                <Row>
                                    <Col md='5'>
                                        <div className="form-group w-100 d-flex">

                                            <div className="w-100 flex mr-2">
                                                <div>Bank</div>
                                                <Select
                                                    className="react-select info"
                                                    classNamePrefix="react-select"
                                                    label={'Select Bank'}
                                                    onChange={(e) => {
                                                        setBankCode(e.value)
                                                    }}
                                                    value={bankData.filter(
                                                        (option) =>
                                                            option.value === bank_Code
                                                    )}
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    name="color"
                                                    options={bankData}
                                                // menuPortalTarget={document.body}
                                                // menuShouldBlockScroll={true}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md='7'>
                                        <div className="form-group w-100 ">
                                            <br />
                                            <div>
                                                <button
                                                    className="btn btn-primary btn-sm mr-3 "
                                                    // disabled={false}
                                                    onClick={() => {
                                                        setViewExecutePayment(true)
                                                    }}
                                                >
                                                    Execute Payment
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Modal>
                );
            default:
                return '';
        }
    };

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <ToastContainer limit={1} />
                            {!view ? (
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Transactions</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="">
                                            {/* <div className="d-flex inline-box">	 */}
                                            <Row>
                                                <Col md="2">
                                                    <div className="form-group">
                                                        <ReactTooltip
                                                            textColor="white"
                                                            backgroundColor="black"
                                                            effect="float"
                                                        />
                                                        <label>Transaction ID</label>
                                                        <input
                                                            type="search"
                                                            id="myInput"
                                                            placeholder="Transaction Id"
                                                            data-tip="Search Transaction"
                                                            className="form-control"
                                                            value={searchValue}
                                                            onChange={(e) => {
                                                                setSearchValue(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col md="2">
                                                    <div className="form-group ml-2 mr-2">
                                                        <label >Select AMC</label>
                                                        <select
                                                            className={"form-control"}
                                                            value={Amc}
                                                            onChange={(e) => {
                                                                setAmc(e.target.value);
                                                                getFunds(e.target.value);
                                                            }}
                                                        >
                                                            <option value="" defaultChecked hidden>
                                                                Select AMC
                                                            </option>
                                                            {renderAmcDropdown()}
                                                        </select>
                                                    </div>
                                                </Col>

                                                <Col md="2">
                                                    <div className="form-group ml-2 mr-2">
                                                        <label >Select Fund</label>
                                                        <select
                                                            className={"form-control"}
                                                            value={fund}
                                                            onChange={(e) => {
                                                                setFund(e.target.value);
                                                            }}>
                                                            <option value="" defaultChecked hidden>
                                                                Select Fund
                                                            </option>
                                                            {renderFundsDropDown()}
                                                        </select>
                                                    </div>
                                                </Col>

                                                {/* <Col md="2">
                                                    <div className="form-group ml-2 mr-2">
                                                        <label >Select Status</label>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                setStatus(e.target.value);
                                                            }}
                                                            value={status}
                                                        >
                                                            <option value="-1">Select type</option>
                                                            <option value="COMPLIANCE">Compliance</option>
                                                            <option value="INREVIEW">In Review</option>
                                                            <option value="SIGNATORY-A">Signatory A</option>
                                                            <option value="SIGNATORY-B">Signatory B</option>
                                                            <option value="COMPLETED">Completed</option>
                                                            <option value="CONCERNED-OFFICER">CONCERNED OFFICER</option>
                                                            <option value="REJECTED-TRUSTEE">Rejected TRUSTEE</option>
                                                            <option value="REJECTED-AMC">Rejected AMC</option>
                                                        </select>
                                                    </div>

                                                </Col> */}


                                                <Col md="4">
                                                    <div className=" inline-box">
                                                        <button
                                                            className="btn btn-primary mt-4"
                                                            disabled={Boolean(searchLoading)}
                                                            onClick={() => {
                                                                if ((searchValue || status !== "-1" || Amc || fund) !== '') {
                                                                    setCnt(0);
                                                                    setCount(0);
                                                                    bulkProcessingData.length = 0;
                                                                    setBulkProcessingData(bulkProcessingData);
                                                                    searchTransactionsByAMCAndFund(status, searchValue, Amc, fund).then(() => {
                                                                        // setPaginationArr(['']);
                                                                        setActivePage('');

                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {searchLoading ? (
                                                                <>
                                                                    <span
                                                                        className="spinner-border login-txt spinner-border-sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="login-txt"> Searching...</span>
                                                                </>
                                                            ) : (
                                                                'Search'
                                                            )}
                                                        </button>
                                                        <button
                                                            className="btn btn-default mt-4"
                                                            disabled={Boolean(clearLoading)}
                                                            onClick={() => {
                                                                setCnt(0);
                                                                setCount(0);
                                                                bulkProcessingData.length = 0;
                                                                setSearchValue('');
                                                                clearFilters('', '').then(() => {
                                                                    setStatus('-1');
                                                                    setActivePage('');
                                                                    setAmc('');
                                                                    setFund('');
                                                                });
                                                            }}
                                                        >
                                                            {clearLoading ? (
                                                                <>
                                                                    <span
                                                                        className="spinner-border login-txt spinner-border-sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                    <span className="login-txt"> Clear</span>
                                                                </>
                                                            ) : (
                                                                'Clear'
                                                            )}
                                                        </button>
                                                    </div>
                                                </Col>
                                                {/* </div>	 */}

                                            </Row>
                                            {/* </div>	 */}
                                        </div>


                                        <div className="">
                                            {resLoading ? (
                                                <div className="d-flex justify-content-center my-5">
                                                    <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
                                                </div>
                                            ) : (
                                                transactions.length > 0 ? (
                                                    <div className='table-responsive'>
                                                        <table className="table mt-2 ">
                                                            <thead>
                                                                <tr>
                                                                    <th><FormGroup check>
                                                                        <Label check>
                                                                            <Input type="checkbox"
                                                                                checked={checkAllBoolean(transactions)}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked === true) {
                                                                                        addAllTxnForBulk(transactions);
                                                                                    } else {
                                                                                        removeAllTxnFromBulk(transactions);
                                                                                    }
                                                                                }} />
                                                                            <span className="form-check-sign" />
                                                                        </Label>
                                                                    </FormGroup></th>
                                                                    <th>Date</th>
                                                                    <th>Transaction</th>
                                                                    <th>Fund</th>
                                                                    <th className='text-right'>Amount</th>
                                                                    <th>Bank</th>
                                                                    <th>Counter Acc</th>
                                                                    <th>Instrument</th>
                                                                    <th>Created</th>
                                                                    <th>Status</th>
                                                                    <th>Act</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {transactions.map((transaction: any, index: number) => {
                                                                    if (transaction.bank === 'HBL') {
                                                                        return (
                                                                            // <tr style={customStyle(transaction.txn_status)}  key={index}>
                                                                            <tr key={index}>
                                                                                <td><FormGroup check>
                                                                                    <Label check>
                                                                                        <Input type="checkbox"
                                                                                            checked={checkBoolean(transaction.txn_id.replace('TXN_', ''))}
                                                                                            onChange={(e) => {
                                                                                                if (e.target.checked === true) {
                                                                                                    addTxnForBulk(transaction);
                                                                                                } else {
                                                                                                    removeTxnFromBulk(transaction);
                                                                                                }
                                                                                            }} />
                                                                                        <span className="form-check-sign" />
                                                                                    </Label>
                                                                                </FormGroup>
                                                                                </td>

                                                                                <td>{moment(transaction.instruction_date).format('DD-MM-YYYY')} <br />
                                                                                    <span className="sub-content">Exe: {moment(transaction.execution_date).format('DD-MM-YYYY')}</span>
                                                                                </td>

                                                                                <td>{transaction.txnr_type
                                                                                    ? CapTxnType(transaction.txnr_type)
                                                                                    : 'N/A'}<br /> <span className="sub-content">{transaction.txn_id.replace('TXN_', '')}</span></td>

                                                                                <td>{transaction.fund_code}	<br />
                                                                                    <span className="sub-content">{transaction.amc_code
                                                                                        ? transaction.amc_code
                                                                                        : 'N/A'}	</span>
                                                                                </td>

                                                                                <td className="text-right pr-3">{numberWithCommas(transaction.net_amount)} <br />
                                                                                    <span className="sub-content">{numberWithCommas(transaction.gross_amount)}</span>
                                                                                </td>

                                                                                <td>{transaction.bank}<br />
                                                                                    <span className="sub-content">{transaction.account_number}</span></td>

                                                                                <td>{transaction.counter_bank} <br />
                                                                                    <span className="sub-content">{transaction.counter_account_number}</span></td>

                                                                                <td>{transaction.mode_of_payment} <br />
                                                                                    <span className="sub-content">{transaction.instrument_type} {transaction.instrument_no !== '' ? transaction.instrument_no + ',' : ''} {transaction.instrument_date !== '' ? moment(transaction.instrument_date).format('DD-MM-YYYY') : ''}</span></td>

                                                                                <td>{moment(transaction.created_at).format('DD-MM-YYYY')} <br />
                                                                                    <span className="sub-content">{transaction.created_by.split('@')[0]}</span>
                                                                                </td>
                                                                                <td>{transaction.txn_status}</td>
                                                                                <td className=" ">
                                                                                    <div className="multi-icons pointer">
                                                                                        <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6" onClick={() => {
                                                                                            // setData(transaction);
                                                                                            let txnData = transaction;
                                                                                            let bankname = bankName(transaction.bank.toString())
                                                                                            let counterbankname = bankName(transaction.counter_bank.toString())
                                                                                            setData({
                                                                                                ...txnData,
                                                                                                bankCode: transaction.bank.toString(),
                                                                                                bank: bankname,
                                                                                                counter_bank: counterbankname
                                                                                            });
                                                                                            setView(true);
                                                                                            setTrxHistory(transaction.txn_history);
                                                                                        }}  >
                                                                                            <i className="fa fa-eye"> </i>
                                                                                        </button>
                                                                                    </div>


                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <div className="table-empty">
                                                        <BsTable className="icon" />
                                                        <p className="text-center empty-text">No record found</p>
                                                    </div>
                                                )
                                            )}

                                            {/* {togglePagination===true ? ( */}
                                            <div
                                                className="row pointer mr-3"
                                                style={{
                                                    float: 'right',
                                                }}

                                            >
                                                {/* PREVIOUS BUTTON */}
                                                {activePage !== paginationArr[0] ? (
                                                    <i
                                                        className="fa fa-arrow-left"
                                                        aria-hidden="true"
                                                        onClick={() => {
                                                            let pageArr = paginationArr;
                                                            let index = pageArr.findIndex((x) => x === activePage);
                                                            if (index !== -1) {
                                                                setActivePage(paginationArr[index - 1]);
                                                            }
                                                        }}
                                                    ></i>
                                                ) : (
                                                    ''
                                                )}
                                                {/* NEXT BUTTON */}
                                                {activePage !== paginationArr[paginationArr.length - 1] ? (
                                                    <i
                                                        className="fa ml-3 fa-arrow-right"
                                                        aria-hidden="true"
                                                        onClick={() => {
                                                            let pageArr = paginationArr;
                                                            let index = pageArr.findIndex((x) => x === activePage);
                                                            if (index !== -1) {
                                                                setActivePage(paginationArr[index + 1]);
                                                            }
                                                        }}
                                                    ></i>
                                                ) : (
                                                    ''
                                                )}
                                            </div>

                                            {count === 0 ? (
                                                ''
                                            ) : (
                                                <div className="mb-2"> <span className="text-primary">{count}</span> transactions selected. &nbsp; <a onClick={() => {
                                                    bulkProcessingData.length = 0;
                                                    setBulkProcessingData(bulkProcessingData);
                                                    setCount(0);
                                                    setChangeCheck(`${count}`);
                                                }}>Clear Selection</a> </div>
                                            )}

                                            {bulkProcessingData.length > 0 && (
                                                <Row>
                                                    <Col md="6">
                                                        <div className="d-flex">
                                                            <div className="">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    disabled={false}
                                                                    onClick={() => {
                                                                        setViewExecutePayment(true);
                                                                    }}
                                                                >
                                                                    Action
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}
                                            {/* ):(
                   ''
                  )} */}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="">

                                    <h4>
                                        <span>
                                            <i className="fa fa-angle-left mr-2 mt-1 pointer" onClick={() => {
                                                setView(false);
                                                setColor('#db6cfa');
                                            }}></i>
                                            View Transaction
                                        </span>
                                        <span>
                                            <Link to='/admin/bank-payment' >
                                                <IoMdClose size={35} className='ml-2 mt-1 pointer float-right'
                                                    style={styles}
                                                    onMouseEnter={() => setColor("white")}
                                                    onMouseLeave={() => setColor("#db6cfa")}
                                                    onClick={() => {
                                                        setView(false);
                                                        setColor('#db6cfa');
                                                    }}
                                                />
                                            </Link>
                                        </span>
                                    </h4>
                                    {/* <h4 className='float-right'>
                    
                  </h4> */}
                                    <ViewTransaction data={data} />

                                    <div className="line"></div>

                                    <Row>
                                        {trx_history.map((hist: any, index: number) => {
                                            return (
                                                <Col
                                                    md="3"
                                                    onClick={() => {
                                                        if (hist.action === 'REJECTED-TRUSTEE' || hist.action === 'REJECTED-AMC') {
                                                            setNote(hist.note);
                                                        } else {
                                                            setNote('');
                                                        }
                                                        if (hist.action === 'CREATED') {
                                                            return;
                                                        }
                                                        if (!hist.check_list) return;
                                                        setTrxCheckList(hist.check_list);
                                                        setViewCheckList(true);
                                                    }}
                                                >
                                                    <div className="card process-card pointer">
                                                        <div className="card-header">
                                                            <h4 className="card-title">
                                                                <span className="card-number">
                                                                    {index + 1}
                                                                </span>
                                                                <span className="mr-1">
                                                                </span> <i className="tim-icons icon-single-02 mr-1"></i> 	 {hist.updated_by_role}
                                                            </h4>
                                                        </div>
                                                        <div className="card-body text-center">

                                                            <p className="small mt-2">Status:</p>
                                                            <h3 className="accepted text-primary">  {hist.action}    </h3>
                                                            {hist.updated_at !== '' ? (
                                                                <>
                                                                    <p className="small mt-2"> by</p>
                                                                    <h4 className="comment text-primary">{(hist.updated_by)}</h4>
                                                                    <p className="small mt-2">at</p>
                                                                    <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MM-YYYY, h:mm a")}</h4>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {hist.note !== '' ? (
                                                                <>
                                                                    <p className="small mt-2">Comment</p>
                                                                    <small className="comment dot-text" title={hist.note}>{hist.note}</small>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {
                                                                hist.created_by !== '' ? (
                                                                    <>
                                                                        <p className="small mt-2"> by</p>
                                                                        <h4 className="text-primary">{hist.created_by}</h4>
                                                                        <p className="small mt-2"> at</p>
                                                                        <h4 className="comment text-primary">  {moment(hist.created_at).format("DD-MM-YYYY, h:mm a")}</h4>

                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </div>
                            )}
                            {renderSecuritiesModalPopup()}
                            {renderTaxPopup()}
                            {viewCheckListPopup()}
                            {renderExecutePayment()}
                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default BankSettlement;


