import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';
import { searchSecurityAPI } from './security.service';
/**
 *
 * @param email
 * @param serial_no
 * @param title
 *
 */
const addChecklist = async (
  email: string,
  serial_no: string,
  message: string,
  role: string
) => {
  const url = `${Config.baseUrl}/checklist/`;
  try{
  const result: any = await axios.post(
    url,
    { email, serial_no, message, role },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await addChecklist(email,serial_no,message,role)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};

/**
 * @param email
 */
const getChecklist = async (
  email: string,
  role_name: string = '',
  page_number: string = '',
  page_size: string = ''
) => {
  const url = `${Config.baseUrl}/checklist?email=${email}&role_name=${role_name}&page_number=${page_number}&page_size=${page_size}`;
 try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getChecklist(email,role_name,page_number,page_size)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 *
 * @param search_value
 */
const searchCheckListAPI = async (email: string, search_value: string) => {
  const url = `${Config.baseUrl}/checklist?email=${email}&search_value=${search_value}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await searchCheckListAPI(email,search_value)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const editChecklistData = async (
  email: string,
  serial_no: string,
  message: string,
  role: string,
  code: string
) => {
  const url = `${Config.baseUrl}/checklist/update`;
 try{
  const result: any = await axios.post(
    url,
    { email, serial_no, message, role, code },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
 }catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await editChecklistData(email,serial_no,message,role,code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 * @param email
 * @param code
 */
const deleteCheckList = async (email: string, code: string, status:string) => {
  const url = `${Config.baseUrl}/checklist/delete`;
 try{
  const result: any = await axios.post(
    url,
    { email, code, status },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
 }catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await deleteCheckList(email,code, status)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

export {
  addChecklist,
  getChecklist,
  searchCheckListAPI,
  deleteCheckList,
  editChecklistData,
};
