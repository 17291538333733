import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
const EditStaff = () => {
  const history = useHistory();
  //  Signature Code
  const myRef1 = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [file, setFile] = useState('');

  const [loading, setLoading] = useState(false)

  const [name, setName] = useState('')
  const [cnic, setCnic] = useState('')
  const [designation, setDesignation] = useState('')
  const [signature, setSignature] = useState<any | null>('');
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('')

  const [nameError, setNameError] = useState('')
  const [cnicError, setCnicError] = useState('')
  const [designationError, setDesignationError] = useState('')
  const [signatureError, setSignatureError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [contactError, setContactError] = useState('')

  useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('staffObj') || '');
    setName(obj.name);
    setCnic(obj.cnic);
    setDesignation(obj.designation);
    setSignature(obj.signature);;
    setEmail(obj.email);
    setContact(obj.contact);

  }, [])


  const validate = () => {
    let nameError = '';
    let cnicError = '';
    let designationError = '';
    let signatureError = '';
    let emailError = '';
    let contactError = '';
    name.trim() === '' ? (nameError = 'Required') : (nameError = '');
    cnic.trim() === '' ? (cnicError = 'Required') : (cnicError = '');
    designation.trim() === '' ? (designationError = 'Required') : (designationError = '');
    signature.trim() === '' || null ? (signatureError = 'Required') : (signatureError = '');
    email.trim() === '' ? (emailError = 'Required') : (emailError = '');
    contact.trim() === '' ? (contactError = 'Required') : (contactError = '');

    if (nameError || cnicError || designationError || signatureError || emailError || contactError) {
      setNameError(nameError);
      setCnicError(cnicError);
      setDesignationError(designationError);
      setSignatureError(signatureError);
      setEmailError(emailError);
      setContactError(contactError);
      return false;
    } else {
      return true;
    }
  };

  const UpdateStaff = () => {
    const isValid = validate()
  }

  const upload = (e) => {
    let file = e?.target.files[0];
    let filesize = parseInt(file.size);
    if (filesize <= 50000) {
      let type = file.name.substring(file.name.lastIndexOf('.') + 1);

      if (type === 'PNG' || type === 'png' || type === 'jpg' || type === 'jpeg') {
        //code here
        setFile(file);
        setFileName(file.name)
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
          setSignature(reader.result);
        };
        setSignatureError('')
      } else {

        toast.error('Invalid Format')
      }
    } else {
      toast.error("File size should be less than 50kb");
    }

  };


  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header title-row">
                  <h4 className="card-title"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    history.replace('/admin/staff-management');
                    sessionStorage.removeItem('staffObj');
                  }}></i>Edit - Staff</h4>

                  <Link to="/admin/staff-management" className="btn btn-primary btn-sm ml-auto" onClick={()=>sessionStorage.removeItem('staffObj')} replace>
                    <i className="fa fa-eye mr-2"></i>View All
                  </Link>
                </div>

                <div className="card-body">
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Name <span className="color-amber">*</span></label>
                        <input
                          type="text"
                          placeholder="Name"
                          className={`form-control  w-100 ${nameError ? 'required-border' : ''
                            }`}
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value)
                            setNameError('');
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label> CNIC   </label>
                        <div  >
                          <InputMask
                            className={`form-control  w-100 ${cnicError ? 'required-border' : ''
                              }`}
                            mask="99999-9999999-9"
                            value={cnic}
                            onChange={(e) => {
                              setCnic(e.target.value);
                              setCnicError('');
                            }}></InputMask>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Email  <span className="color-amber">*</span></label>
                        <input
                          type="text"
                          placeholder="Enter Email"
                          className={`form-control  w-100 ${emailError ? 'required-border' : ''
                            }`}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value)
                            setEmailError('')
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Contact No.  <span className="color-amber">*</span></label>
                        <input
                          type="text"
                          placeholder="Enter Contact No"
                          className={`form-control  w-100 ${contactError ? 'required-border' : ''
                            }`}
                          value={contact}
                          onChange={(e) => {
                            setContact(e.target.value)
                            setContactError('')
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>

                    <Col md="6">
                      <div className="form-group">
                        <label>Designation  <span className="color-amber">*</span></label>
                        <input
                          type="text"
                          placeholder="Enter Designation"
                          className={`form-control  w-100 ${designationError ? 'required-border' : ''
                            }`}
                          value={designation}
                          onChange={(e) => {
                            setDesignation(e.target.value)
                            setDesignationError('')
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Signature<small className="color-amber">(PNG or JPG of upto 50KB)</small></label>
                        <div
                          className="multi-input pointer"
                          onClick={() => myRef1?.current?.click()}
                        >
                          <div className="form-group">
                            <div className={`form-control  w-100 ${signatureError ? 'required-border' : ''
                              }`}>
                              {fileName === '' ? 'Upload File' : fileName}
                            </div>
                            <input
                              type="file"
                              ref={myRef1}
                              style={{ display: 'none' }}
                              multiple={false}
                              accept="image/png, image/jpeg"
                              onChange={(e) => {
                                setSignature('');
                                upload(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {signature ? (
                        <img src={signature} className="rounded" alt="spicemen" width="100px" height="100px" />
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary ml-3"
                    onClick={UpdateStaff}
                    disabled={Boolean(loading)}
                  >
                    {false ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Update</span>
                    )}
                  </button>
                </div>
              </div>
            </Container >

          </Col>
        </Row>
      </div>
    </>
  );
};
export default EditStaff;