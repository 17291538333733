import React, { useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { Container, Row, Col } from 'reactstrap';
import { addAmc } from '../../../stores/services/amc.service';
import ConfirmModal from '../../components/signatories-popup';
import { getOfficer } from '../../../stores/services/user.service';
import CreatorAddPopup from '../../components/modal-popup-txn';
import { BsX } from 'react-icons/bs';
import { Dropdown } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { getAllBanks } from '../../../stores/services/bank.service';
import { getBranchByBankName } from '../../../stores/services/branch.service';
import { Link } from 'react-router-dom';
import { add } from 'lodash';
import ToggleButton from 'react-toggle-button';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { accountTitle_AccountNumber_Regex } from './../../../utils/customFunction';
import {	
  Container,	
  Row,	
  Col,	
  FormGroup,	
  Label,	
  Input,	
  Card,	
  CardBody,	
  CardHeader,	
  CardTitle,	
} from "reactstrap";	
import { IoIosClose } from "react-icons/io";	
import { Modal } from "react-bootstrap";

const Setup = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const myRef1 = useRef<HTMLInputElement>(null);
  const myRef2 = useRef<HTMLInputElement>(null);
  const myRef3 = useRef<HTMLInputElement>(null);
  const emailRegex = new RegExp(
    '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
  );
  const amcCodeRegex = new RegExp('^[A-Z]{1,5}[A-Z]{1,5}$');
  const [amcName, setAmcName] = useState('');
  const [amcCode, setAmcCode] = useState('');
  const [focalPerEmail, setFocalPerEmail] = useState('');
  const [focalPerName, setFocalPerName] = useState('');
  const [accTitle, setAccTitle] = useState('');
  const [accNo, setAccNo] = useState('');
  const [bankname, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [tax_exemption, setTax_exemption] = useState(false);
  const [revenueAuthority, setRevenueAuthority] = useState('');
  // const [amcSig, setAmcSig] = useState<any[]>([]);	
  const [amcAud, setAmcAud] = useState('');
  const [taxAdv, setTaxAdv] = useState('');
  const [conOffericer, setConOfficer] = useState('');
  const [Suboffericer, setSubOffericer] = useState('');
  const [txCreater, setTxCreater] = useState({
    name: '',
    email: '',
    role: 'TXN_CREATOR',
  });
  const [txCreaterPop, setTxCreaterPop] = useState(false);
  let date = new Date();
  const [from, setFrom] = useState(moment(date).format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment(date).format('YYYY-MM-DD'));
  const [AmcBar, setAmcBar] = useState('');
  const [listSig, setListSig] = useState('');
  const [txExe, setTxExe] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');
  const email = sessionStorage.getItem('email') || '';
  //foor Errors	
  const [amcNameError, setAmcNameError] = useState('');
  const [amcCodeError, setAmcCodeError] = useState('');
  const [focalPerEmailError, setFocalPerEmailError] = useState('');
  const [focalPerNameError, setFocalPerNameError] = useState('');
  const [amcSigError, setAmcSigError] = useState('');
  const [revenueAuthorityError, setRevenueAuthorityError] = useState('');
  const [amcAudError, setAmcAudError] = useState('');
  const [taxAdvError, setTaxAdvError] = useState('');
  const [conOffericerError, setConOfficerError] = useState('');
  const [SuboffericerError, setSubOffericerError] = useState('');
  const [txCreaterError, setTxCreaterError] = useState('');
  const [fromError, setFromErrorError] = useState('');
  const [toError, setToErrorError] = useState('');
  const [AmcBarError, setAmcBarError] = useState('');
  const [listSigError, setListSigError] = useState('');
  const [txExeError, setTxExeError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [branchLoading, setBranchLoading] = useState<boolean>(false);
  const [accTitleError, setAccTitleError] = useState('');
  const [accNoError, setAccNoError] = useState('');
  const [bankNameError, setBankNameError] = useState('');
  const [branchNameError, setBranchNameError] = useState('');
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [billingName, setBillingName] = useState("");	
  const [billingEmail, setBillingEmail] = useState("");	
  const [billingDesignation, setBillingDesignation] = useState("");	
  const [billingContactNumber, setBillingContactNumber] = useState("");	
  const [Ntn, setNtn]=useState('')	

  const [billingNameErr, setBillingNameErr] = useState("");	
  const [billingEmailErr, setBillingEmailErr] = useState("");	
  const [billingDesignationErr, setBillingDesignationErr] = useState("");	
  const [billingContactNumberErr, setBillingContactNumberErr] = useState("");	
  const [NtnErr, setNtnErr]=useState('')	

  const [allBillingContact, setAllBillingContact] = useState<any>([]);	
  const [modalView, setModalView] = useState(false);	
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  // const amc_check = () => {	
  //   //   \w{1,3}\-\w{1,3}	
  //   const reg = new RegExp('\w{1,3}\-\w{1,3}');	
  //   if(!reg.test(amc_val)) {	

  //   }	
  // };	
  const validate = () => {
    let amcErr,
      codeErr,
      personErr,
      perName,
      phoneErr,
      mobileErr,
      sigErr,
      offErr = '';

    let subOffErr,
      txCreaterErr,
      txExeErr,
      accTitleErr,
      accNoErr,
      banknameErr,
      branchNameErr,
      revAuth = '';

      
    amcName.trim() === '' ? (amcErr = 'Required') : (amcErr = '');
    amcCode.trim() === ''
      ? (codeErr = 'Required')
      : amcCodeRegex.test(amcCode) !== true
        ? (codeErr = 'Invalid')
        : (codeErr = '');
    // focalPerEmail.trim() === '' ? personErr = "Required" : emailRegex.test(focalPerEmail) !== true ? personErr = 'Invalid Email' : personErr = "";	
    // focalPerName.trim() === '' ? perName = "Required" : perName = "";	
    emailArray.length === 0 ? (sigErr = 'Required') : (sigErr = '');
    accTitle.trim() === '' ? (accTitleErr = 'Required') : (accTitleErr = '');
    accNo.trim() === '' ? (accNoErr = 'Required') : (accNoErr = '');
    bankname.trim() === '' ? (banknameErr = 'Required') : (banknameErr = '');
    branchName.trim() === ''
      ? (branchNameErr = 'Required')
      : (branchNameErr = '');
    // phone.trim() === '' ? phoneErr = "Required" : phoneErr = "";	
    // mobile.trim() === '' ? mobileErr = "Required" : mobileErr = "";	
    conOffericer.trim() === '' ? (offErr = 'Required') : (offErr = '');
    Suboffericer.trim() === '' ? (subOffErr = 'Required') : (subOffErr = '');
    revenueAuthority.trim() === '' ? (revAuth = 'Required') : (revAuth = '');
    creatorEmailArray.length === 0 ? (txCreaterErr = 'Required') : (txCreaterErr = '');
    

    if (
      amcErr ||
      codeErr ||
      personErr ||
      perName ||
      sigErr ||
      phoneErr ||
      mobileErr ||
      offErr ||
      subOffErr ||
      txCreaterErr ||
      accTitleErr ||
      accNoErr ||
      banknameErr ||
      branchNameErr ||
      revAuth 
      
    ) {
      setAmcNameError(amcErr);
      setPhoneError(phoneErr);
      setMobileError(mobileErr);
      setBankNameError(banknameErr);
      setBranchNameError(branchNameErr);
      setAccNoError(accNoErr);
      setAccTitleError(accTitleErr);
      setAmcCodeError(codeErr);
      setFocalPerEmailError(personErr);
      setFocalPerNameError(perName);
      setAmcSigError(sigErr);
      setConOfficerError(offErr);
      setSubOffericerError(subOffErr);
      setTxCreaterError(txCreaterErr);
      setTxExeError(txExeErr);
      setRevenueAuthorityError(revAuth);
      
      return false;
    } else {
      return true;
    }
  };
  const createAmc = async () => {
    const isValid = validate();
    if (isValid) {
      if (conOffericer === Suboffericer) {
        toast.error(
          'Concerned officer and substitute concerned officer can not be same'
        );
        return;
      } else {
        setLoading(true);
        try {
          const amcSig = JSON.stringify(emailArray);
          //const billing_contact={"name":billingName, "email":billingEmail, "contact_number":billingContactNumber, "designation":billingDesignation}
          const response = await addAmc(
            email,
            amcCode,
            amcName,
            focalPerEmail,
            focalPerName,
            amcSig,
            conOffericer,
            Suboffericer,
            from,
            to,
            JSON.stringify(creatorEmailArray),
            AmcBar,
            accTitle,
            accNo,
            bankname,
            branchName,
            phone,
            mobile,
            JSON.stringify(tax_exemption),
            revenueAuthority,
            JSON.stringify(allBillingContact),
            Ntn
           
            
          );
           //JSON.stringify(billing_contact)
          if (response.data.status === 200) {
            setAmcName('');
            setPhone('');
            setMobile('');
            setAmcCode('');
            setBankName('');
            setBranchName('');
            setAccNo('');
            setTax_exemption(false);
            setRevenueAuthority('');
            setAccTitle('');
            setFocalPerEmail('');
            setFocalPerName('');
            // let emptyObj: any[] = [];	
            // setAmcSig(emptyObj)	
            setAmcAud('');
            setEmailArray([]);
            setTaxAdv('');
            setConOfficer('');
            setSubOffericer('');
            setBillingContactNumber('')
            setBillingDesignation('')
            setBillingEmail('')
            setBillingName('')
            setTxCreater({
              name: '',
              email: '',
              role: 'TXN_CREATOR',
            });
            setFrom('');
            setTo('');
            setFocalPerName('');
            setAmcBar('');
            setListSig('');
            setTxExe('');
            setFile1Name('');
            setFile2Name('');
            setFile3Name('');
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        } catch (err: any) {
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message)
          }
        }
        setLoading(false);
      }
    }
  };
  const getBranchByName = async (name: string) => {
    setBranchLoading(true);
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setBranchLoading(false);
  };
  const [officerData, setOfficerData] = useState<any>([]);
  React.useEffect(() => {
    const fetchAmc = async () => {
      officerData.length = 0;
      setOfficerData(officerData);
      //get all officers for dropdown	
      try {
        const amcResponse = await getOfficer(
          sessionStorage.getItem('email') || '',
          'CONCERNED_OFFICER'
        );
        setOfficerData(amcResponse.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      try {
        const response = await getAllBanks(email);
        setBankNameData(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    };

    fetchAmc();
  }, []);
  const [Loading, setLoading] = useState(false);
  //render dropdown for amc data	
  const renderofficersDropdown = () => {
    return officerData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.email}>
          {item.name}
        </option>
      );
    });
  };
  //render dropdown for bank name data	
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code.replace('BANK_', '')}>
          {item.bank_code.replace('BANK_', '')}-{item.bank_name}
        </option>
      );
    });
  };
  //render dropdown for branch name data	
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_code}-{item.branch_name}
        </option>
      );
    });
  };
  //render dropdown for amc data	
  const renderSubOfficerDropdown = () => {
    return officerData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.email}>
          {item.name}
        </option>
      );
    });
  };
  const [emailArray, setEmailArray] = useState([]);
  const renderComponents = () => {
    switch (showModal) {
      case true:
        return (
          <ConfirmModal
            setShowModal={setShowModal}
            // setAmcSig={setAmcSig}	
            // amcSig={amcSig}	
            setAmcSigError={setAmcSigError}
            emailArray={emailArray}
            setEmailArray={setEmailArray}
          />
        );
      default:
        return '';
    }
  };
  const removeEmail = (obj: object) => {
    const newArray = emailArray.filter((object) => object !== obj);
    setEmailArray(newArray);
  };
  const removeCreatorCEmail = (obj: object) => {
    const newArray = creatorEmailArray.filter((object) => object !== obj);
    setCreatorEmailArray(newArray);
  };
  const uploadAmcBarFile = (e: any) => {
    let file = e?.target.files[0];
    setFile1Name(file.name);
    setAmcBar(file);
    setAmcBarError('');
  };
  const uploadSignatoriesFile = (e: any) => {
    let file = e?.target.files[0];
    setFile2Name(file.name);
    setListSig(file);
    setListSigError('');
  };
  const uploadExemptionFile = (e: any) => {
    let file = e?.target.files[0];
    setFile3Name(file.name);
    setTxExe(file);
    setTxExeError('');
  };
  const [filename1, setFile1Name] = useState('');
  const [filename2, setFile2Name] = useState('');
  const [filename3, setFile3Name] = useState('');
  const [creatorEmailArray, setCreatorEmailArray] = useState([]);
  const renderCreaterPopup = () => {
    switch (txCreaterPop) {
      case true:
        return (
          <CreatorAddPopup
            setShowModal={setTxCreaterPop}
            setTxCreator={setTxCreater}
            emailArray={creatorEmailArray}
            setEmailArray={setCreatorEmailArray}
          />
        );
      default:
        return '';
    }
  };
  const borderRadiusStyle = { borderRadius: 2 };
  const changeCodeValue = (value: any) => {
    setAmcCode(value);
    if (value.trim() === '') {
      setAmcCodeError('Required');
    } else {
      amcCodeRegex.test(value) !== true
        ? setAmcCodeError('Invalid')
        : setAmcCodeError('');
    }
  };
  const renderBillingDataInTable = () => {
    return allBillingContact.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.name}</td>
          <td>{items.email}</td>
          <td className='text-right'>{items.designation}</td>
          <td className='text-right'>{items.contact_number}</td>
          <td className='text-right'>
            <IoIosClose
              style={{ fontSize: "25px", color: "red" }}
              onClick={() => {
                let array = [...allBillingContact];
                array.splice(index, 1);
                setAllBillingContact(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };
  const billingContactValidate=()=>{
    let billingNameError,
    billingEmailError,
    billingDesignationError,
    
    billingContactNumberError = "";

    billingContactNumber.trim() === ""
      ? (billingContactNumberError = "Required")
      : (billingContactNumberError = "");
    billingEmail.trim() === ""
      ? (billingEmailError = "Required")
      : (billingEmailError = "");
    billingDesignation.trim() === ""
      ? (billingDesignationError = "Required")
      : (billingDesignationError = "");
    billingName.trim() === ""
      ? (billingNameError = "Required")
      : (billingNameError = "");

     

    if(billingContactNumberError ||
      billingDesignationError ||
      billingEmailError ||
      billingNameError)
      {
        setBillingContactNumberErr(billingContactNumberError);
        setBillingDesignationErr(billingDesignationError);
        setBillingEmailErr(billingEmailError);
        setBillingNameErr(billingNameError);
        setDisableAddBtn(false);
         return false

      }else{
        return true

      }

  }
  const renderModalPopup = () => {
    switch (modalView) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setModalView(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setModalView(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Add Billing Contact </h6>
            </div>

            <div className="modal-body">
              <Row>
                <Col md="6">
                  <div className="form-group  ">
                    <label>Name</label>
                    <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          billingNameErr ? "required-border" : ""
                        }`}
                        value={billingName}
                        onChange={(e) => {
                          setBillingName(
                            e.target.value.replace(/[^a-z^A-Z ]/, "")
                          );
                          setBillingNameErr("");
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>Designation</label>
                    <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          billingDesignationErr ? "required-border" : ""
                        }`}
                        value={billingDesignation}
                        onChange={(e) => {
                          setBillingDesignation(e.target.value);
                          setBillingDesignationErr("");
                        }}
                      />
                    </div>
                  </div>
                  
                </Col>

                <Col md="6">
                  <div className="form-group  ">
                    <label>Email</label>
                    <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          billingEmailErr ? "required-border" : ""
                        }`}
                        value={billingEmail}
                        onChange={(e) => {
                          setBillingEmail(e.target.value);
                          setBillingEmailErr("");
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group  ">
                        <label>Contact Number</label>
                        <div className="">
                          <input
                            type="number"
                            className={`form-control  w-100 ${
                              billingContactNumberErr ? "required-border" : ""
                            }`}
                            value={billingContactNumber}
                            onChange={(e) => {
                              var num = e.target.value;
                              if (num.length > 12) {
                                num = num.substring(0, 12);
                              }
                              setBillingContactNumber(num);
                              setBillingContactNumberErr("");
                            }}
                          />
                        </div>
                      </div>
                </Col>
              </Row>

              <div className="row">
                <div className="col-sm-12">
                  <button
                    className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                    onClick={() => {
                      addBillingContact();
                    }}
                    disabled={Boolean(disableAddBtn)}
                  >
                    {disableAddBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            {}
          </Modal>
        );
      default:
        return "";
    }
  };

  const addBillingContact=()=>{
    setDisableAddBtn(true);
    let valid=billingContactValidate()
    if(valid)
    {

      const billing_contact = {
        name: billingName,
        email: billingEmail,
        contact_number: billingContactNumber,
        designation: billingDesignation,
      };
        let temp=allBillingContact
        temp.push(billing_contact)
      
        setAllBillingContact([...temp])

        
        setBillingName('')
        setBillingEmail('')
        setBillingDesignation('')
        setBillingContactNumber('')
        setModalView(false);
      

    }else {
      setDisableAddBtn(false);
    }
    



  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />

              <div className=" input-holder">
                <h4 className="card-title">
                  <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    history.replace('/admin/amc')
                  }}></i>Setup - AMC</h4>
                <Link to="/admin/amc" className="t-3 ml-auto btn btn-primary btn-sm ">
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        AMC Detail
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group  ">
                        <label>AMC Code <span className="color-amber">*</span></label>
                        <div>
                          <div className=" ">
                            <input
                              type="text"
                              className={`form-control  w-100 ${amcCodeError ? 'required-border' : ''
                                }`}
                              value={amcCode}
                              onChange={(e) => {
                                var cleaned = e.target.value.replace(/\d/, '');
                                if (cleaned.length == 5 && !cleaned.includes('-')) {
                                  // cleaned = cleaned + '-';
                                } else if (cleaned.length > 10) {
                                  cleaned = cleaned.substring(0, 10);
                                }
                                changeCodeValue(cleaned.toUpperCase());
                              }}
                            />
                          </div>
                          <div className="d-flex">
                            <p style={{ fontSize: '11px' }}>
                              {/* Code Pattern (AAAAA-AAAAA)	 */}
                            </p>
                            {amcCodeError ? (
                              <span
                                className="ml-auto"
                                style={{ fontSize: '11px' }}
                              >
                                {amcCodeError}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group  ">
                        <label>Name of AMC <span className="color-amber">*</span></label>
                        <div className=" ">
                          <input
                            type="text"
                            className={`form-control  w-100 ${amcNameError ? 'required-border' : ''
                              }`}
                            value={amcName}
                            onChange={(e) => {
                              setAmcName(e.target.value);
                              setAmcNameError('');
                            }}
                          />
                          {/* {amcNameError ? <p className="error-labels error-message">{amcNameError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Revenue Authority <span className="color-amber">*</span></label>
                        <div className=" ">
                          <select
                            className={`form-control  w-100 ${revenueAuthorityError ? 'required-border' : ''
                              }`}
                            value={revenueAuthority}
                            onChange={(e) => {
                              // eslint-disable-next-line 
                              setRevenueAuthority(e.target.value);
                              setRevenueAuthorityError('');
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Revenue Authority
                            </option>
                            <option value="FBR">FBR - Federal Board Of Revenue</option>
                            <option value="SRB">SRB - Sindh Revenue Board</option>
                            <option value="PRA">PRA - Punjab Revenue Authority</option>
                            <option value="BRA">BRA - Balochistan Revenue Authority</option>
                            <option value="KPRA">KPRA - Khyber Pakhtunkhwa Revenue Authority</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Tax Exemption</label>
                        <ToggleButton
                          value={tax_exemption}
                          thumbStyle={borderRadiusStyle}
                          trackStyle={borderRadiusStyle}
                          onToggle={() => {
                            if (tax_exemption) {
                              setTax_exemption(false);
                              setFrom('');
                              setTo('');
                              setFile1Name('');
                              setAmcBar('');
                            } else {
                              setTax_exemption(true);
                            }
                          }}
                        />
                      </div>
                      {tax_exemption && (
                        <div className="form-group  ">
                          <label>Exempted From <span className="color-amber">*</span></label>
                          <div className=" ">
                            <input
                              type="date"
                              className={`form-control  w-100 ${fromError ? 'required-border' : ''
                                }`}
                              value={from}
                              onChange={(e) => {
                                setFrom(e.target.value);
                                setFromErrorError('');
                              }}
                            />
                            {/* {fromError ? <p className="error-labels error-message">{fromError}</p> : ''} */}
                          </div>
                        </div>
                      )}
                      {tax_exemption && (
                        <div>
                          <div className="form-group  ">
                            <label>Exempted To</label>
                            <div className=" ">
                              <input
                                type="date"
                                className={`form-control  w-100 ${toError ? 'required-border' : ''
                                  }`}
                                value={to}
                                onChange={(e) => {
                                  setTo(e.target.value);
                                  setToErrorError('');
                                }}
                              />
                              {/* {toError ? <p className="error-labels error-message2">{toError}</p> : ''} */}
                            </div>
                          </div>
                          <div className="form-group  ">
                            <label>Evidence <span className="color-amber">*</span></label>
                            <div
                              className="multi-input"
                              onClick={() => myRef1?.current?.click()}
                            >
                              <div
                                className={`form-control  w-100 ${AmcBarError ? 'required-border' : ''
                                  }`}
                              >
                                <div className="input-2 ml-2">
                                  {filename1 ? filename1 : 'Select File'}
                                </div>
                                <input
                                  type="file"
                                  ref={myRef1}
                                  style={{ display: 'none' }}
                                  onChange={(e) => {
                                    uploadAmcBarFile(e);
                                  }}
                                />
                                {/* <input type="file" id="amc_br" ref="amc_br_Uploader" style={{display: "none"}}/> */}
                                {/* <div className="icon">	
                            <img src="assets/upload.svg" alt="" width="20" />	
                          </div> */}
                                {/* {AmcBarError ? <p className="error-labels error-message2">{AmcBarError}</p> : ''} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group  ">
                    <label>NTN </label>
                    <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 `}
                        value={Ntn}
                        onChange={(e) => {
                          setNtn(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        Bank Details
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group  ">
                        <label>Account Title <span className="color-amber">*</span></label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${accTitleError ? 'required-border' : ''
                              }`}
                            value={accTitle}
                            onChange={(e) => {
                              if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                // setAccTitle(e.target.value.replace(/[^a-z^A-Z ]/,''));	
                                setAccTitle(e.target.value);
                                setAccTitleError('');
                              }
                            }}
                          />
                          {/* {accTitleError ? <p className="error-labels error-message2">{accTitleError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Account No <span className="color-amber">*</span></label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${accNoError ? 'required-border' : ''
                              }`}
                            value={accNo}
                            onChange={(e) => {
                              if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                setAccNo(e.target.value);
                                setAccNoError('');
                              }
                            }}
                          />
                          {/* {accNoError ? <p className="error-labels error-message">{accNoError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Bank Name <span className="color-amber">*</span></label>
                        <div className=" ">
                          <select
                            className={`form-control  w-100 ${bankNameError ? 'required-border' : ''
                              }`}
                            value={bankname}
                            onChange={(e) => {
                              setBankNameError('');
                              setBankName(e.target.value);
                              getBranchByName(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Bank
                            </option>
                            {renderBankNameDropdown()}
                          </select>
                          {/* {bankNameError ? <p className="error-labels error-message2">{bankNameError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Branch Name <span className="color-amber">*</span></label>
                        <input type="text" className={`form-control  w-100 ${branchNameError ? 'required-border' : ''
                          }`}
                          value={branchName}
                          onChange={(e) => {
                            setBranchNameError('');
                            setBranchName(e.target.value);
                          }} />
                        {/* <ReactTooltip	
                      textColor="white"	
                      backgroundColor="black"	
                      multiline={true}	
                      effect="float"	
                    />	
                    <div	
                      className=" "	
                      id="x"	
                      data-tip={`First Select Bank`}	
                    >	 */}
                        {/* {branchLoading ? (	 
                           <div className="form-group">
                          <div className="ml-2 form-control">Branch Loading <span className="color-amber">*</span>
                                  <i className="fa fa-spinner fa-spin fa-1x ml-3" ></i>  
                           </div> 
                           </div>	 
                      ) : (	
                        <select	
                          className={`form-control  w-100 ${	
                            branchNameError ? 'required-border' : ''	
                          }`}	
                          value={branchName}	
                          onChange={(e) => {	
                            setBranchNameError('');	
                            setBranchName(e.target.value);	
                          }}	
                        >	
                          <option value="" defaultChecked hidden>	
                            {branchCheckFlag? 'No Branch Found' : 'Select Branch Name'}	
                          </option>	
                          {renderBranchsDropdown()}	
                        </select>	
                      )}	 */}
                        {/* {branchNameError ? <p className="error-labels error-message">{branchNameError}</p> : ''} */}
                        {/* </div>	 */}
                      </div>

                    </div>
                  </div>
                  
                </Col>

                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        Authorized AMC Users
                      </h4>
                    </div>
                    <div className="card-body">

                      <div className="form-group ">
                        <label>Transaction Creator Field <span className="color-amber">*</span></label>
                        <div className="multi-input">
                          <div
                            className={`d-flex  w-100 ${amcSigError ? 'required-border' : ''
                              }`}
                          >
                            <Dropdown>
                              <Dropdown.Toggle
                                className="input-sign"
                                variant="success"
                                id="dropdown-basic"
                              >
                                {creatorEmailArray.length} Added
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {creatorEmailArray.map((obj: any, i: any) => (
                                  <Dropdown.Item className="drop-item " key={i}>
                                    <div className="d-flex justify-content-between">
                                      <div className="mr-auto">{obj.email}</div>
                                      <div className="col-1">
                                        <BsX onClick={() => removeCreatorCEmail(obj)} />
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            <div
                              className="icon icon-pos-xpt"
                              onClick={() => {
                                setTxCreaterPop(true);
                              }}
                            >
                              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm">
                                <i className="fa fa-user-plus fa-3x">  </i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>AMC Signatories <span className="color-amber">*</span></label>
                        <div className="multi-input">
                          <div
                            className={`d-flex  w-100 ${amcSigError ? 'required-border' : ''
                              }`}
                          >
                            <Dropdown>
                              <Dropdown.Toggle
                                className="input-sign"
                                variant="success"
                                id="dropdown-basic"
                              >
                                {emailArray.length} Added
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {emailArray.map((obj: any, i: any) => (
                                  <Dropdown.Item className="drop-item " key={i}>
                                    <div className="d-flex justify-content-between" style={{ fontSize: '15px' }}>
                                      <div
                                        className="mr-auto"
                                        style={{ fontSize: '15px' }}
                                      >
                                        <span style={{ color: 'green' }}>
                                          Sig{obj.role.slice(-1)}
                                        </span>{' '}
                                        {obj.email}
                                      </div>
                                      <div
                                        className="col-1"
                                        style={{ fontSize: '15px' }}
                                      >
                                        <BsX onClick={() => removeEmail(obj)} />
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            <div
                              className="icon icon-pos-xpt"
                              onClick={() => {
                                setShowModal(true);
                              }}
                            >
                              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm">
                                <i className="fa fa-user-plus fa-3x">  </i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="form-group  ">
                        <label>Concerned Officer {' '} <span className="color-amber">*</span></label>
                        <div className="">
                          <select
                            className={`form-control  w-100 ${conOffericerError ? 'required-border' : ''
                              }`}
                            value={conOffericer}
                            onChange={(e) => {
                              setConOfficer(e.target.value);
                              setConOfficerError('');
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Officer
                            </option>
                            {renderofficersDropdown()}
                          </select>
                          {/* {conOffericerError ? <p className="error-labels error-message2">{conOffericerError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Substitute Concerned Officer <span className="color-amber">*</span></label>
                        <div className=" ">
                          <select
                            className={`form-control  w-100 ${SuboffericerError ? 'required-border' : ''
                              }`}
                            value={Suboffericer}
                            onChange={(e) => {
                              setSubOffericer(e.target.value);
                              setSubOffericerError('');
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Substitute
                            </option>
                            {renderSubOfficerDropdown()}
                          </select>
                          {/* {SuboffericerError ? <p className="error-labels error-message">{SuboffericerError}</p> : ''} */}
                        </div>
                      </div>

                    </div>
                  </div>
                </Col>

              </Row>

              <Row>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        Focal Person Detail
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group  ">
                        <label>AMC Focal Person Name</label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${focalPerNameError ? 'required-border' : ''
                              }`}
                            value={focalPerName}
                            onChange={(e) => {
                              setFocalPerName(e.target.value.replace(/[^a-z^A-Z ]/, ""));
                              setFocalPerNameError('');
                            }}
                          />
                          {/* {focalPerNameError ? <p className="error-labels error-message2">{focalPerNameError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>AMC Focal Person Email</label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${focalPerEmailError ? 'required-border' : ''
                              }`}
                            value={focalPerEmail}
                            onChange={(e) => {
                              setFocalPerEmail(e.target.value);
                              setFocalPerEmailError('');
                            }}
                          />
                          {/* {focalPerEmailError ? <p className="error-labels error-message">{focalPerEmailError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Mobile</label>
                        <div className="">
                          <input
                            type="number"
                            className={`form-control  w-100 ${mobileError ? 'required-border' : ''
                              }`}
                            value={mobile}
                            onChange={(e) => {
                              var mob = e.target.value;
                              if (mob.length > 12) {
                                mob = mob.substring(0, 12);
                              }
                              setMobile(mob);
                              setMobileError('');
                            }}
                          />
                          {/* {mobileError ? <p className="error-labels error-message2">{mobileError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Phone</label>
                        <div className="">
                          <input
                            type="number"
                            className={`form-control  w-100 ${phoneError ? 'required-border' : ''
                              }`}
                            value={phone}
                            onChange={(e) => {
                              var num = e.target.value;
                              if (num.length > 12) {
                                num = num.substring(0, 12);
                              }
                              setPhone(num);
                              setPhoneError('');
                            }}
                          />
                          {/* {phoneError ? <p className="error-labels error-message">{phoneError}</p> : ''} */}
                        </div>
                      </div>

                    </div>
              </div>
               </Col>

               

               
              </Row>
              {/* <Row>
                <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">
                          Billing Contact
                        </CardTitle>
                      </CardHeader>
                      <CardBody>

                        <div className="input-size-pos">

                          <Row>
                            <Col>
                              <label>Add Billing Contact</label>
                              <div>
                                <ReactTooltip
                                  textColor="white"
                                  backgroundColor="black"
                                  effect="float"
                                />
                                <button
                                  data-tip="Add Billing Contact"
                                  className="btn-round btn-icon btn btn-primary"
                                  onClick={() => {
                                    setModalView(!modalView);
                                    setBillingName('')
                                    setBillingEmail('')
                                    setBillingDesignation('')
                                    setBillingContactNumber('')
                                    setBillingContactNumberErr('')
                                    setBillingDesignation('')
                                    setBillingEmailErr('')
                                    setBillingContactNumber('')
                                    
                                    setDisableAddBtn(false);
                                  
                                  }}
                                > <i className="fa fa-plus"></i> </button>
                              </div>
                            </Col>
                          

                          </Row>
                        </div>

                        <div className="table-responsive">
                          {allBillingContact.length > 0 && (
                            <div className="input-size-pos mb-3 mt-2">
                              <table className="table   ">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    
                                    <th className='text-right'>Designation</th>
                                    <th className='text-right'>Contact Number</th>
                                    <th className='text-right'>Action</th>
                                  
                                  </tr>
                                </thead>
                                <tbody>{renderBillingDataInTable()}</tbody>
                              </table>
                            </div>
                          )}

                        </div>


                      </CardBody>
                    </Card>
                  </Col>
              </Row> */}




              <div className=" ">
                <button
                  className="btn btn-primary mb-3"
                  onClick={createAmc}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>Create</span>
                  )}
                </button>
              </div>

              {renderComponents()}
              {renderCreaterPopup()}
              {renderModalPopup()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Setup;