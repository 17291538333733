import React, { useEffect } from "react";
import { useState } from "react";
import { EditFund, getFundType } from "../../../stores/services/funds.service";
import { getAmc } from "../../../stores/services/amc.service";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { BsPlusCircleFill } from "react-icons/bs";
import {
  getAllSecurities,
  getAllPsx,
  getAllActiveFundSecurity,
} from "../../../stores/services/security.service";
import { getAllBanks } from "../../../stores/services/bank.service";
import { getBranchByBankName } from "../../../stores/services/branch.service";
import { isNumber, numberWithCommas } from "../../../utils/customFunction";
import NumberFormat from "react-number-format";

const EditFunds = () => {
  const history = useHistory();
  //hooks for getting all inputs from user
  const [fundName, setFundName] = useState("");
  const [code, setCode] = useState("");
  const [amcName, setAmcName] = useState("");
  const [fundType, setFundType] = useState("");
  const [incorporationDate, setIncorporationDate] = useState("");
  const [revocationDate, setRevocationDate] = useState("");
  const [psxListing, setPsxListing] = useState("false");
  const [nav, setNav] = useState("");
  const [faceValue, setFaceValue] = useState("");
  const [navError, setNavError] = useState("");
  const [faceValError, setFaceValError] = useState("");
  // const [clearMemberError, setClearMemberError] = useState('');
  const [bats_id, setBatsID] = useState("");

  const [fundSecurities, setFundSecurities] = useState<any>([]);

  const email = sessionStorage.getItem("email") || "";
  const role = sessionStorage.getItem("role") || "";

  const [bankNameData, setBankNameData] = useState<any>([]);
  const [branchData, setBranchData] = useState<any>([]);

  const [cdc_participant_id, setCDCParticipantID] = useState("");
  const [cdc_account_number, setCDCAccountNumber] = useState("");
  const [clearMember_id, setCleariMemberID] = useState("");
  const [batsError, setBatsError] = useState("");

  const [allFundSecurities, setAllFundSecurities] = useState<any>([]); // Fund as securities Hook

  const [fieldReadOnly, setFieldReadOnly] = useState(false);
  const [feeSlabFrom, setFeeSlabFrom] = useState("");
  const [feeSlabTo, setFeeSlabTo] = useState("");
  const [feeRate, setFeeRate] = useState("");
  const [feeFixAmount, setFeeFixAmount] = useState("");
  const [feeSlabModal, setFeeSlabModal] = useState(false);
  const [feeSlabFromErr, setFeeSlabFromErr] = useState("");
  const [feeSlabToErr, setFeeSlabToErr] = useState("");
  const [feeRateErr, setFeeRateErr] = useState("");
  const [feeFixAmountErr, setFeeFixAmountErr] = useState("");
  const [allFeeSlab, setAllFeeSlab] = useState<any>([]);

  useEffect(() => {
    const getFundSecurities = async () => {
      try {
        const response = await getAllActiveFundSecurity(email);
        let sortedData = response.data.data.sort(function (a, b) {
          let x = a.name.toLowerCase();
          let y = b.name.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        setAllFundSecurities(sortedData);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
    };
    getFundSecurities();
  }, []);

  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("fundobj") || "");
    let feeDetails = obj.fee_slab ? JSON.parse(obj.fee_slab) : null;
    if (feeDetails) {
      setAllFeeSlab(feeDetails);
    }
    setFundName(obj.fund_name);
    setCode(obj.symbol_code.replace("FUND_", ""));
    setAmcName(obj.amc_code);
    setFundType(obj.nature);
    setIncorporationDate(obj.date_of_incorporation);
    setRevocationDate(obj.date_of_revocation);
    setPsxListing(obj.psx_listing);
    setNav(obj.nav);
    setFaceValue(obj.face_value);
    if (
      obj.money_market &&
      obj.money_market !== "" &&
      obj.stock_market !== undefined &&
      obj.money_market !== "[]"
    ) {
      setMoneyMarket(JSON.parse(obj.money_market));
    }
    if (
      obj.stock_market &&
      obj.stock_market !== "" &&
      obj.stock_market !== undefined &&
      obj.stock_market !== "[]"
    ) {
      setStockMarket(JSON.parse(obj.stock_market));
    }
    if (
      obj.fund_market &&
      obj.fund_market !== "" &&
      obj.fund_market !== undefined &&
      obj.fund_market !== "[]"
    ) {
      setFundSecurities(JSON.parse(obj.fund_market));
    }
    if (
      obj.term_deposit &&
      obj.term_deposit !== "" &&
      obj.term_deposit !== "[]"
    ) {
      setTermDeposit(JSON.parse(obj.term_deposit));
    }
    setCDCParticipantID(obj.cdc_participant_id);
    setCDCAccountNumber(obj.cdc_account_number);
    setCleariMemberID(obj.cmid);
    setBatsID(obj.bats_id);
  }, []);
  //error getting hooks
  const [namelError, setNameError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [amcNameError, setAmcError] = useState("");
  const [fundTypeError, setFundTypeError] = useState("");
  const [incorporationDateError, setIncorporationDateError] = useState("");
  const [psxListingError, setPsxListingError] = useState("");
  const [Loading, setLoading] = useState(false);
  //for dropdown data
  const [amcdata, setAmcdata] = useState<any>([]);
  const [fundTpedata, setFundTpedata] = useState<any>([]);
  const [isCheckBoxSelected, setCheckBoxSelected] = useState(true);
  const [securityTypeData, setSecurityTypeData] = useState<any>([]);
  const [psxList, setPsxList] = useState<any>([]); // PSX securities Hook
  const validate = () => {
    let nameError = "";
    let codeError = "";
    let amcErr = "";
    let typeErr = "";
    let inDateerr = "";
    // let clearMemberErr = '';
    // let batsErr = '';
    let psxErr,
      faceValErr,
      navErr = "";
    fundName.trim() === "" ? (nameError = "Required") : (nameError = "");
    code.trim() === "" ? (codeError = "Required") : (codeError = "");
    amcName.trim() === "" ? (amcErr = "Required") : (amcErr = "");
    fundType.trim() === "" ? (typeErr = "Required") : (typeErr = "");
    // clearMember_id.trim() === '' ? (clearMemberErr = 'Required') : (clearMemberErr = '');
    // bats_id.trim() === '' ? (batsErr = 'Required') : (batsErr = '');
    // incorporationDate.trim() === '' ? inDateerr = "Required" :
    //     inDateerr = "";
    psxListing.trim() === "" ? (psxErr = "Required") : (psxErr = "");
    faceValue.trim() === "" ? (faceValErr = "Required") : (faceValErr = "");
    nav.trim() === "" ? (navErr = "Required") : (navErr = "");
    if (
      nameError ||
      codeError ||
      amcErr ||
      typeErr ||
      inDateerr ||
      psxErr ||
      navErr ||
      faceValErr
      // clearMemberErr
      // batsErr
    ) {
      setNameError(nameError);
      setCodeError(codeError);
      setAmcError(amcErr);
      setNavError(navErr);
      setFaceValError(faceValErr);
      // setClearMemberError(clearMemberErr);
      // setBatsError(batsErr);
      setFundTypeError(typeErr);
      setIncorporationDateError(inDateerr);
      setPsxListingError(psxErr);
      return false;
    } else {
      return true;
    }
  };
  const EditFundData = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await EditFund(
          email,
          amcName,
          fundType,
          incorporationDate,
          fundName,
          code,
          revocationDate,
          psxListing,
          nav,
          JSON.stringify(moneyMarket),
          JSON.stringify(stockMarket),
          JSON.stringify(fundSecurities),
          faceValue,
          JSON.stringify(termDeposit),
          cdc_participant_id,
          cdc_account_number,
          clearMember_id,
          bats_id,
          JSON.stringify(allFeeSlab)
        );
        if (response.data.status === 200) {
          setCheckBoxSelected(true);
          setFundName("");
          setCode("");
          setNav("");
          setAmcName("");
          stockMarket.length = 0;
          setStockMarket(stockMarket);
          moneyMarket.length = 0;
          setMoneyMarket(moneyMarket);
          termDeposit.length = 0;
          setTermDeposit(termDeposit);
          setFundType("");
          setIncorporationDate("");
          setRevocationDate("");
          sessionStorage.removeItem("fundobj");
          setPsxListing("true");
          setFaceValue("");
          toast.success(response.data.message);
          setTimeout(() => {
            history.replace(`/admin/funds`);
          }, 2000);
        } else {
          toast.error(response.data.message);
        }
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
      setLoading(false);
    }
  };
  React.useEffect(() => {
    const fetchAmc = async () => {
      try {
        const amcResponse = await getAmc(email);
        setAmcdata(amcResponse.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
      try {
        const response = await getFundType(email);
        setFundTpedata(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
      try {
        const motResponse = await getAllSecurities(email);
        setSecurityTypeData(motResponse.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
      try {
        const response = await getAllPsx(email);
        let sortedData = response.data.data.sort(function (a, b) {
          let x = a.company_name.toLowerCase();
          let y = b.company_name.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setPsxList(response.data.data);
        setPsxList(sortedData);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      // get all banks
      try {
        const bankResponse = await getAllBanks(email);
        // temp.unshift({ value: 'Other', label: 'Other' });
        setBankNameData(bankResponse.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
    };
    fetchAmc();
  }, []);

  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      setBranchData(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };
  const renderBankDropdown = () => {
    return bankNameData.map((item: any) => {
      return (
        <option
          key={item.bank_code}
          value={item.bank_code.replace("BANK_", "")}
        >
          {item.bank_name} ({item.bank_code.replace("BANK_", "")})
        </option>
      );
    });
  };

  const renderBranchDropdown = () => {
    return branchData.map((item: any) => {
      return (
        <option key={item.branch_code} value={item.branch_name}>
          {item.branch_name} ({item.branch_code})
        </option>
      );
    });
  };

  const renderAmcDropdown = () => {
    return amcdata.map((item: any) => {
      return (
        <option key={item.amc_code} value={item.amc_code.replace("AMC_", "")}>
          {item.amc_code.replace("AMC_", "")}-{item.name}
        </option>
      );
    });
  };
  const addFeeSlab = () => {
    setDisableAddBtn(true);
    let valid = feeSlabValidate();
    if (valid) {
      let fee_slab = {
        slab_from: parseFloat(isNumber(feeSlabFrom)).toFixed(2),
        slab_to: parseFloat(isNumber(feeSlabTo)).toFixed(2),
        rate: parseFloat(isNumber(feeRate)).toFixed(2),
        fix_amount: parseFloat(isNumber(feeFixAmount)).toFixed(2),
      };

      if (parseFloat(fee_slab.slab_to) < parseFloat(fee_slab.slab_from)) {
        toast.error("Slab To must be greater than Slab From");
        setDisableAddBtn(false);
      } else {
        let temp = allFeeSlab;
        temp.push(fee_slab);
        setAllFeeSlab([...temp]);

        setFeeSlabModal(false);
        setFeeSlabFrom("");
        setFeeSlabTo("");
        setFeeRate("");
        setFeeFixAmount("");
        setModalView(false);
      }
    }
  };

  const feeSlabValidate = () => {
    let feeSlabFromError,
      feeSlabToError,
      feeRateError,
      feeFixAmountError = "";

    feeSlabFrom.trim() === ""
      ? (feeSlabFromError = "Required")
      : (feeSlabFromError = "");
    feeSlabTo.trim() === ""
      ? (feeSlabToError = "Required")
      : (feeSlabToError = "");
    feeRate.trim() === "" ? (feeRateError = "Required") : (feeRateError = "");
    feeFixAmount.trim() === ""
      ? (feeFixAmountError = "Required")
      : (feeFixAmountError = "");

    if (
      feeFixAmountError ||
      feeRateError ||
      feeSlabFromError ||
      feeSlabToError
    ) {
      setFeeSlabFromErr(feeSlabFromError);
      setFeeSlabToErr(feeSlabToError);
      setFeeFixAmountErr(feeFixAmountError);
      setFeeRateErr(feeRateError);
      setDisableAddBtn(false);
      return false;
    } else {
      return true;
    }
  };
  const renderFeeDataInTable = () => {
    return allFeeSlab.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{isNumber(items.slab_from)}</td>
          <td>{isNumber(items.slab_to)}</td>
          <td className="text-right">{isNumber(items.rate)}</td>
          <td className="text-right">{isNumber(items.fix_amount)}</td>

          {/* <td>{items.calculatedAmount}</td> */}
          <td className="text-right">
            <IoIosClose
              style={{ fontSize: "25px", color: "red" }}
              onClick={() => {
                let array = [...allFeeSlab];
                array.splice(index, 1);
                setAllFeeSlab(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };
  //render dropdown for security data
  const renderSecurityDataDropdown = () => {
    return securityTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.code}>
          {item.name}-{item.code}
        </option>
      );
    });
  };
  //render dropdown for psx data
  const renderPsxDataDropdown = () => {
    return psxList.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol}>
          {item.symbol}
        </option>
      );
    });
  };

  const [securityData, setSecurityData] = useState([]);

  const renderFilterSecurityDataDropdown = (security_type: string) => {
    const check = securityTypeData.filter((item: any, index: string) => {
      return item.security_type === security_type;
    });
    setSecurityData(check);
  };

  const renderSecuritiesDropDown = () => {
    return securityData.map((item: any, index: number) => {
      return (
        <option key={index} value={item.code.replace("SECURITY_", "")}>
          {item.name}
        </option>
      );
    });
  };

  const [modalView, setModalView] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [moneyMarket, setMoneyMarket] = useState<any>([]);
  const [moneyMarketEntity, setMoneyMarketEntity] = useState<any>({
    code: "",
    total_amount: "0",
    // name: '',
  });
  // ADD TAX Entity inside tax Array
  const addMoneyMarket = async () => {
    setDisableAddBtn(true);
    let count = 0;

    for (let i in moneyMarketEntity) {
      if (moneyMarketEntity.otherCode === "") {
      } else {
        if (moneyMarketEntity[i] === "") {
          count += 1;
        }
      }
    }
    if (moneyMarketEntity["otherCode"] == "Other") {
      count -= 1;
    }
    if (count > 0) {
      toast.error("Kindly Fill All Fields");
      setDisableAddBtn(false);
    } else {
      if (moneyMarketEntity["otherCode"] === "Other") {
        if (
          moneyMarketEntity["issue_date"] === undefined &&
          moneyMarketEntity["maturity_date"] === undefined
        ) {
          toast.error("Kindly select issue and maturity date");
          setDisableAddBtn(false);
        } else {
          let date = moneyMarketEntity["issue_date"].replace("-", "");
          let date2 = moneyMarketEntity["maturity_date"].replace("-", "");
          let symbol1 =
            moneyMarketEntity["security_type"] + "-" + date + "-" + date2;
          // setMoneyMarketEntity({
          //   ...moneyMarketEntity,
          //   code: symbol1,
          //   // name: e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
          //   name: symbol1
          // });
          const tempobj = {
            ...moneyMarketEntity,
            code: symbol1,
            name: symbol1,
          };
          setModalView(false);
          // push data inside array
          moneyMarket.push(tempobj);
          setMoneyMarket(moneyMarket);
          // }
          const filedEntirty = {
            code: "",
            otherCode: "",
            total_amount: "0",
            // name: '',
          };
          setMoneyMarketEntity(filedEntirty);
          setDisableAddBtn(false);
        }
      } else {
        setModalView(false);
        // push data inside array
        moneyMarket.push(moneyMarketEntity);
        setMoneyMarket(moneyMarket);
        // }
        const filedEntirty = {
          code: "",
          total_amount: "0",
          // name: '',
        };
        setMoneyMarketEntity(filedEntirty);
        setDisableAddBtn(false);
      }
    }
  };
  const updateMoneyMarket = async () => {
    setDisableAddBtn(true);
    let count = 0;
    // push data inside array
    for (let i in moneyMarketEntity) {
      if (moneyMarketEntity[i] === "") {
        count += 1;
      }
    }
    if (count > 0) {
      toast.error("Kindly Fill All Fields");
      setDisableAddBtn(false);
    } else {
      setModalView(false);
      const check = moneyMarket.filter((item) => {
        return (
          item.code === moneyMarketEntity["code"] &&
          item.security_type == moneyMarketEntity["security_type"] &&
          item.issue_date == moneyMarketEntity["issue_date"] &&
          item.maturity_date == moneyMarketEntity["maturity_date"]
        );
      });
      if (check.length > 0) {
        moneyMarket.map((item, index) => {
          if (
            item.code === moneyMarketEntity["code"] &&
            item.security_type == moneyMarketEntity["security_type"] &&
            item.issue_date == moneyMarketEntity["issue_date"] &&
            item.maturity_date == moneyMarketEntity["maturity_date"]
          ) {
            item.coupon_rate = moneyMarketEntity.coupon_rate;
            item.face_value = moneyMarketEntity.face_value;
            item.mmface_value = moneyMarketEntity.mmface_value;
            item.price = moneyMarketEntity.price;
            item.tenor = moneyMarketEntity.tenor;
            item.total_amount = moneyMarketEntity.total_amount;
            item.units = moneyMarketEntity.units;
            item.yield = moneyMarketEntity.yield;
          }
        });
        // setMoneyMarket(updatedData);
      } else {
        // moneyMarket.push(moneyMarketEntity);
        // setMoneyMarket(moneyMarket);
      }
      const filedEntirty = {
        code: "",
        total_amount: "0",
        // name: '',
      };
      setMoneyMarketEntity(filedEntirty);
      setDisableAddBtn(false);
      setFieldReadOnly(false);
      setMoneyMarketEntity({});
    }
  };
  //render Fund table data
  const renderFundDataInTable = () => {
    return moneyMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.security_type}</td>
          <td>{items.name}</td>
          <td>{items.issue_date}</td>
          <td>{items.maturity_date}</td>
          <td>
            {numberWithCommas(parseFloat(items.coupon_rate).toFixed(4) || "")}
          </td>
          <td>{numberWithCommas(parseFloat(items.yield).toFixed(4) || "")}</td>
          <td className="text-right">
            {numberWithCommas(parseFloat(items.mmface_value).toFixed(4) || "")}
          </td>
          <td className="text-right">
            {numberWithCommas(parseFloat(items.face_value).toFixed(4) || "")}
          </td>
          <td className="text-right">
            {numberWithCommas(parseFloat(items.price).toFixed(4) || "")}
          </td>
          <td className="text-right">
            {numberWithCommas(parseFloat(items.units).toFixed(4) || "")}
          </td>
          <td className="text-right">
            {" "}
            {numberWithCommas(
              parseFloat(items.total_amount).toFixed(2) || ""
            ) &&
              numberWithCommas(
                parseFloat(items.total_amount || "0").toFixed(2)
              )}{" "}
          </td>
          <td>{items.tenor}</td>
          {/* <td>{items.calculatedAmount}</td> */}
          <td>
            <IoIosClose
              style={{ fontSize: "25px", color: "red" }}
              onClick={() => {
                let array = [...moneyMarket];
                array.splice(index, 1);
                setMoneyMarket(array);
              }}
            />
            {role == "ADMIN" && (
              <button
                type="button"
                className="btn-icon btn-link like btn btn-info btn-sm"
                onClick={() => {
                  renderFilterSecurityDataDropdown(items.security_type);
                  setFieldReadOnly(true);
                  setMoneyMarketEntity(items);
                  setModalView(!modalView);
                  setDisableAddBtn(false);
                  setStockModal(false);
                }}
              >
                <i className="tim-icons icon-pencil"></i>
              </button>
            )}
          </td>
        </tr>
      );
    });
  };
  // render Model for adding Fund
  const renderModalPopup = () => {
    switch (modalView) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setModalView(false);
              setFieldReadOnly(false);
              setFeeSlabModal(false);
              setMoneyMarketEntity({});
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setModalView(false);
                  setFieldReadOnly(false);
                  setFeeSlabModal(false);
                  setMoneyMarketEntity({});
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">
                {" "}
                {feeSlabModal
                  ? "Add fee Slab"
                  : stockModal
                    ? "Add Stock Market"
                    : "Add Money Market"}{" "}
              </h6>
            </div>

            {!feeSlabModal ? (
              <div className="modal-body">
                <Row>
                  <Col>
                    <div className="form-group">
                      <div>Select Security / Placement</div>
                      <select
                        className="form-control"
                        value={moneyMarketEntity["security_type"]}
                        disabled={fieldReadOnly}
                        onChange={(e) => {
                          renderFilterSecurityDataDropdown(e.target.value);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            security_type: e.target.value,
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {" "}
                          Select Security / Placement
                        </option>
                        <optgroup>Money Market</optgroup>
                        <option value="PIB">Pakistan Investment Bonds</option>
                        <option value="TBILL">Treasury Bills</option>

                        <optgroup>Debt Market</optgroup>
                        <option value="SUKUK">Sukuk Certificates</option>
                        <option value="IJARA">GoP Ijara Sukuks</option>
                        <option value="TFC">Term Finance Certificates</option>
                        <option value="CP">Commercial Papers</option>

                        <optgroup>Term Deposits</optgroup>
                        <option value="TDR">Term Deposit Receipts</option>
                        <option value="CDR">Call Deposit Receipts</option>
                        <option value="LOP">Letter of Placement</option>
                      </select>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <div>Select Security</div>
                      <ReactTooltip
                        textColor="white"
                        backgroundColor="black"
                        effect="float"
                      />

                      <select
                        className="form-control"
                        value={
                          moneyMarketEntity["otherCode"] === ""
                            ? moneyMarketEntity["code"]
                            : moneyMarketEntity["otherCode"]
                        }
                        disabled={fieldReadOnly}
                        onChange={(e) => {
                          // let value = securityTypeData.filter((item: any) => {
                          //   return item.code === e.target.value;
                          // });
                          if (e.target.value === "Other") {
                            setMoneyMarketEntity({
                              ...moneyMarketEntity,
                              // code: e.target.value,
                              otherCode: e.target.value,
                              otherName:
                                e.nativeEvent.target === null
                                  ? ""
                                  : e.nativeEvent.target[e.target.selectedIndex]
                                    .text,
                            });
                            // }
                          } else {
                            setMoneyMarketEntity({
                              ...moneyMarketEntity,
                              // code: e.target.value,
                              code: e.target.value,
                              name:
                                e.nativeEvent.target === null
                                  ? ""
                                  : e.nativeEvent.target[e.target.selectedIndex]
                                    .text,
                            });
                          }
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Security
                        </option>
                        {/* {renderSecurityDataDropdown()} */}
                        {renderSecuritiesDropDown()}
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </Col>
                  {/* <Col md="6">
                  <div className="form-group">
                    <div>Symbol</div>
                    <input
                      type="text"
                      className="form-control w-100"
                      value={moneyMarketEntity['code']}
                      onChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          code: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col> */}
                </Row>

                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <div>Issue Date</div>
                      <input
                        type="date"
                        className="form-control w-100"
                        value={moneyMarketEntity["issue_date"]}
                        disabled={fieldReadOnly}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            issue_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <div>Maturity Date</div>
                      <input
                        type="date"
                        className="form-control w-100"
                        value={moneyMarketEntity["maturity_date"]}
                        disabled={fieldReadOnly}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            maturity_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <div>Coupon Rate</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity["coupon_rate"]}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            coupon_rate: value,
                          });
                        }}
                      />
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <div>Yield</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity["yield"]}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            yield: value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <div>Money Market Face Value</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity["mmface_value"]}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            mmface_value: value,
                          });
                        }}
                      />
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <div>Total Face Value</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity["face_value"]}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            face_value: value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <div>Price</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity["price"]}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            price: value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <div>Units</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity["units"]}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            units: value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <div>Settlement Amount</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity["total_amount"]}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 2);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            total_amount: value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <div>Tenor</div>
                      <input
                        type="number"
                        className="form-control w-100"
                        value={moneyMarketEntity["tenor"]}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            tenor: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="row">
                  <div className="col-sm-12">
                    {!fieldReadOnly ? (
                      <button
                        className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                        onClick={() => {
                          if (parseFloat(moneyMarketEntity["units"]) <= 0) {
                            toast.error("Units must be greater than zero.");
                          } else {
                            addMoneyMarket();
                          }
                        }}
                        disabled={Boolean(disableAddBtn)}
                      >
                        {disableAddBtn ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Add</span>
                        )}
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                        onClick={() => {
                          if (parseFloat(moneyMarketEntity["units"]) <= 0) {
                            toast.error("Units must be greater than zero.");
                          } else {
                            updateMoneyMarket();
                          }
                        }}
                        disabled={Boolean(disableAddBtn)}
                      >
                        {disableAddBtn ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Update</span>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="modal-body">
                <Row>
                  <Col md="6">
                    <div className="form-group  ">
                      <label>Slab From</label>
                      <div className="">
                        <input
                          type="number"
                          className={`form-control  w-100 ${feeSlabFromErr ? "required-border" : ""
                            }`}
                          value={feeSlabFrom}
                          onChange={(e) => {
                            if (e.target.value.includes(".")) {
                              let decimalVal = e.target.value.split(".")[1];
                              let val = e.target.value.split(".")[0];
                              setFeeSlabFrom(
                                val + "." + decimalVal.substring(0, 7)
                              );
                            } else {
                              setFeeSlabFrom(e.target.value);
                            }

                            setFeeSlabFromErr("");
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group  ">
                      <label>Rate %</label>
                      <div className="">
                        <input
                          type="number"
                          className={`form-control  w-100 ${feeRateErr ? "required-border" : ""
                            }`}
                          value={feeRate}
                          onChange={(e) => {
                            if (e.target.value.includes(".")) {
                              let decimalVal = e.target.value.split(".")[1];
                              let val = e.target.value.split(".")[0];
                              setFeeRate(
                                val + "." + decimalVal.substring(0, 7)
                              );
                            } else {
                              setFeeRate(e.target.value);
                            }

                            setFeeRateErr("");
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group  ">
                      <label>Slab To</label>
                      <div className="">
                        <input
                          type="number"
                          className={`form-control  w-100 ${feeSlabToErr ? "required-border" : ""
                            }`}
                          value={feeSlabTo}
                          onChange={(e) => {
                            if (e.target.value.includes(".")) {
                              let decimalVal = e.target.value.split(".")[1];
                              let val = e.target.value.split(".")[0];
                              setFeeSlabTo(
                                val + "." + decimalVal.substring(0, 7)
                              );
                            } else {
                              setFeeSlabTo(e.target.value);
                            }

                            setFeeSlabToErr("");
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group  ">
                      <label>Fix Amount</label>
                      <div className="">
                        <input
                          type="number"
                          className={`form-control  w-100 ${feeFixAmountErr ? "required-border" : ""
                            }`}
                          value={feeFixAmount}
                          onChange={(e) => {
                            if (e.target.value.includes(".")) {
                              let decimalVal = e.target.value.split(".")[1];
                              let val = e.target.value.split(".")[0];
                              setFeeFixAmount(
                                val + "." + decimalVal.substring(0, 7)
                              );
                            } else {
                              setFeeFixAmount(e.target.value);
                            }

                            setFeeFixAmountErr("");
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="row">
                  <div className="col-sm-12">
                    <button
                      className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                      onClick={() => {
                        addFeeSlab();
                      }}
                      disabled={Boolean(disableAddBtn)}
                    >
                      {disableAddBtn ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        <span>Add</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        );
      default:
        return "";
    }
  };

  //render fund Security table data
  const renderFundSecuirtiesTable = () => {
    return fundSecurities.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>
            {numberWithCommas(parseFloat(isNumber(items.price)).toFixed(4))}
          </td>
          <td className="text-right">
            {numberWithCommas(parseFloat(isNumber(items.units)).toFixed(4))}
          </td>
          <td className="text-right">
            {numberWithCommas(
              parseFloat(isNumber(items.total_amount)).toFixed(2)
            )}
          </td>
          {/* <td>{items.calculatedAmount}</td> */}
          <td className="text-right">
            <IoIosClose
              style={{ fontSize: "25px", color: "red" }}
              onClick={() => {
                let array = [...fundSecurities];
                array.splice(index, 1);
                setFundSecurities(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };
  //onchange Quantity in security Modal  the value of Gross amount calculated here
  const onChangeSecurityValue = (value) => {
    let grossAmount =
      parseFloat(value) * parseFloat(fundInvestmentSecurityEtity["price"]);
    setFundInvestmentSecurityEtity((prevState) => ({
      ...fundInvestmentSecurityEtity,
      total_amount: grossAmount.toFixed(2),
    }));
  };

  //onchange Quantity in security Modal  the value of Gross amount calculated here
  const onChangeRate = (value) => {
    let grossAmount =
      parseFloat(fundInvestmentSecurityEtity["units"]) * parseFloat(value);
    setFundInvestmentSecurityEtity((prevState) => ({
      ...fundInvestmentSecurityEtity,
      total_amount: grossAmount.toFixed(2),
    }));
  };

  //render dropdown for Fund Securities
  const renderFundSecuritiesDropdown = () => {
    return allFundSecurities.map((item: any, index: string) => {
      if (code !== item.code) {
        return (
          <option
            key={index}
            value={item.code}
          >{`${item.code} - ${item.name}`}</option>
        );
      }
    });
  };

  const [fundInvestmentSecurityEtity, setFundInvestmentSecurityEtity] =
    useState<any>({
      code: "",
      units: "",
      price: "",
      total_amount: "",
    });

  // add Fund Security Entity
  const addFundInvestmentSecurityInArray = () => {
    let count = 0;
    for (let i in fundInvestmentSecurityEtity) {
      if (fundInvestmentSecurityEtity[i] === "") {
        count = count + 1;
      }
    }
    if (count > 0) {
      toast.error("Kindly Fill All Fields");
    } else {
      const check = fundSecurities.filter((item) => {
        return item.code === fundInvestmentSecurityEtity["code"];
      });
      if (check.length > 0) {
        fundSecurities.map((item, index) => {
          if (item.code === fundInvestmentSecurityEtity["code"]) {
            item.units =
              isNumber(parseFloat(item.units)) +
              isNumber(parseFloat(fundInvestmentSecurityEtity["units"]));
            item.total_amount =
              isNumber(parseFloat(item.total_amount)) +
              isNumber(parseFloat(fundInvestmentSecurityEtity["total_amount"]));
            item.price = isNumber(
              parseFloat(fundInvestmentSecurityEtity["price"])
            );
          }
        });
      } else {
        fundSecurities.push(fundInvestmentSecurityEtity);
        setFundSecurities(fundSecurities);
      }

      setViewAddFundInvestmentModal(false);
      setFundInvestmentSecurityEtity((prevState) => ({
        ...fundInvestmentSecurityEtity,
        code: "",
        units: "",
        price: "",
        total_amount: "",
      }));
    }
  };

  // ADD  Investment In Fund
  const [viewAddFundInvestmentModal, setViewAddFundInvestmentModal] =
    useState(false);
  const renderModalInvestmentInFundPopup = () => {
    switch (viewAddFundInvestmentModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddFundInvestmentModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Add Fund Security </h6>
            </div>
            <div className="modal-body ">
              <div className="">
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      Funds*
                      <select
                        className="form-control w-100"
                        onChange={(e) => {
                          fundInvestmentSecurityEtity["code"] = e.target.value;
                          setFundInvestmentSecurityEtity(
                            fundInvestmentSecurityEtity
                          );
                          // let value = allFundSecurities.filter((item: any) => {
                          //   return item.code === e.target.value;
                          // });

                          // fundInvestmentSecurityEtity['avg_rate'] = value[0]?.price || '0';
                          // setCloseRate(value[0]?.price || '0');
                          // fundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                          // setAccTiltleName(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {" "}
                          Select
                        </option>
                        {renderFundSecuritiesDropdown()}
                      </select>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      NAV Price*
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={fundInvestmentSecurityEtity["price"]}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          fundInvestmentSecurityEtity["price"] = value;
                          let grossAmount =
                            parseFloat(
                              fundInvestmentSecurityEtity["units"] || "0"
                            ) *
                            parseFloat(
                              fundInvestmentSecurityEtity["price"] || "0"
                            );
                          fundInvestmentSecurityEtity["total_amount"] =
                            grossAmount;
                          // fundInvestmentSecurityEtity['avg_rate'] = value
                          // setCloseRate(value);
                          setFundInvestmentSecurityEtity(
                            fundInvestmentSecurityEtity
                          );
                          onChangeRate(value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      No of Units*
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={decimalValue(
                          fundInvestmentSecurityEtity["units"],
                          4
                        )}
                        onValueChange={(e) => {
                          let value = e.value;
                          fundInvestmentSecurityEtity["units"] = value;
                          let grossAmount =
                            parseFloat(
                              fundInvestmentSecurityEtity["units"] || "0"
                            ) *
                            parseFloat(
                              fundInvestmentSecurityEtity["price"] || "0"
                            );
                          fundInvestmentSecurityEtity["total_amount"] =
                            grossAmount;
                          setFundInvestmentSecurityEtity(
                            fundInvestmentSecurityEtity
                          );
                          onChangeSecurityValue(value);
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      Total Amount
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={
                          // parseFloat(
                          fundInvestmentSecurityEtity["total_amount"]
                          // ).toFixed(2)
                        }
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 2);
                          fundInvestmentSecurityEtity["total_amount"] = value;
                          setFundInvestmentSecurityEtity(
                            fundInvestmentSecurityEtity
                          );
                          // onChangeGrossValue(value);
                        }}
                      // readOnly
                      />
                    </div>
                  </Col>
                </Row>

                <div className="">
                  <button
                    className="btn btn-primary mt-3 w-100 px-3 rounded"
                    onClick={() => {
                      if (
                        parseFloat(fundInvestmentSecurityEtity["price"]) <= 0
                      ) {
                        toast.error(
                          "Nav price must be greater or equal to zero"
                        );
                      } else if (
                        parseFloat(fundInvestmentSecurityEtity["units"]) <= 0
                      ) {
                        toast.error("No of Units must be greater than zero.");
                      } else {
                        addFundInvestmentSecurityInArray();
                      }
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  //for stock Market
  const [equityModalFlag, setEquityModalFlag] = useState(false);
  const [disableEquityBtn, setDisableEquityBtn] = useState<boolean>(false);
  const [stockMarket, setStockMarket] = useState<any>([]);
  const [stockEntity, setStockEntity] = useState<any>({
    code: "",
    unit: "",
    amount: "0",
    close_rate: "0",
    pledged: "0",
  });
  // ADD TAX Entity inside tax Array
  const addEquityStock = async () => {
    setDisableEquityBtn(true);
    let count = 0;
    //push data inside array
    for (let i in stockEntity) {
      if (stockEntity[i] === "") {
        count += 1;
      }
    }
    if (count > 0) {
      toast.error("Kindly Fill All Fields");
      setDisableEquityBtn(false);
    } else {
      setEquityModalFlag(false);
      const check = stockMarket.filter((item) => {
        return item.code === stockEntity["code"];
      });
      if (check.length > 0) {
        stockMarket.map((item, index) => {
          if (item.code === stockEntity["code"]) {
            item.unit = (+item.unit + +stockEntity["unit"]).toString();
            item.amount = (
              parseFloat(item.unit) * parseFloat(item.close_rate)
            ).toFixed(2);
          }
        });
      } else {
        stockMarket.push(stockEntity);
        setStockMarket(stockMarket);
      }
      const filedEntirty = {
        code: "",
        unit: "",
        amount: "0",
        close_rate: "0",
        pledged: "0",
      };
      setStockEntity(filedEntirty);
      setDisableEquityBtn(false);
    }
  };
  const [stockModal, setStockModal] = useState(false);
  //render equity table data
  const renderEquityInTable = () => {
    return stockMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>
            {numberWithCommas(parseFloat(items.close_rate).toFixed(4) || "") &&
              numberWithCommas(parseFloat(items.close_rate).toFixed(4))}{" "}
          </td>
          <td className="text-right">
            {numberWithCommas(parseFloat(items.unit).toFixed(4) || "") &&
              numberWithCommas(parseFloat(items.unit).toFixed(4) || "")}{" "}
          </td>
          <td className="text-right">
            {numberWithCommas(items.amount || "") &&
              numberWithCommas(parseFloat(items.amount).toFixed(2))}{" "}
          </td>
          <td className="pl-3">{items.market} </td>
          <td>{numberWithCommas(items.pledged)}</td>
          {/* <td>{items.calculatedAmount}</td> */}
          <td>
            <IoIosClose
              style={{ fontSize: "25px", color: "red" }}
              onClick={() => {
                let array = [...stockMarket];
                array.splice(index, 1);
                setStockMarket(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };
  // render Model for adding Equity
  const renderEquityPopup = () => {
    switch (equityModalFlag) {
      case true:
        return (
          <Modal
            className="  "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setEquityModalFlag(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setEquityModalFlag(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">
                {stockModal ? "Add Stock Market" : "Add Money Market"}{" "}
              </h6>
            </div>

            <div className="modal-body">
              <Row>
                <Col md="6">
                  <div className="form-group">
                    Market*
                    <select
                      className="form-control w-100"
                      value={stockEntity.market}
                      onChange={(e) => {
                        setStockEntity({
                          ...stockEntity,
                          market: e.target.value,
                        });
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        Select Market
                      </option>
                      <option value="REG">Regular</option>
                      <option value="ODL">Odd Lot</option>
                      <option value="FUT">Future</option>
                      <option value="BNB">Bills and Bonds</option>
                      <option value="IPO">Initial Public Offering</option>
                      <option value="BKB">Book Building</option>
                      <option value="CSF">Cash Settled Futures</option>
                      <option value="DSF">Direct Settled Futures</option>
                      <option value="SIF">Stock Index Futures</option>
                      <option value="NDM">Negotiable Deal Market</option>
                      <option value="FRO">Future Rollover Contract</option>
                      <option value="MTS">Margin Trading System</option>
                      <option value="SQR">Square Up</option>
                    </select>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    Select Security*
                    <select
                      className="form-control w-100"
                      value={stockEntity["code"]}
                      onChange={(e) => {
                        let value = psxList.filter((item: any) => {
                          return item.symbol === e.target.value;
                        });
                        setStockEntity({
                          ...stockEntity,
                          code: e.target.value,
                          close_rate: value[0].close_rate,
                          amount: (
                            parseFloat(e.target.value) *
                            parseFloat(stockEntity.Unit)
                          ).toFixed(2),
                        });
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        Select Security
                      </option>
                      {renderPsxDataDropdown()}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group">
                    Average Rate
                    <input
                      type="number"
                      className="form-control w-100 disable-input"
                      value={decimalValue(stockEntity["close_rate"], 4)}
                      readOnly
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    Units*
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={decimalValue(stockEntity["unit"], 4)}
                      onValueChange={(e) => {
                        setStockEntity({
                          ...stockEntity,
                          unit: e.value,
                          amount: (
                            parseFloat(e.value) *
                            parseFloat(stockEntity.close_rate)
                          ).toFixed(2),
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group">
                    Amount
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={stockEntity["amount"]}
                      readOnly
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    Pledged
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={stockEntity["pledged"]}
                      onValueChange={(e) => {
                        setStockEntity({
                          ...stockEntity,
                          pledged: e.value,
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <div className="row">
                <div className="col-sm-12">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      if (parseFloat(stockEntity["unit"]) <= 0) {
                        toast.error("Units must be greater than zero.");
                      } else {
                        addEquityStock();
                      }
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const [termDepositModal, setTermDepositModal] = useState(false);
  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [termDepositEntity, setTermDepositEntity] = useState<any>({
    code: "",
    name: "",
    principal_amount: "",
    interest_rate: "",
    from_date: "",
    to_date: "",
    bank: "",
    branch: "",
  });
  const addTermDeposit = async () => {
    // setDisableEquityBtn(true);
    let count = 0;
    //push data inside array
    for (let i in termDepositEntity) {
      if (termDepositEntity[i] === "") {
        count += 1;
      }
    }
    if (count > 0) {
      toast.error("Kindly Fill All Fields");
      // setDisableEquityBtn(false);
    } else {
      setTermDepositModal(false);
      // const check = termDeposit.filter((item) => {
      //   return item.interest_rate === termDepositEntity['interest_rate'];
      // });
      // if (check.length > 0) {
      //   termDeposit.map((item, index) => {
      //     if (item.code === termDepositEntity['code']) {
      //       item.interest_rate = (+item.interest_rate + +termDepositEntity['interest_rate']).toString();
      //     }
      //   });
      // } else {
      termDeposit.push(termDepositEntity);
      setTermDeposit(termDeposit);
      // }
      const filedEntirty = {
        code: "",
        name: "",
        principal_amount: "",
        interest_rate: "",
        from_date: "",
        to_date: "",
        bank: "",
        branch: "",
      };
      setTermDepositEntity(filedEntirty);
      // setDisableEquityBtn(false);
    }
  };

  const renderTermDepositInTable = () => {
    return termDeposit.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.name}</td>
          <td>{numberWithCommas(items.principal_amount || "")}</td>
          <td>{numberWithCommas(items.interest_rate || "")}</td>
          <td>{items.from_date}</td>
          <td>{items.to_date}</td>
          <td>{items.bank}</td>
          <td>{items.branch}</td>
          <td>
            <IoIosClose
              style={{ fontSize: "25px", color: "red" }}
              onClick={() => {
                let array = [...termDeposit];
                array.splice(index, 1);
                setTermDeposit(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const renderTermDepositPopup = () => {
    switch (termDepositModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setTermDepositModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setTermDepositModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Term Deposit</h6>
            </div>

            <div className="modal-body ">
              <div className="">
                <Row>
                  <Col>
                    <div className="form-group">
                      Type
                      <select
                        className="form-control w100"
                        onChange={(e) => {
                          termDepositEntity["code"] = e.target.value;
                          termDepositEntity["name"] =
                            e.nativeEvent.target === null
                              ? ""
                              : e.nativeEvent.target[e.target.selectedIndex]
                                .text;
                          setTermDepositEntity(termDepositEntity);
                        }}
                      >
                        <option value="" hidden>
                          {" "}
                          Select Type
                        </option>
                        <option value="TDR">Term Deposit Receipts</option>
                        <option value="CDR">Call Deposit Receipts</option>
                        <option value="LOP">Letter of Placement</option>
                      </select>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      Principal Amount
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        // value={stockEntity['close_rate']}
                        onValueChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            principal_amount: e.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      Interest Rate
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        onValueChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            interest_rate: e.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-group">
                      From Date
                      <input
                        type="date"
                        className="form-control w-100"
                        // value={stockEntity['close_rate']}
                        onChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            from_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      To Date
                      <input
                        type="date"
                        className="form-control w-100"
                        // value={stockEntity['close_rate']}
                        onChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            to_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-group">
                      Bank
                      <select
                        className="form-control w-100"
                        onChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            bank: e.target.value,
                          });
                          getCounterBranchByName(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {" "}
                          Select Bank
                        </option>
                        {renderBankDropdown()}
                      </select>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      Branch
                      <select
                        className="form-control w-100"
                        onChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            branch: e.target.value,
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {" "}
                          Select Branch
                        </option>
                        {renderBranchDropdown()}
                      </select>
                    </div>
                  </Col>
                </Row>

                <div className="">
                  <button
                    className="btn btn-primary mt-3 w-100 px-3 rounded"
                    onClick={() => {
                      addTermDeposit();
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const [codeChange, setCodeChange] = useState("");
  const decimalValue = (value, len) => {
    let startingValue = value.split(".")[0];
    let decimalVal = value.split(".")[1];
    let temp = "";
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + "." + decimalString;
      } else {
        temp = startingValue + "." + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />

              <div className="input-holder">
                <h4 className="card-title">
                  <i
                    className="fa fa-angle-left mr-1 pointer"
                    onClick={() => {
                      history.replace("/admin/funds");
                    }}
                  ></i>
                  Edit - Funds
                </h4>
                <Link
                  to="/admin/funds"
                  className="t-3 ml-auto btn btn-primary btn-sm"
                  replace
                >
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Fund Details</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>Fund Name</label>
                        <div className=" ">
                          <input
                            type="text"
                            className={`form-control  w-100 ${namelError ? "required-border" : ""
                              }`}
                            value={fundName}
                            onChange={(e) => {
                              setFundName(e.target.value);
                              setNameError("");
                            }}
                          />
                          {/* {namelError ? <p className="error-labels error-message2">{namelError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Fund Code</label>
                        <div className="  disable">
                          <input
                            type="text"
                            readOnly
                            className={`form-control  w-100 ${codeError ? "required-border" : ""
                              }`}
                            value={code}
                            onChange={(e) => {
                              setCode(e.target.value.toUpperCase());
                              setCodeError("");
                            }}
                          />
                          {/* {codeError ? <p className="error-labels error-message">{codeError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>AMC Name</label>
                        <div className="">
                          <select
                            className={`form-control  w-100 ${amcNameError ? "required-border" : ""
                              }`}
                            value={amcName}
                            onChange={(e) => {
                              setAmcError("");
                              setAmcName(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {" "}
                              Select An AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                          {/* {amcNameError ? <p className="error-labels error-message2">{amcNameError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Nature / Type of Fund</label>
                        <div className="">
                          {/* <input	
                        type="text"	
                        className={`form-control  w-100 ${	
                          fundTypeError ? 'required-border' : ''	
                        }`}	
                        value={fundType}	
                        onChange={(e) => {	
                          setFundType(e.target.value);	
                          setFundTypeError('');	
                        }}	
                      />	 */}
                          <select
                            className={`form-control input-broker  w-100 ${fundTypeError ? "required-border" : ""
                              }`}
                            value={fundType}
                            onChange={(e) => {
                              setFundType(e.target.value);
                              setFundTypeError("");
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select
                            </option>
                            <option value="Agressive Income Fund">
                              Agressive Income Fund
                            </option>
                            <option value="Asset Allocation Fund">
                              Asset Allocation Fund
                            </option>
                            <option value="Income Fund">Income Fund</option>
                            <option value="Money Market Fund">
                              Money Market Fund
                            </option>
                            <option value="Stock Fund">Stock Fund</option>
                            <option value="Private Fund Managment Co">
                              Private Fund Managment Co
                            </option>
                            <option value="Fund of Fund">Fund of Fund</option>
                            <option value="Special Managed Account">
                              Special Managed Account (SMA)
                            </option>
                            <option value="Private Equity">
                              Private Equity
                            </option>
                            <option value="Venture Capital">
                              Venture Capital
                            </option>
                            <option value="Fixed Return Fund">
                              Fixed Return Fund
                            </option>
                            <option value="REIT Scheme">REIT Scheme</option>
                            <option value="Live Stock">Live Stock</option>
                          </select>
                          {/* {fundTypeError ? <p className="error-labels error-message">{fundTypeError}</p> : ''} */}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Incorporation</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>Date of Incorporation</label>
                        <div className="">
                          <input
                            type="date"
                            className={`form-control  w-100 ${incorporationDateError ? "required-border" : ""
                              }`}
                            value={incorporationDate}
                            onChange={(e) => {
                              setIncorporationDate(e.target.value);
                              setIncorporationDateError("");
                            }}
                          />
                          {/* {incorporationDateError ? <p className="error-labels error-message2">{incorporationDateError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Date of Revocation</label>
                        <div className="">
                          <input
                            type="date"
                            className="form-control"
                            value={revocationDate}
                            onChange={(e) => {
                              setRevocationDate(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>
                              NAV<span className="color-amber">*</span>
                            </label>
                            <div className="">
                              <input
                                type="text"
                                className={`form-control  w-100 ${navError ? "required-border" : ""
                                  }`}
                                value={nav}
                                onChange={(e) => {
                                  let value = decimalValue(e.target.value, 4);
                                  setNav(value);
                                  setNavError("");
                                  setCodeChange(value);
                                }}
                              />
                              {/* {navError ? <p className="error-labels error-message2">{navError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label htmlFor="">
                              Face Value <span className="color-amber">*</span>
                            </label>
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              className={`form-control  w-100 text-right ${faceValError ? "required-border" : ""
                                }`}
                              value={faceValue}
                              onValueChange={(e) => {
                                setFaceValue(e.value);
                                setFaceValError("");
                                // setNameError('');
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <label>CDC Participant ID</label>
                            <div>
                              <input
                                type="text"
                                className={`form-control  w-100 `}
                                value={cdc_participant_id}
                                onChange={(e) => {
                                  setCDCParticipantID(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label htmlFor="">CDC Account Number </label>
                            <input
                              type="text"
                              className="form-control"
                              value={cdc_account_number}
                              onChange={(e) => {
                                setCDCAccountNumber(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <label>BATS ID</label>
                            <div>
                              <input
                                type="text"
                                className="form-control  w-100"
                                value={bats_id}
                                onChange={(e) => {
                                  setBatsID(e.target.value);
                                  // setBatsError('')
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label>Clearing Member ID</label>
                            <div>
                              <input
                                type="text"
                                className={`form-control  w-100 `}
                                value={clearMember_id}
                                onChange={(e) => {
                                  setCleariMemberID(e.target.value);
                                  // setClearMemberError('')
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div>
                            <label>PSX Listing</label>
                            <div>
                              <div>
                                <form action="">
                                  <FormGroup
                                    check
                                    className="form-check-radio d-inline mr-3"
                                  >
                                    <Label check>
                                      <Input
                                        type="radio"
                                        id="listed"
                                        name="listed"
                                        value="true"
                                        checked={isCheckBoxSelected}
                                        onChange={(e) => {
                                          setPsxListingError("");
                                          setPsxListing(e.target.value);
                                          setCheckBoxSelected(true);
                                        }}
                                      />
                                      <span className="form-check-sign" />
                                      Listed
                                    </Label>
                                  </FormGroup>

                                  <FormGroup
                                    check
                                    className="form-check-radio d-inline mr-3"
                                  >
                                    <Label check>
                                      <Input
                                        id="unlisted"
                                        name="listed"
                                        value="false"
                                        onChange={(e) => {
                                          setPsxListingError("");
                                          setPsxListing(e.target.value);
                                          setCheckBoxSelected(false);
                                        }}
                                        type="radio"
                                      />
                                      <span className="form-check-sign" />
                                      Unlisted
                                    </Label>
                                  </FormGroup>
                                  <br />
                                </form>
                                {psxListingError ? (
                                  <p className="error-labels error-mes">
                                    {psxListingError}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>

                {/* <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Fee Slab</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="input-size-pos ml-3">
                        <Row>
                          <Col>
                            <label>Add Fee Slab</label>
                            <div>
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />

                              <button
                                data-tip="Add Fee Slab"
                                className="btn-round btn-icon btn btn-primary"
                                onClick={() => {
                                  setModalView(!modalView);
                                  setFeeSlabFrom("");
                                  setFeeSlabTo("");
                                  setFeeRate("");
                                  setFeeFixAmount("");
                                  setFeeSlabFromErr("");
                                  setFeeSlabToErr("");
                                  setFeeRateErr("");
                                  setFeeFixAmountErr("");
                                  setDisableAddBtn(false);
                                  setStockModal(false);
                                  setFeeSlabModal(true);
                                }}
                              >
                                {" "}
                                <i className="fa fa-plus"> </i>{" "}
                              </button>
                            </div>
                          </Col>
                          
                        </Row>
                      </div>

                      <div className="table-responsive">
                        {allFeeSlab.length > 0 && (
                          <div className="input-size-pos mb-3 mt-2 ">
                            <table className="table   ">
                              <thead>
                                <tr>
                                  <th>Slab From</th>
                                  <th>Slab To</th>
                                  <th className="text-right">Rate %</th>
                                  <th className="text-right">Fix Amount</th>
                                  <th className="text-right">Action</th>
                                </tr>
                              </thead>
                              <tbody>{renderFeeDataInTable()}</tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Money Market Portfolio</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="input-size-pos">
                        <Row>
                          <Col>
                            <label>Add Portfolio</label>
                            <div>
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              <button
                                data-tip="Add Money Maket"
                                className="btn-round btn-icon btn btn-primary"
                                onClick={() => {
                                  setModalView(!modalView);
                                  setDisableAddBtn(false);
                                  setStockModal(false);
                                }}
                              >
                                {" "}
                                <i className="fa fa-plus"></i>{" "}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="table-responsive">
                        {moneyMarket.length > 0 && (
                          <div className="input-size-pos mb-3 mt-2 ">
                            <table className="table ">
                              <thead>
                                <tr>
                                  <th>Symbol / Code</th>
                                  <th>Security Type</th>
                                  <th>Name</th>
                                  <th>Issue Date</th>
                                  <th>Maturity Date</th>
                                  <th>Coupon Rate</th>
                                  <th>Yield</th>
                                  <th className="text-right">
                                    Money Market Face Value
                                  </th>
                                  <th className="text-right">
                                    Total Face Value
                                  </th>
                                  <th className="text-right">Price</th>
                                  <th className="text-right">Units</th>
                                  <th className="text-right">
                                    Settlement Amount
                                  </th>
                                  <th>Tenor</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>{renderFundDataInTable()}</tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Stock Market Portfolio</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="input-size-pos ">
                        <Row>
                          <Col>
                            <label>Add Portfolio</label>
                            <div>
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              <button
                                data-tip="Add Stock Market"
                                className="btn-round btn-icon btn btn-primary"
                                onClick={() => {
                                  setEquityModalFlag(!equityModalFlag);
                                  setDisableEquityBtn(false);
                                  setStockModal(true);
                                  const filedEntirty = {
                                    code: "",
                                    unit: "",
                                    amount: "0",
                                    close_rate: "0",
                                  };
                                  setStockEntity(filedEntirty);
                                }}
                              >
                                {" "}
                                <i className="fa fa-plus"> </i>{" "}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="table-responsive">
                        {stockMarket.length > 0 && (
                          <div className="input-size-pos mb-3 mt-2">
                            <table className="table ">
                              <thead>
                                <tr>
                                  <th>Symbol / Code</th>
                                  <th>Rate</th>
                                  <th className="text-right">Quantity</th>
                                  <th className="text-right">Amount</th>
                                  <th className="pl-3">Market</th>
                                  <th>Pledged</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>{renderEquityInTable()}</tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Mutual Funds Portfolio</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="input-size-pos ml-3">
                        <Row>
                          <Col>
                            <label>Add Portfolio</label>
                            <div>
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />

                              <button
                                data-tip="Add Stock Market"
                                className="btn-round btn-icon btn btn-primary"
                                onClick={() => {
                                  setViewAddFundInvestmentModal(true);
                                  // setEquityModalFlag(!equityModalFlag);
                                  // setDisableEquityBtn(false);
                                  // setStockModal(true);
                                  const investment = {
                                    code: "",
                                    units: "",
                                    price: "",
                                    total_amount: "",
                                  };
                                  setFundInvestmentSecurityEtity(investment);
                                }}
                              >
                                {" "}
                                <i className="fa fa-plus"> </i>{" "}
                              </button>
                            </div>
                          </Col>
                          {/* <Col>
                            <label>Upload Portfolio</label>
                            <div onClick={() => myRef2?.current?.click()}>
                              <div className="form-group">
                                <div className="form-control" data-tip="Choose File">

                                  {fileName2 === '' ? 'Choose File' : fileName2}
                                </div>
                                <input
                                  type="file"
                                  ref={myRef2}
                                  style={{ display: 'none' }}
                                  multiple={false}
                                  data-tip="Choose File"
                                  onChange={(e) => {
                                    uploadStockMarketPortfolio(e);
                                  }}
                                  onClick={(e) => {
                                    e.currentTarget.value = '';
                                  }}
                                />
                                {fileError2 ? (
                                  <p className="error-labels error-message2">{fileError2}</p>
                                ) : (
                                  ''
                                )}

                              </div>
                            </div>
                            {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i>}
                          </Col> */}
                        </Row>
                      </div>

                      <div className="table-responsive">
                        {fundSecurities.length > 0 && (
                          <div className="input-size-pos mb-3 mt-2 ">
                            {/* <h3>Stock Market Portfolio</h3> */}
                            <table className="table   ">
                              <thead>
                                <tr>
                                  <th>Fund Symbol / Code</th>
                                  <th>NAV Price</th>
                                  <th className="text-right">No of Units</th>
                                  <th className="text-right">Gross Amount</th>
                                  <th className="text-right">Action</th>
                                </tr>
                              </thead>
                              <tbody>{renderFundSecuirtiesTable()}</tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <button
                className="btn btn-primary"
                onClick={EditFundData}
                disabled={Boolean(Loading)}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </button>

              <button
                className="btn btn-default mr-2"
                onClick={() => {
                  history.replace("/admin/funds");
                }}
              >
                <span>Cancel</span>
              </button>

              {renderModalPopup()}
              {renderEquityPopup()}
              {renderTermDepositPopup()}
              {renderModalInvestmentInFundPopup()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default EditFunds;
